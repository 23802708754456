import React,{useContext} from 'react';
import './App.css';
import './css/fonts.css';
import {firebaseAuth} from './provider/AuthProvider.js';
import {Route,Switch} from 'react-router-dom';
import Signup from './pages/signup/Signup.js';
import Signin from './pages/signin-page/Signin.js';
import 'antd/dist/antd.css';
import './components/tlac-icon/tlac-icon.css';
import MainLayout from './components/MainLayout.js';
import FinishOrderPage from './pages/payment-page/FinishOrderPage.js';
import LegalPage from './pages/legal-page/LegalPage.js';
import PrivacyPage from './pages/legal-page/PrivacyPage.js';
import CheckinOrder from './pages/checkin-order/CheckinOrder.js';

export const Auth = React.createContext();

function App() {
  const { token } = useContext(firebaseAuth);

  return (
    <>{/*switch allows switching which components render.*/}
    <Switch>
      {/* route allows you to render by url path*/}
      <Route exact path='/' render={rProps=> token === null? <Signin/>:<MainLayout />}/>
      <Route exact path='/en' render={rProps=> token === null? <Signin/>:<MainLayout />}/>
      <Route exact path='/es' render={rProps=> token === null? <Signin/>:<MainLayout />}/>
      <Route exact path='/signup' component={Signup} />
      <Route exact path='/signin' component={Signin}/>
      <Route exact path='/legal' component={LegalPage}/>
      <Route exact path='/privacy-terms' component={PrivacyPage}/>
      <Route exact path='/privacy-terms/es' component={PrivacyPage}/>
      <Route exact path='/privacy-terms/en' component={PrivacyPage}/>
      <Route exact path='/recipe/:id' render={rProps=> token === null? <Signin/>:<MainLayout prefix="recipe"/>}/>
      <Route exact path='/finish-order-page/:key' render={rProps=> token === null? <Signin/>:<FinishOrderPage/>}/>
      <Route exact path='/checkin-order/:key' render={rProps=> token === null? <Signin />:<CheckinOrder/>}/>
      <Route exact path='/new_recipe' render={rProps=> token === null? <Signin/>:<MainLayout prefix="newRecipe"/>}/>
      <Route exact path='/new_address' render={rProps=> token === null? <Signin/>:<MainLayout prefix="newAddress"/>}/>
      <Route exact path='/profile' render={rProps=> token === null? <Signin/>:<MainLayout prefix="profile"/>}/>
      <Route exact path='/lunch_hours' render={rProps=> token === null? <Signin/>:<MainLayout prefix="lunch_hours"/>}/>
      <Route exact path='/payment' render={rProps=> token === null? <Signin/>:<MainLayout prefix="payment"/>}/>
      <Route exact path='/orders' render={rProps=> token === null? <Signin/>:<MainLayout prefix="orders"/>}/>
      <Route exact path='/suggest' render={rProps=> token === null? <Signin/>:<MainLayout prefix="suggest"/>}/>
      <Route exact path='/delivery' render={rProps=> token === null? <Signin/>:<MainLayout prefix="delivery"/>}/>
    </Switch>
    </>
  );
}

export default App;
