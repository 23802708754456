import { Button, List, message, Modal, Popconfirm, Popover } from "antd";
import React from "react";
import { BagsIcon, ClockIcon, DotsIcon, MapPinIcon } from "../../tlac-icon/TlacIcon";
import sw from '../../../utils/multilingual.json';
import OrderDetails from "../order-details/OrderDetails";
import OrderAddress from "../order-address/OrderAddress";
import { dbMethods } from "../../../firebase/dbmethods";

const OrderListItem = (props) => {
    
    const copyId = (key) => {
        navigator.clipboard.writeText(key).then(() => {
            message.info(sw.copiedOrderNumber[props.language])
          }).catch(err => {
            console.error('Error al copiar el texto: ', err);
          });
    }

    const orderDetails = order => {
        Modal.info({
            className:'order-details-modal',
            title: <span className="order-details-modal__title">{sw.orderDetails[props.language]} <Button size='small' onClick={() => copyId(order.key)}>⎘</Button></span>,
            content: (
                <OrderDetails 
                    language={props.language} 
                    created={formatDate(order.dateTime)}
                    recipes={order.order.map(item => {
                        const newItem = { ...item, itemkey: item.key };
                        delete newItem.key;
                        return newItem;
                    })}
                    sendCost={order.send_cost}
                    subtotal={order.subtotal}
                    delivered={order.delivered}
                    deliverHour={order?.selectedHour}
                    deliveryDays={order.deliveryDays}
                    scheduled={order.scheduled}
                />
            )
        });
    } 

    const orderAddress = order => {
        Modal.info({
            className: "address-modal",
            title: (<span className="address-modal__title">
                {sw.order[props.language]} {sw.address[props.language]}
                </span>),
            content: (
                <OrderAddress 
                    language={props.language}
                    deliverAddress={{ 
                        lat: order.address.lat, 
                        lng:order.address.lng,zoom:17
                    }}
                    addressName={order.address.address}
                />
            )
        });
    }

    const getStatusText = (delivered,is_canceled)=> {
        if(delivered)
            return sw.orderDelivered[props.language];
        if(is_canceled)
            return sw.orderCanceled[props.language];
        return sw.orderScheduled[props.language];
    }

    const getStatusIcon = (delivered,is_canceled)=> {
        if(delivered)
            return <span className='status-icon ready'>✓</span>;
        if(is_canceled)
            return <span className='status-icon canceled'>X</span>;
        return <ClockIcon class='status-icon in-progress' dark={true} size={20} />;
    }

    const getStatusDate = (delivered,is_canceled, scheduled, created)=> {
        if(delivered)
            return ''.concat(sw.delivered[props.language], 
                            ': ', 
                            delivered && formatDate(delivered));
        if(is_canceled)
            return ''.concat(sw.created[props.language], 
                            ': ', 
                            created && formatDate(created));
        return ''.concat(sw.scheduled[props.language], 
                        ': ', 
                        scheduled && formatDate(scheduled));
    }

    const formatDate = d => {
        if(!d)
            return '____/__/__';
        d = d.toDate();
        return ''.concat(d.getFullYear(),'/',(d.getMonth() + 1),'/',d.getDate()); 
    }

    const confirmCancel= key => {
        const cancelOrder = ()=> {
            dbMethods.cancelOrder(
                new Date(props.period.year, props.period.month, 1, 0, 0, 0),
                new Date(props.period.year, props.period.month + 1, 0, 23, 59, 59),
                props.setOrders,
                key,
                props.language
            );
            props.setPag(prev=> prev);
        };
        cancelOrder();
    }

    return (<List.Item key={props.item.key} className={`order-list-item ${props.item.isLatest ? "latest": ""}`} onClick={()=> orderDetails(props.item)}>
        <div className="latest-header">
            {sw.latestOrder[props.language]}
        </div>
        <div className="order-list-item__icon">
            <BagsIcon/>
            {getStatusIcon(props.item.delivered, props.item.is_canceled)}
        </div>
        <div className="order-list-item__metadata">
            <span className="order-list-item__metadata-status">
                {getStatusText(props.item.delivered, props.item.is_canceled)}
            </span>
            <span className="order-list-item__metadata-date">
                {getStatusDate(props.item.delivered, props.item.is_canceled, props.item.scheduled, props.item.dateTime)}
            </span>
        </div>
        <div className="order-list-item__actions">
            <Button 
                onClick={button=> { button.stopPropagation(); orderAddress(props.item); }} 
                shape="circle"
                size="large" 
                className="order-list-item__actions-map-btn">
                <MapPinIcon size={24} />
            </Button>
            <Popover 
            trigger="click"
            placement="bottom"
            content={
                <Popconfirm 
                    title={sw.confirmCancelOrder[props.language]}
                    onConfirm={p=> { p.stopPropagation(); confirmCancel(props.item.key);}}
                    onCancel={p=> p.stopPropagation()}
                >
                    <Button type="text" onClick={b=> { b.stopPropagation();}}>{sw.cancel[props.language]}</Button>
                </Popconfirm>} 
            >
                <Button
                    shape="circle"
                    className={`order-list-item__actions-options-btn ${props.item.is_canceled || props.item.delivered ? 'hidden' : ''}`}
                    size="large"
                    type='text'
                    onClick={b=> b.stopPropagation()}
                >
                    <DotsIcon size={20}/>
                </Button>
            </Popover>
            
        </div>
    </List.Item>)
}

export default OrderListItem;