import React from 'react';
import sw from '../../utils/multilingual.json'; 
import { useRef } from 'react';
import { useEffect } from 'react';
import './checking-order.css';
import { useParams } from 'react-router-dom';
import { dbMethods } from '../../firebase/dbmethods';

const CheckinOrder = (props)=> {
    const language = localStorage.getItem('language') ?? ((window.navigator.userLanguage || window.navigator.language).split('-')[0] === 'es'? 'spanish':'english'); 
    const ref = useRef();
    const {key} = useParams();

    useEffect(() => {
        ref.current = {
            language: language,
            key: key
        };
    });

    useEffect(() => {
        if (ref?.current?.key) {
            document.title = `TLAC | ${sw.checkoutOrder[ref.current.language]} ${ref.current.key}`;
            dbMethods.checkoutOrder(ref.current.key);
        }
    }, []);

    return (
        <div className='checkin-order-page'>
            <img src={require('../../pics/logo-horizontal-dark.png').default} width="100" alt="logo" />
            <div className='checkin-order-page__content'>
                <h2 className='checkin-order-page__content-title'>{sw.deliveredMessageTitle[language]}</h2>
                <p className='checkin-order-page__content-msg'>
                    {sw.deliveredMessageContent[language]}
                </p>
                <a className='checkin-order-page__content-action' href="/orders">{sw.deliveredMessageAction[language]}</a>
            </div>
        </div>
    )
};

export default CheckinOrder;