import firebase from 'firebase/app';
import 'firebase/remote-config';

const config = firebase.remoteConfig();

export async function getConfigString(key, set) {
    await config.fetchAndActivate();
    await config.activate();
    const value = config.getValue(key).asString();
    set(value);
}

export async function getConfigJson(key, set) {
    try {
        await config.fetchAndActivate();
        await config.activate();
        const jsonString = config.getValue(key).asString();
        const jsonData = JSON.parse(jsonString);
        set(jsonData);
    } catch (error) {
        console.error('Error fetching or parsing config:', error);
        set(null);
    }
}

export async function getColorsFromConfig() {
    if (document.documentElement.style.getPropertyValue('--colors-configured') === 'true') {
        return;
    }
    
    try {
        await config.fetchConfig();
        await config.activate();

        const jsonString = config.getValue('colors').asString();
        const colors = JSON.parse(jsonString);
        const root = document.documentElement;
        for (const [key, value] of Object.entries(colors)) {
            root.style.setProperty(`--${key}`, value);
        }
        // set flag on colors configured
        root.style.setProperty('--colors-configured', 'true');
    } catch (error) {
        console.error('Error fetching or applying colors:', error);
    }
}