import React,{useContext,useState, useEffect, useRef} from 'react';
import { Layout, Menu, Modal, Switch } from 'antd';
import {
  QuestionCircleFilled, GlobalOutlined, FormatPainterOutlined, BulbOutlined, BulbFilled, CarFilled
} from '@ant-design/icons';
import '../css/main.css'
import {firebaseAuth} from '../provider/AuthProvider.js';
import HomePage from '../pages/home/HomePage.js';
import RecipePage from '../pages/recipe-page/RecipePage.js';
import LunchHours from './lunch-hours/LunchHours.js';
import Payment from '../pages/payment-page/Payment.js';
import OrdersPage from '../pages/orders-page/OrdersPage.js';
import SuggestRecipePage from '../pages/suggest-recipe-page/SuggestRecipePage.js';
import CheffRecipes from './cheff/CheffRecipes.js'
import sw from '../utils/multilingual.json';
import NewAddress from '../pages/add-address-page/NewAddress.js';
import Profile from '../pages/user-profile-page/Profile.js';
import '../components/navbar/navbar.css';
import { AddRecipeIcon, ArrowIcon, BagsIcon, CalendarIcon, LogoutIcon } from './tlac-icon/TlacIcon';
import FinishOrderPage from '../pages/payment-page/FinishOrderPage';
import CheckinOrder from '../pages/checkin-order/CheckinOrder';
import Delivery from '../pages/delivery/Delivery.js';

const { Header, Sider, Content } = Layout;

const MainLayout = (props)=> {
  const {handleSignout,handleGetUserData} = useContext(firebaseAuth);
  const [userData, setUserData] = useState({});
  const [content, setContent] = useState(<div></div>);
  const [selected,setSelected] = useState('0');
  const [title, setTitle] = useState({spanish:'TLAC', english:'TLAC'});
  const [language, setLanguage] = useState(localStorage.getItem('language') ?? ((window.navigator.userLanguage || window.navigator.language).split('-')[0] === 'es'? 'spanish':'english'))
  const [isDark, setDark] = useState(localStorage.getItem('dark') ? true : false)
  const [isEmployee, setIsEmployee] = useState(false)
  // let language = localStorage.getItem('language') ?? (window.navigator.userLanguage || window.navigator.language).split('-')[0] === 'es'? 'spanish':'english'; 

  const getBodyContent = (prefix) =>{
    const pages = {
      recipe: { component: RecipePage, menu:'-1',title:{spanish:'recipe',english:'recipe'} },
      finishOrder: { component: FinishOrderPage, menu:'-1',title:{spanish:'terminar orden',english:'finish order'} },
      checkinOrder: { component: CheckinOrder, menu:'-1',title:{spanish:'recibir orden',english:'checkin order'} },
      undefined: { component: HomePage, menu:'0',title:{spanish:'TLAC',english:'TLAC'} },
      newRecipe: { component: CheffRecipes, menu:'-1',title:sw.sendRecipe },
      newAddress: { component: NewAddress, menu:'-1',title:sw.addNewAddresses },
      profile: { component: Profile, menu:'-1',title:sw.profileTitle },
      lunch_hours: { component: LunchHours, menu:'1',title:sw.lunchHours },
      payment: { component: Payment, menu:'2',title:sw.payment },
      orders: { component: OrdersPage, menu:'3',title:sw.myOrders },
      suggest: { component: SuggestRecipePage, menu:'4',title:sw.sendRecipe },
      delivery: { component: Delivery, menu:'7',title:sw.delivery }
    };
    const CurrentPage = pages[prefix].component;
    setSelected(pages[prefix].menu);
    setTitle(pages[prefix].title);
    return <CurrentPage language={language} />
  } 
  
  const signout = ()=> {
    Modal.confirm({
      className:'confirm-logout',
      // title:sw.confirm[language],
      icon: <QuestionCircleFilled />,
      content: sw.confirmSignout[language],
      onOk: ()=> {
        localStorage.removeItem('recipes');
        localStorage.removeItem('categories');
        localStorage.removeItem('units');
        handleSignout();
        window.location = '/';
      }
    });
  
    }

  const handleSelectedMenu = e => {
    setSelected(e.key);
    switch(e.key){
      case "0":
        window.location = '/';
        break;
      
      case "1":
        window.location = '/lunch_hours';
        break;
      
      case "2":
        window.location = '/payment';
        break;
      
      case "3":
        window.location = '/orders';
        break;
      
      case "4":
        window.location = '/suggest';
        break;

      case "5":
        setContent(<CheffRecipes language={language}/>);
        break;
      case "7":
        window.location = '/delivery';
        break;
      default:
        window.location = '/';
    }
  }

  const switchLanguage = isSpanish => {
    const l = isSpanish ? "spanish" : "english"
    setLanguage(l);
    localStorage.setItem('language', l);
  }

  const switchTheme = () => {
    const dark = localStorage.getItem('dark')
    if (dark) {
      document.querySelector('body').classList.add('light');
      document.querySelector('.site').classList.add('light');
      localStorage.removeItem('dark');
      setDark(false)
    }
    else {
      document.querySelector('body').classList.remove('light');
      document.querySelector('.site').classList.remove('light');
      localStorage.setItem('dark','true');
      setDark(true)
    }
  }

  const fixed = useRef();
  
  useEffect(()=> {
    fixed.current = {
      handleGetUserData: handleGetUserData,
      prefix: props.prefix,
      getBodyContent: getBodyContent
    }; 
    window.signout = signout;

    // check theme
    document.querySelector('.site')?.classList[isDark ? 'remove' : 'add']('light');
    document.querySelector('body')?.classList[isDark ? 'remove' : 'add']('light');
  });

  useEffect(()=> {
    fixed.current.handleGetUserData(setUserData);
    setContent(fixed.current.getBodyContent(fixed.current.prefix));
    setTimeout(() => setIsEmployee(sessionStorage.getItem('isEmployee') === 'true'), 1000)
  },[language]);

  return (
    <Layout className={`site ${title['english']}`} style={{height:'100%'}}>
      <Sider 
      theme="dark" 
      id="sider"
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={broken => {
        // console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        // console.log(collapsed, type);
      }}>
        <div className="site-navbar">
        <a href='/profile' className='site-navbar-header'>
          <img alt="userPic" className="site-navbar-header-picture" src={userData?.picture} width={75}/>
          <div className="site-navbar-header-content">
              <span className="site-navbar-header-content__welcome">{sw.welcome[language]}</span>
              <span className="site-navbar-header-content__user">{userData.displayName}</span>
          </div>
        </a>
        </div>
        <Menu theme="dark" className='site-navbar-menu' mode="inline" style={{marginTop:'25px'}} defaultSelectedKeys={['0']} selectedKeys={[selected]}>
          <Menu.Item onClick={handleSelectedMenu} key="0" icon={<CalendarIcon size={20} className='anticon'/>}>
            {sw.weeklyPlan[language]}
          </Menu.Item>
          <Menu.Item onClick={handleSelectedMenu} key="3" icon={<BagsIcon size={20} className='anticon'/>}>
            {sw.myOrders[language]}
          </Menu.Item>
          <Menu.Item onClick={handleSelectedMenu} key="4" icon={<AddRecipeIcon size={20} className='anticon'/>}>
            {sw.sendRecipe[language]}
          </Menu.Item>
          {/* <Menu.Item onClick={handleSelectedMenu} key="6" icon={<SendOutlined />}>
            New Recipe(temp)
          </Menu.Item> */}
          {isEmployee ? 
          <Menu.Item onClick={handleSelectedMenu} key="7" icon={<CarFilled size={20} className='anticon'/>}>
            {sw.delivery[language]}
          </Menu.Item> :
          <></>}
          <Menu.Item onClick={signout} key="5" icon={<LogoutIcon size={20} className='anticon'/>}>
            {sw.signout[language]}
          </Menu.Item>
          <Menu.Item icon={<GlobalOutlined /> }>
            <Switch checkedChildren="ES" unCheckedChildren="En" checked={language === "spanish"} onChange={(e) => switchLanguage(e)} />
          </Menu.Item>
          <Menu.Item icon={<FormatPainterOutlined /> }>
            <Switch checkedChildren={<BulbOutlined />} unCheckedChildren={<BulbFilled />} checked={isDark} onChange={(e) => switchTheme(e)} />
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site">
        <Header className="site-header" style={{ padding: 0 }}>
          {title['english'] === 'recipe' ? <ArrowIcon orientation={'left'}  onClick={() => window.history.back()} class_name='pwa-back'/> : null}
          <label id="nav-bar-title" className="site-header-text" style={{display:"inline"}}>
            <img className='site-header-logo' width={180} src={require('../pics/logo-horizontal.png').default} alt='logo'/>
          </label>
          {title['english'] === 'TLAC' ? <div className='pwa-logout' onClick={signout}>
            <LogoutIcon size={20} class='anticon'/>
          </div> : null}
          
        </Header>
        <Content
          className={'site-content '.concat(title['english'].replace(/ /g,'_'))}
          style={{
            minHeight: 'calc(100vh - 100px)'
          }}
          id="content"
        >
          {content}
        </Content>
        {/* <Footer>
      <a href='#legal'>Legal</a>
      TLAC ©2023 Created by TLAC
      <div className='social-footer'>
        <a title='facebook' href='/'><SocialIcon type='facebook' /></a>
        <a title='instagram' href='https://www.instagram.com/tlac.official/' target='_blank' rel='noreferrer'><SocialIcon type='instagram' /></a>
        <a title='whatsapp' href='https://wa.me/+351934043683' target='_blank' rel='noreferrer'><SocialIcon type='whatsapp' /></a>
      </div>
      </Footer> */}
      </Layout>
    </Layout>
  );

};

export default MainLayout;

