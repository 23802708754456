import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import React from "react";
import { CalendarIcon } from "../../tlac-icon/TlacIcon";

const OrderFilter = (props) => {

    const changePeriod = (value, type)=> {
        props.setPeriod(prev=> ({...prev,[type]:value}));
    };

    return (<div className='order-filter'>
    <CalendarIcon />
    <Select
        size="middle" 
        defaultValue={props.period.month}
        onChange={v=> changePeriod(v,'month')}
        className="capitalize">
    {Array(12).fill().map((_,i)=> 
        <Option className="capitalize" key={i} value={i}>
            {new Date(null,i).toLocaleDateString('default',{month:'long'})}
        </Option>)}
    </Select>
    <Select 
        size="middle" 
        defaultValue={props.period.year}
        onChange={v=> changePeriod(v,'year')}>
    {Array(3).fill().map((_,i)=> 
        <Option value={new Date().getFullYear() - i}>
            {new Date().getFullYear() - i}
        </Option>)}
    </Select>
</div>);
}

export default OrderFilter;