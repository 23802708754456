import { Card, Button } from 'antd';
import React from 'react';

import sw from '../../../utils/multilingual.json';
import { ArrowIcon, MapPinIcon } from '../../tlac-icon/TlacIcon.js';
import AddressInfoList from '../address-info-list/AddressInfoList.js';


const Address = (props)=> {

    

    

    const getMoreAddresses = ()=> {
        if (props.addresses.length){
            return String(props.addresses.length - 1)
                    .concat(" ", 
                            sw.more[props.language], 
                            " " , 
                            sw.addresses[props.language]);
        }
         return "";
    }

    const expanded = (<div className="address-info__body">
        <AddressInfoList
            language={props.language}
            setSelectedAddress={props.setSelectedAddress}
            setAddresses={props.setAddresses}
            addresses={props.addresses}
            selectedAddress={props.selectedAddress}
        />

        <Button 
            type="primary" 
            size="large" 
            className="add-address-button"
            onClick={()=> document.location.href = '/new_address'}
        >
            {sw.addNewAddresses[props.language]}
        </Button>
    </div>);

    const closed = (<div className="address-info__body closed" onClick={props.setOpened}>
        <span className='address-info-closed__field'>{props.selectedAddress && props.selectedAddress.address}</span>
        <span className='address-info-closed__field'>{getMoreAddresses()}</span>
    </div>);

    return (<Card 
        className="address-info" 
        hoverable 
        title={<div className="address-info__title" onClick={props.setOpened}>
                <label><MapPinIcon size={20}/> {sw.addresses[props.language]}</label>
                <ArrowIcon orientation={props.expand.address? 'top-left': 'bottom-right'} size={25} />
            </div>}>
            {props.expand.address? expanded:
                ((props.expand.info || props.expand.health)? null:closed)}
        </Card>);
}

export default Address;