import { Card, Button, Input } from 'antd';
import React, { useState} from 'react';
import { dbMethods } from '../../../firebase/dbmethods.js';
import {  
    SaveOutlined
} from '@ant-design/icons';
import sw from '../../../utils/multilingual.json';
import { ArrowIcon, UserIcon } from '../../tlac-icon/TlacIcon.js';


const Information = (props)=> {

    const [changes,setChanges] = useState({
        password:false,
        phoneNumber:false
    });

    const onType = (val,field)=> {
        if(field === 'name' && val.length > 54)
            return;
        if(field === 'phoneNumber' && (val.length >= 11 || !val.match(/^\d+$/)))
            return;
        if(field === 'phoneNumber')
            setChanges(prev=> ({...prev,phoneNumber:true}));
        if(field === 'password')
            setChanges(prev=> ({...prev,password:true}));

        props.setProfile(prev=> ({...prev,[field]:val}));
    }

    const updateProfile = ()=> {
        if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(props.profile.email))
            dbMethods.updateProfile(props.profile,changes.phoneNumber,changes.password,props.language,setChanges);
    }

    const expanded = (<div className="profile-info__body">
        <div className='data'>
            <div className='data-input column-1 row-1'>
                <label>{sw.userName[props.language]}</label>
                <Input
                    onChange={v=> onType(v.currentTarget.value,'name')} 
                    value={props.profile.name} 
                    maxLength={55} 
                    type="text" 
                    placeholder={sw.userName[props.language]} 
                />
            </div>
            <div className='data-input column-1 row-2'>
                <label>{sw.email[props.language]}</label>
                <Input
                    onChange={v=> onType(v.currentTarget.value,'email')} 
                    value={props.profile.email} 
                    disabled 
                    type="text" 
                />
            </div>
            <div className='data-input column-2 row-1'>
                <label>{sw.password[props.language]}</label>
                <Input
                    onChange={v=> onType(v.currentTarget.value,'password')} 
                    type="password" 
                    placeholder={sw.password[props.language]} 
                />
            </div>
            <div className='data-input column-2 row-2'>
                <label>{sw.phoneNumber[props.language]}</label>
                <Input
                    onChange={v=> onType(v.currentTarget.value,'phoneNumber')} 
                    value={props.profile.phoneNumber} 
                    type="text" 
                    placeholder={sw.phoneNumber[props.language]}
                />
            </div>
        </div>
        <Button 
            onClick={updateProfile} 
            type="primary" 
            className="profile-info__body-save"
            size="large"
        >
            <SaveOutlined className="text-large" />
            <label className="text-medium"> {sw.save[props.language]}</label>
        </Button>
    </div>);

    const closed = (<div className="profile-info__body closed" onClick={props.setOpened}>
            <span className='profile-info-closed__field'>{props.profile.name}</span>
            <span className='profile-info-closed__field'>{props.profile.email}</span>
            <span className='profile-info-closed__field'>{props.profile.phoneNumber}</span>
        </div>);

    return (<Card 
            className="profile-info" 
            hoverable 
            title={<div className="profile-info__title" onClick={props.setOpened}>
                    <label><UserIcon size={20} /> {sw.accountInfo[props.language]}</label>
                    <ArrowIcon orientation={props.expand.info? 'top-left': 'bottom-right'} size={25} />
                </div>}>
                {props.expand.info? expanded: 
                    ((props.expand.address || props.expand.health)? null:closed)}
            </Card>);
}

export default Information;