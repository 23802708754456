import React from 'react';
import '../../pages/legal-page/legal-page.css';
import { useEffect } from 'react';
import './legal-page.css';

const LegalPage = (props) => {

    const language = window.location.pathname.includes('/en') ? 'english' :
                window.location.pathname.includes('/es') ? 'spanish' :
                (window.navigator.userLanguage || window.navigator.language).split('-')[0] === 'es' ? 'spanish' :
                'english';

    useEffect(() => {
        document.title = `TLAC | Legal`;
    }, []);

    return (<div className='legal-page'>
        <a href='/'>
            <img alt='tlac logo' src={require('../../pics/LOGO-01.png').default} height={100} width={100} />
        </a>

        {language === "english" ? <div>
             <h2>Terms and Conditions</h2>

             Acceptance of the Terms and Conditions: By accessing and using the TLAC services, the User accepts and acknowledges having read and understood the terms and conditions of this Agreement and agrees to comply with them.
             <h3>Services Offered:</h3> TLAC is an online meal delivery platform that offers weekly meal plans and a variety of meal options. The selection of meal plans and specific details of the products offered by TLAC are provided on the TLAC website and are subject to change without notice.
             <h3>User Registration:</h3> To use the TLAC services, the User must register and create an account by providing accurate and complete information, including name, email address, physical address and telephone number. The User is responsible for maintaining the security of his account and the confidentiality of his login information.
             <h3>Use of the service:</h3> The User acknowledges and accepts that the products offered by TLAC are intended exclusively for personal and non-commercial use. The User undertakes not to resell, sublicense, transfer or distribute TLAC products.
             <h3>Payment and billing:</h3> TLAC charges the User a fee for each meal plan selected. The User agrees to pay the corresponding fee through the payment method provided in their account. TLAC reserves the right to change the rates at any time prior notice to the User.
             <h3>Delivery of products:</h3> TLAC undertakes to deliver the products selected by the User to the address provided in the account. Delivery is made on the previously scheduled date and time. If the User is not available at the time of delivery, TLAC may attempt a second delivery or leave the products in a safe place determined by the User. TLAC is not responsible for products delivered in a safe place if the User is not available at the time of delivery.
             <h3>Cancellations and refunds:</h3> The User can cancel his subscription at any time before the closing of the corresponding term. TLAC reserves the right to cancel the User's subscription at any time.
             <h3>Intellectual Property:</h3> All content, materials and services offered by TLAC, including without limitation, software, text, images, designs, photographs, videos, graphics, music, logos, trademarks and trade names ( hereinafter "Content"), are the exclusive property of TLAC or its licensors. The User acknowledges and accepts that he has no right to use, reproduce, modify or distribute the Content without the prior written authorization of TLAC.
             <h3>Limitation of Liability:</h3> TLAC shall not be liable for any direct, indirect, incidental, special or consequential damages resulting from the use of TLAC's services or the inability to use them.
             TLAC's Responsibility for Any Indemnity: The User agrees to indemnify, defend and hold harmless TLAC, its affiliates, directors, employees and agents from any claim, demand, action, loss, damage, cost or expense, including reasonable attorneys' fees, that arise from the use that the User makes of the TLAC services or from his breach of the terms and conditions of this Contract.

             Waiver: TLAC's failure to enforce any provision of this Agreement shall not be construed as a waiver of such provision. Waiver of any provision of this Agreement will only be effective if in writing and signed by an authorized representative of TLAC.

             Rights of Third Parties: This Agreement does not confer any rights or benefits on any person or entity other than the User or TLAC.

             TLAC is committed to providing a quality service to its Users. In the event that the User has any complaint or disagreement related to the services provided by TLAC, the following complaint procedure is established:

             The User must submit their complaint in writing, clearly and precisely detailing the reasons for it, as well as any relevant information that supports their claim.

             The complaint must be sent to TLAC through the contact means provided on the TLAC website.

             TLAC agrees to diligently review and analyze the complaint and take appropriate steps to resolve it within a reasonable time.

             TLAC will contact the User to inform them of the actions taken to resolve the complaint and, if necessary, request additional information or provide clarifications.
             TLAC will use all reasonable efforts to resolve the complaint to the satisfaction of both parties. However, in the event that an agreement is not reached, the parties may resort to the alternative means of dispute resolution mentioned in the "Dispute Resolution" section of this Agreement.

Storage conditions:

TLAC undertakes to guarantee adequate storage conditions for the products offered to Users. The following storage conditions are established below:

TLAC will ensure that perishable products are stored and transported at safe temperatures for consumption, in accordance with applicable sanitary regulations.

TLAC will use appropriate storage methods to maintain the freshness and quality of the products during storage and transportation.

TLAC will carry out regular inspections of the storage places and will take corrective measures in case of identifying inadequate conditions that may affect the safety or quality of the products.

TLAC is committed to complying with all applicable laws and regulations related to the storage and transportation of food and beverages until they leave the warehouse/kitchen.

Liability for Damages:

TLAC acknowledges that harm situations may arise as a result of the use of its services. The following provisions regarding liability for damages are set forth below:

TLAC shall not be liable for any direct, indirect, incidental, special or consequential damages resulting from the use of TLAC's services or the inability to use them. TLAC's Responsibility for Any Indemnity: The User agrees to indemnify, defend and hold harmless TLAC, its affiliates, directors, employees and agents from any claim, demand, action, loss, damage, cost or expense, including reasonable attorneys' fees, that arise from the use that the User makes of the TLAC services or from his breach of the terms and conditions of this Contract.
<h3>Waiver:</h3> TLAC's failure to enforce any provision of this Agreement shall not be construed as a waiver of such provision. Waiver of any provision of this Agreement will only be effective if in writing and signed by an authorized representative of TLAC.
<h3>Third Party Rights:</h3> This Agreement does not confer any right or benefit to any person or entity other than the User or TLAC.
<h3>Complaints procedure:</h3> The User must present his complaint in writing, clearly and precisely detailing the reasons for it, as well as any relevant information that supports his claim. The complaint must be sent to TLAC through the contact means provided on the TLAC website. TLAC agrees to diligently review and analyze the complaint and take appropriate steps to resolve it within a reasonable time. TLAC will contact the User to inform them of the actions taken to resolve the complaint and, if necessary, request additional information or provide clarifications. TLAC will make all reasonable efforts to resolve the complaint to the satisfaction of both parties. However, in the event that an agreement is not reached, the parties may resort to the alternative means of dispute resolution mentioned in the "Dispute Resolution" section of this Agreement.
<h3>Storage Conditions:</h3> TLAC undertakes to guarantee adequate storage conditions for the products offered to Users. The following storage conditions are established below: TLAC will ensure that perishable products are stored and transported at safe temperatures for consumption, in accordance with applicable sanitary regulations. TLAC will use appropriate storage methods to maintain the freshness and quality of the products during storage and transportation. TLAC will carry out regular inspections of the storage places and will take corrective measures in case of identifying inadequate conditions that may affect the safety or quality of the products. TLAC is committed to complying with all applicable laws and regulations related to the storage and transportation of food and beverages until they leave the warehouse/kitchen.
<h3>Liability for Damages:</h3> TLAC acknowledges that situations may arise where damages occur as a result of the use of its services. The following provisions regarding liability for damages are set forth below: TLAC shall not be liable for any direct, indirect, incidental, special, or consequential damages that may arise from the use of TLAC's services, Including, but not limited to, damages for loss of profit, business interruption, loss of data, or property damage. In the event that TLAC is held responsible for any damage, TLAC's total liability will be limited to the amount paid by the User for the corresponding services in the three-month period prior to the event that gave rise to the damage. TLAC will not be liable for any damages that may arise as a result of the conduct of third parties, including other TLAC Users or third party service providers. These provisions regarding liability for damages shall apply to the fullest extent permitted by applicable law.
             <h3>Liability for Illnesses and Food Allergies:</h3> TLAC will endeavor to ensure that all food and beverages offered to customers are safe for human consumption. However, TLAC cannot guarantee the absolute safety of food and beverages offered to customers, as certain risks inherent in the production, storage, and transportation of food and beverages may exist. Therefore, the client accepts that TLAC will not be responsible for any illness, injury or damage that may arise from the consumption of the food and beverages offered by TLAC. In addition, the client agrees that he waives any right to sue TLAC for said illness, injury or damage. TLAC is committed to taking all reasonable steps to ensure the safety of food and beverages offered to customers, including adopting proper hygiene and food safety practices. However, the client acknowledges and accepts that certain risks may exist and that TLAC cannot guarantee the absolute safety of the food and beverages offered. In addition, TLAC reserves the right to withdraw any product from its offer at any time if it believes there is a risk to the health and safety of the consumer. TLAC is also committed to complying with all applicable laws and regulations related to the production, storage, transportation, and sale of food and beverages. In the event a claim arises regarding the safety of food and beverages offered by TLAC, TLAC agrees to investigate the claim in good faith and take appropriate action to address any food safety issues that may have arisen. However, TLAC accepts no responsibility for any claim related to the safety of food and beverages offered by TLAC and reserves the right to defend itself against any claim made against it.
             <h3>Language:</h3> This Agreement has been written in English and any translations of this Agreement are provided solely as a courtesy. In the event of any conflict between the Spanish version and a translated version, the Spanish version will prevail.
             <h3>Contact:</h3> If the User has any questions about this Agreement or the services offered by TLAC, they can contact TLAC through the contact information provided on the TLAC website.
             <h3>Acceptance:</h3> By accessing and using the TLAC services, the User acknowledges having read, understood and accepted the terms and conditions of this Agreement and undertakes to comply with them.
             <h3>Conflict resolution:</h3> This contract will be governed and interpreted in accordance with Mexican law, without considering conflicts of laws. Any dispute arising from this contract will be resolved by alternative dispute resolution means, such as mediation or arbitration, in accordance with the rules of the Mexican Arbitration Center. If the parties cannot resolve the conflict by alternative means, the parties agree to submit to the exclusive jurisdiction of the courts of Tijuana, Baja California.
             <h3>Entire Agreement:</h3> This Agreement constitutes the entire agreement between TLAC and the user relating to access to and use of the TLAC Services and supersedes any prior agreements between the parties. If any provision of this agreement is held invalid or unenforceable, that provision will be removed and the other provisions will remain in full force and effect.

             <h2>Privacy Policy of tlac.com.mx</h2>

             Date of entry into force: 01 January-2023

             This Privacy Policy establishes how TLAC.com.mx ("we", "our", "us") collects, uses and protects the personal information that you ("user", "your") provide on the https website: //www.tlac.com.mx/ ("Site"). We are committed to protecting your privacy and complying with applicable data protection laws and regulations in Mexico. Please read this Privacy Policy carefully to understand our practices in relation to your personal information.
             <h3>Information We Collect</h3>
             We can recCollect the following personal and non-personal information when you interact with our Site:
             a) Information provided by you: We may request and collect personal information when you register on our Site, create a user account, make online purchases, participate in surveys or promotions, communicate with us through contact forms, or provide us with information in any other way. This information may include your name, email address, telephone number, shipping and billing address, and any other information relevant to providing you with our services.

             b) Information Collected Automatically: We may use tracking technologies, such as cookies and web beacons, to automatically collect certain non-personal information about your visit to the Site. This may include your IP address, browser type, Internet Service Provider (ISP), pages viewed, date and time of access, and clickstream data. This data helps us improve your experience on the Site, analyze trends, and administer the Site.

             <h3>Use of information</h3>
             We use the information collected for the following purposes:
             a) Provide and maintain our services: We use your information to process and complete your transactions, provide you with customer support, respond to your inquiries, and provide you with the information and services you requested.

             b) Personalization and improvement of the Site: We use the information to personalize your experience on the Site, tailor our products and services to your preferences, and continually improve our Site based on your comments and suggestions.

             c) Promotional Communications: If you have given us your consent, we may use your information to send you promotional communications, such as newsletters, special offers and product updates. You may opt out of receiving such communications at any time by following the instructions provided in the emails.

             d) Compliance with legal obligations: We may use your information to comply with our legal obligations, including complying with applicable laws and regulations, responding to legal and governmental requests, and protecting our legal rights.

             <h3>Sharing information with third parties</h3>
             We may share your personal information with third parties in the following circumstances:
             a) Service Providers: We may share your information with third-party service providers who assist us in providing services, such as payment processing, product shipping, data analytics, and marketing. These service providers are contractually bound to protect the confidentiality and security of your personal information and may only use it in accordance with our instructions.

             b) Legal compliance: We may disclose your personal information when necessary to comply with a legal obligation, such as to respond to legal and governmental requests, comply with legal process, protect our legal rights, and address fraud or security situations.

             c) Consent: We may share your information with third parties where you have given your explicit consent for such disclosure.

             <h3>Protection of information</h3>
             We are committed to protecting the security of your personal information. We implement appropriate technical and organizational measures to protect your information against unauthorized access, misuse, alteration or destruction.
             However, please note that no Internet security measures can guarantee the absolute protection of information. We recommend that you take additional steps to protect your personal information, such as keeping your passwords secure and not sharing them with third parties.

             <h3>Data Retention</h3>
             We will retain your personal information for as long as necessary to fulfill the purposes set out in this Privacy Policy, unless a longer retention period is required or permitted under applicable law.

             <h3>Rights of the data owner</h3>
             You have certain rights in relation to your personal information, including access, rectification, cancellation and opposition to the processing of your personal data. You can exercise these rights by sending us a written request through the contact means provided at the end of this Privacy Policy.

             <h3>Links to third party sites</h3>
             Our Site may contain links to third party websites. We are not responsible for the privacy practices or the content of such websites. We recommend that you review Be aware of the privacy policies of third party websites before providing any personal information.

<h3>Changes to this Privacy Policy</h3>
We reserve the right to update or modify this Privacy Policy at any time and without prior notice. Any changes will be effective immediately upon posting on the Site. We encourage you to periodically review this Privacy Policy to be informed about how we protect your personal information.

<h3>Contact</h3>
If you have any questions, concerns or requests related to this Privacy Policy or the handling of your personal data, you can contact us through the following means of contact:

<span>tlac.oficial@gmail.com</span>

<span>Last update: 23-May-2023</span>
</div>:
            <div>
            <h2>Términos y Condiciones</h2>

            Aceptación de los Términos y Condiciones: Al acceder y utilizar los servicios de TLAC, el Usuario acepta y reconoce haber leído y entendido los términos y condiciones de este Contrato y acuerda cumplir con ellos.
            <h3>Servicios ofrecidos:</h3> TLAC es una plataforma en línea de entrega de comidas que ofrece planes de comidas semanales y una variedad de opciones de comidas. La selección de los planes de comidas y los detalles específicos de los productos ofrecidos por la TLAC se proporcionan en la página web de TLAC y están sujetos a cambios sin previo aviso.
            <h3>Registro del Usuario:</h3> Para utilizar los servicios de TLAC, el Usuario debe registrarse y crear una cuenta proporcionando información precisa y completa, incluyendo nombre, dirección de correo electrónico, dirección física y número de teléfono. El Usuario es responsable de mantener la seguridad de su cuenta y la confidencialidad de su información de inicio de sesión.
            <h3>Uso del servicio:</h3> El Usuario reconoce y acepta que los productos ofrecidos por TLAC están destinados exclusivamente para uso personal y no comercial. El Usuario se compromete a no revender, sublicenciar, transferir o distribuir los productos de TLAC.
            <h3>Pago y facturación:</h3> TLAC cobra al Usuario una tarifa por cada plan de comida seleccionado. El Usuario acepta pagar la tarifa correspondiente a través del método de pago proporcionado en su cuenta. TLAC se reserva el derecho de cambiar las tarifas en cualquier momento previo aviso al Usuario.
            <h3>Entrega de productos:</h3> TLAC se compromete a entregar los productos seleccionados por el Usuario a la dirección proporcionada en la cuenta. La entrega se realiza en la fecha y hora programadas previamente. Si el Usuario no está disponible en el momento de la entrega, TLAC puede intentar una segunda entrega o dejar los productos en un lugar seguro determinado por el Usuario. TLAC no se hace responsable de los productos entregados en un lugar seguro si el Usuario no se encuentra disponible en el momento de la entrega.
            <h3>Cancelaciones y reembolsos:</h3> El Usuario puede cancelar su suscripción en cualquier momento antes del cierre del plazo correspondiente. TLAC se reserva el derecho de cancelar la suscripción del Usuario en cualquier momento.
            <h3>Propiedad intelectual:</h3> Todos los contenidos, materiales y servicios ofrecidos por TLAC, incluyendo sin limitación, el software, textos, imágenes, diseños, fotografías, videos, gráficos, música, logos, marcas comerciales y nombres comerciales (en adelante "Contenido"), son propiedad exclusiva de TLAC o sus licenciantes. El Usuario reconoce y acepta que no tiene derecho a utilizar, reproducir, modificar o distribuir el Contenido sin la autorización previa y por escrito de TLAC.
            <h3>Limitación de responsabilidad:</h3> TLAC no será responsable por cualquier daño directo, indirecto, incidental, especial o consecuente que resulte del uso de los servicios de TLAC o de la imposibilidad de utilizarlos.
            La responsabilidad de TLAC por cualquier Indemnización: El Usuario acepta indemnizar, defender y mantener indemne a TLAC, sus afiliados, directores, empleados y agentes de cualquier reclamo, demanda, acción, pérdida, daño, costo o gasto, incluyendo honorarios razonables de abogados, que surjan del uso que el Usuario haga de los servicios de TLAC o de su incumplimiento de los términos y condiciones de este Contrato.

            Renuncia: La falta de cumplimiento por parte de TLAC de cualquier disposición de este Contrato no se interpretará como una renuncia a dicha disposición. La renuncia a cualquier disposición de este Contrato sólo tendrá efecto si se realiza por escrito y es firmada por un representante autorizado de TLAC.

            Derechos de terceros: Este Contrato no confiere ningún derecho o beneficio a ninguna persona o entidad que no sea el Usuario o TLAC.

            TLAC se compromete a brindar un servicio de calidad a sus Usuarios. En caso de que el Usuario tenga alguna queja o inconformidad relacionada con los servicios proporcionados por TLAC, se establece el siguiente procedimiento de quejas:

            El Usuario deberá presentar su queja por escrito, detallando de manera clara y precisa los motivos de la misma, así como cualquier información relevante que respalde su reclamación.

            La queja deberá ser enviada a TLAC a través de los medios de contacto proporcionados en la página web de TLAC.

            TLAC se compromete a revisar y analizar la queja de manera diligente y a tomar las medidas adecuadas para resolverla en un plazo razonable.

            TLAC se comunicará con el Usuario para informarle sobre las acciones tomadas para resolver la queja y, en caso necesario, solicitar información adicional o proporcionar aclaraciones.

            TLAC hará todos los esfuerzos razonables para resolver la queja de manera satisfactoria para ambas partes. Sin embargo, en caso de que no se llegue a un acuerdo, las partes podrán recurrir a los medios alternativos de resolución de conflictos mencionados en la sección "Resolución de conflictos" de este Contrato.

            Condiciones de Almacenamiento:

            TLAC se compromete a garantizar las condiciones adecuadas de almacenamiento de los productos ofrecidos a los Usuarios. A continuación se establecen las siguientes condiciones de almacenamiento:

            TLAC se asegurará de que los productos perecederos sean almacenados y transportados a temperaturas seguras para su consumo, de acuerdo con las regulaciones sanitarias aplicables.

            TLAC utilizará métodos de almacenamiento adecuados para mantener la frescura y calidad de los productos durante su almacenamiento y transporte.

            TLAC realizará inspecciones regulares de los lugares de almacenamiento y tomará medidas correctivas en caso de identificar condiciones inadecuadas que puedan afectar la seguridad o calidad de los productos.

            TLAC se compromete a cumplir con todas las leyes y regulaciones aplicables relacionadas con el almacenamiento y transporte de alimentos y bebidas hasta que salen del almacén/cocina

            Responsabilidad por Daños:

            TLAC reconoce que pueden surgir situaciones en las que se produzcan daños como resultado del uso de sus servicios. A continuación se establecen las siguientes disposiciones con respecto a la responsabilidad por daños:

            TLAC no será responsable por cualquier daño directo, indirecto, incidental, especial o consecuente que resulte del uso de los servicios de TLAC o de la imposibilidad de utilizarlos. La responsabilidad de TLAC por cualquier Indemnización: El Usuario acepta indemnizar, defender y mantener indemne a TLAC, sus afiliados, directores, empleados y agentes de cualquier reclamo, demanda, acción, pérdida, daño, costo o gasto, incluyendo honorarios razonables de abogados, que surjan del uso que el Usuario haga de los servicios de TLAC o de su incumplimiento de los términos y condiciones de este Contrato.
            <h3>Renuncia:</h3> La falta de cumplimiento por parte de TLAC de cualquier disposición de este Contrato no se interpretará como una renuncia a dicha disposición. La renuncia a cualquier disposición de este Contrato sólo tendrá efecto si se realiza por escrito y es firmada por un representante autorizado de TLAC.
            <h3>Derechos de terceros:</h3> Este Contrato no confiere ningún derecho o beneficio a ninguna persona o entidad que no sea el Usuario o TLAC.
            <h3>Procedimiento de quejas:</h3> El Usuario deberá presentar su queja por escrito, detallando de manera clara y precisa los motivos de la misma, así como cualquier información relevante que respalde su reclamación. La queja deberá ser enviada a TLAC a través de los medios de contacto proporcionados en la página web de TLAC. TLAC se compromete a revisar y analizar la queja de manera diligente y a tomar las medidas adecuadas para resolverla en un plazo razonable. TLAC se comunicará con el Usuario para informarle sobre las acciones tomadas para resolver la queja y, en caso necesario, solicitar información adicional o proporcionar aclaraciones. TLAC hará todos los esfuerzos razonables para resolver la queja de manera satisfactoria para ambas partes. Sin embargo, en caso de que no se llegue a un acuerdo, las partes podrán recurrir a los medios alternativos de resolución de conflictos mencionados en la sección "Resolución de conflictos" de este Contrato.
            <h3>Condiciones de Almacenamiento:</h3> TLAC se compromete a garantizar las condiciones adecuadas de almacenamiento de los productos ofrecidos a los Usuarios. A continuación se establecen las siguientes condiciones de almacenamiento: TLAC se asegurará de que los productos perecederos sean almacenados y transportados a temperaturas seguras para su consumo, de acuerdo con las regulaciones sanitarias aplicables. TLAC utilizará métodos de almacenamiento adecuados para mantener la frescura y calidad de los productos durante su almacenamiento y transporte. TLAC realizará inspecciones regulares de los lugares de almacenamiento y tomará medidas correctivas en caso de identificar condiciones inadecuadas que puedan afectar la seguridad o calidad de los productos. TLAC se compromete a cumplir con todas las leyes y regulaciones aplicables relacionadas con el almacenamiento y transporte de alimentos y bebidas hasta que salen del almacén/cocina.
            <h3>Responsabilidad por Daños:</h3> TLAC reconoce que pueden surgir situaciones en las que se produzcan daños como resultado del uso de sus servicios. A continuación se establecen las siguientes disposiciones con respecto a la responsabilidad por daños: TLAC no será responsable por ningún daño directo, indirecto, incidental, especial o consecuente que pueda surgir del uso de los servicios de TLAC, incluyendo, pero no limitado a, daños por pérdida de beneficios, interrupción de negocios, pérdida de datos o daños a la propiedad. En caso de que TLAC sea considerado responsable por cualquier daño, la responsabilidad total de TLAC estará limitada al monto pagado por el Usuario por los servicios correspondientes en el periodo de tres meses anteriores al evento que dio lugar al daño. TLAC no será responsable por los daños que puedan surgir como resultado de la conducta de terceros, incluyendo otros Usuarios de TLAC o proveedores de servicios externos. Estas disposiciones con respecto a la responsabilidad por daños se aplicarán en la medida máxima permitida por la ley aplicable.
            <h3>Responsabilidad por enfermedades y alergias alimentarias:</h3> TLAC se esforzará por garantizar que todos los alimentos y bebidas ofrecidos a los clientes sean seguros para el consumo humano. Sin embargo, TLAC no puede garantizar la seguridad absoluta de los alimentos y bebidas ofrecidos a los clientes, ya que ciertos riesgos inherentes a la producción, almacenamiento y transporte de alimentos y bebidas pueden existir. Por lo tanto, el cliente acepta que TLAC no será responsable de ninguna enfermedad, lesión o daño que pueda surgir del consumo de los alimentos y bebidas ofrecidos por TLAC. Además, el cliente acepta que renuncia a cualquier derecho a demandar a TLAC por dicha enfermedad, lesión o daño. TLAC se compromete a tomar todas las medidas razonables para garantizar la seguridad de los alimentos y bebidas ofrecidos a los clientes, incluyendo la adopción de prácticas de higiene y seguridad alimentaria adecuadas. Sin embargo, el cliente reconoce y acepta que ciertos riesgos pueden existir y que TLAC no puede garantizar la seguridad absoluta de los alimentos y bebidas ofrecidos. Además, TLAC se reserva el derecho de retirar cualquier producto de su oferta en cualquier momento si cree que existe un riesgo para la salud y seguridad del consumidor. TLAC también se compromete a cumplir con todas las leyes y regulaciones aplicables relacionadas con la producción, almacenamiento, transporte y venta de alimentos y bebidas. En caso de que surja una reclamación relacionada con la seguridad de los alimentos y bebidas ofrecidos por TLAC, TLAC se compromete a investigar la reclamación de buena fe y tomar las medidas adecuadas para abordar cualquier problema de seguridad alimentaria que pueda haber surgido. Sin embargo, TLAC no acepta ninguna responsabilidad por cualquier reclamo relacionado con la seguridad de los alimentos y bebidas ofrecidos por TLAC y se reserva el derecho a defenderse de cualquier reclamación presentada en su contra.
            <h3>Idioma:</h3> Este Contrato se ha redactado en español y cualquier traducción del mismo se proporciona únicamente como una cortesía. En caso de cualquier conflicto entre la versión en español y una versión traducida, prevalecerá la versión en español.
            <h3>Contacto:</h3> Si el Usuario tiene alguna pregunta sobre este Contrato o los servicios ofrecidos por TLAC, puede ponerse en contacto con TLAC a través de la información de contacto proporcionada en la página web de TLAC.
            <h3>Aceptación:</h3> Al acceder y utilizar los servicios de TLAC, el Usuario reconoce haber leído, entendido y aceptado los términos y condiciones de este Contrato y se compromete a cumplir con ellos.
            <h3>Resolución de conflictos:</h3> Este contrato se regirá e interpretará de acuerdo con las leyes mexicanas, sin considerar conflictos de leyes. Cualquier disputa que surja de este contrato será resuelta por medios alternativos de resolución de conflictos, como la mediación o el arbitraje, de acuerdo con las reglas del Centro de Arbitraje mexicano. Si las partes no pueden resolver el conflicto por medios alternativos, las partes acuerdan someterse a la jurisdicción exclusiva de los tribunales de Tijuana, Baja California.
            <h3>Acuerdo completo:</h3> Este contrato constituye el acuerdo completo entre TLAC y el usuario en relación con el acceso y uso de los servicios de TLAC y reemplaza cualquier acuerdo anterior entre las partes. Si alguna disposición de este contrato es considerada inválida o inaplicable, dicha disposición será eliminada y las demás disposiciones permanecerán en pleno vigor y efecto.

            <h2>Política de Privacidad de tlac.com.mx</h2>

            Fecha de entrada en vigor: 01 enero-2023

            Esta Política de Privacidad establece cómo tlac.com.mx ("nosotros", "nuestro", "nos") recopila, utiliza y protege la información personal que usted ("usuario", "su") proporciona en el sitio web https://www.tlac.com.mx/ ("Sitio"). Nos comprometemos a proteger su privacidad y a cumplir con las leyes y regulaciones de protección de datos aplicables en México. Lea detenidamente esta Política de Privacidad para comprender nuestras prácticas en relación con su información personal.

            <h3>Información que recopilamos</h3>
            Podemos recopilar la siguiente información personal y no personal cuando interactúa con nuestro Sitio:
            a) Información proporcionada por usted: Podemos solicitar y recopilar información personal cuando se registra en nuestro Sitio, crea una cuenta de usuario, realiza compras en línea, participa en encuestas o promociones, se comunica con nosotros a través de formularios de contacto o nos proporciona información de cualquier otra manera. Esta información puede incluir su nombre, dirección de correo electrónico, número de teléfono, dirección de envío y facturación, y cualquier otra información relevante para brindarle nuestros servicios.

            b) Información recopilada automáticamente: Podemos utilizar tecnologías de seguimiento, como cookies y balizas web, para recopilar automáticamente cierta información no personal sobre su visita al Sitio. Esto puede incluir su dirección IP, tipo de navegador, proveedor de servicios de Internet (ISP), páginas visitadas, fecha y hora de acceso, y datos de clics. Estos datos nos ayudan a mejorar su experiencia en el Sitio, analizar tendencias y administrar el Sitio.

            <h3>Uso de la información</h3>
            Utilizamos la información recopilada para los siguientes fines:
            a) Proporcionar y mantener nuestros servicios: Utilizamos su información para procesar y completar sus transacciones, brindarle soporte al cliente, responder a sus consultas y proporcionarle la información y los servicios solicitados.

            b) Personalización y mejora del Sitio: Utilizamos la información para personalizar su experiencia en el Sitio, adaptar nuestros productos y servicios a sus preferencias, y mejorar continuamente nuestro Sitio en base a sus comentarios y sugerencias.

            c) Comunicaciones promocionales: Si usted nos ha dado su consentimiento, podemos utilizar su información para enviarle comunicaciones promocionales, como boletines informativos, ofertas especiales y actualizaciones de productos. Puede optar por no recibir dichas comunicaciones en cualquier momento siguiendo las instrucciones proporcionadas en los correos electrónicos.

            d) Cumplimiento de obligaciones legales: Podemos utilizar su información para cumplir con nuestras obligaciones legales, incluyendo el cumplimiento de las leyes y regulaciones aplicables, la respuesta a solicitudes legales y gubernamentales, y la protección de nuestros derechos legales.

            <h3>Compartir información con terceros</h3>
            Podemos compartir su información personal con terceros en las siguientes circunstancias:
            a) Proveedores de servicios: Podemos compartir su información con proveedores de servicios externos que nos asisten en la prestación de servicios, como procesamiento de pagos, envío de productos, análisis de datos y marketing. Estos proveedores de servicios están obligados contractualmente a proteger la confidencialidad y seguridad de su información personal y solo pueden utilizarla de acuerdo con nuestras instrucciones.

            b) Cumplimiento legal: Podemos divulgar su información personal cuando sea necesario para cumplir con una obligación legal, como responder a solicitudes legales y gubernamentales, cumplir con procesos legales, proteger nuestros derechos legales y abordar situaciones de fraude o seguridad.

            c) Consentimiento: Podemos compartir su información con terceros cuando haya dado su consentimiento explícito para dicha divulgación.

            <h3>Protección de la información</h3>
            Nos comprometemos a proteger la seguridad de su información personal. Implementamos medidas técnicas y organizativas apropiadas para proteger su información contra acceso no autorizado, uso indebido, alteración o destrucción.
            Sin embargo, tenga en cuenta que ninguna medida de seguridad en Internet puede garantizar la protección absoluta de la información. Le recomendamos que tome medidas adicionales para proteger su información personal, como mantener sus contraseñas de forma segura y no compartirlas con terceros.

            <h3>Retención de datos</h3>
            Mantendremos su información personal durante el tiempo necesario para cumplir con los fines establecidos en esta Política de Privacidad, a menos que se requiera o permita un período de retención más largo según la ley aplicable.

            <h3>Derechos del titular de los datos</h3>
            Usted tiene ciertos derechos en relación con su información personal, incluyendo el acceso, rectificación, cancelación y oposición al tratamiento de sus datos personales. Puede ejercer estos derechos enviándonos una solicitud por escrito a través de los medios de contacto proporcionados al final de esta Política de Privacidad.

            <h3>Enlaces a sitios de terceros</h3>
            Nuestro Sitio puede contener enlaces a sitios web de terceros. No nos responsabilizamos de las prácticas de privacidad o el contenido de dichos sitios web. Le recomendamos que revise las políticas de privacidad de los sitios web de terceros antes de proporcionar cualquier información personal.

            <h3>Cambios a esta Política de Privacidad</h3>
            Nos reservamos el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento y sin previo aviso. Cualquier cambio entrará en vigor inmediatamente después de su publicación en el Sitio. Le recomendamos que revise periódicamente esta Política de Privacidad para estar informado sobre cómo protegemos su información personal.

            <h3>Contacto</h3>
            Si tiene alguna pregunta, inquietud o solicitud relacionada con esta Política de Privacidad o el manejo de sus datos personales, puede comunicarse con nosotros a través de los siguientes medios de contacto:

            <span>tlac.oficial@gmail.com</span>

            <span>Última actualización: 23-mayo-2023</span>
        </div>
        }
    </div>)
};

export default LegalPage;