import { message } from 'antd';


const isInvalid = recipe=>{
    if(!recipe['name']['spanish'].length || !recipe['name']['spanish'].length){
        message.error("falta nombre");
        return true;
    }
    if(!recipe['description']['spanish'].length || !recipe['description']['spanish'].length){
        message.error("falta descripcion");
        return true;
    }
    if(!recipe['time']){
        message.error("tiempo invalido");
        return true;
    }
    if(!recipe['porcion']){
        message.error("porciones invalidas");
        return true;
    }
    
    return false;
}

const csvJSON = (csv,c1,c2,c3, setError)=>{
    let err = false;
    let table = csv.split("\n").map(item=> item.split(','));
    let recipe ={};
    try{
    recipe["name"] = {
                    english: table[0][1],
                    spanish: table[1][1]
                    }; 
    recipe["description"] = {
        english: table[2][1],
        spanish: table[3][1]
        }; 
    recipe["time"] = parseInt(table[4][1]);
    recipe["porcion"] = parseInt(table[5][1]);
    try {
    recipe["dificulty"] = {Facil: {spanish:"Facil",english:"Easy"},
                            Normal: {spanish:"Normal",english:"Normal"},
                            Experto: {spanish:"Experto",english:"Hard"}
                            }[table[6][1]];
    if(!recipe["dificulty"]){
        recipe["dificulty"] = {spanish:"Facil",english:"Easy"};
        err= true
    }
    }catch(ex){
        recipe["dificulty"] = {spanish:"Facil",english:"Easy"};
        err = true; 
    }
    recipe["is_vegan"] = table[7][1]?true:false;
    recipe["is_gluten_free"] = table[8][1]?true:false;
    recipe["is_low_calories"] = table[9][1]?true:false;
    recipe["is_soy_free"] = table[10][1]?true:false;
    recipe["is_veggie"] = table[11][1]?true:false;
    recipe["is_lactose_free"] = table[12][1]?true:false;
    recipe["is_low_sodium"] = table[13][1]?true:false;
    recipe["is_sugar_free"] = table[14][1]?true:false;
    recipe["notes"] = {
        english: table[16][1],
        spanish: table[15][1]
        }; 
    const categoryList = table.filter((f,i)=> f[2] && i).map(item=> item[2]);
    try{
        if(!categoryList.length){
            err = true;
            message.error("faltan categorias");
        }
        recipe["categories"] = {
            english: categoryList.map(item=> c2.split('\n').filter(f=> f.includes(item))[0].split(',')[0]).toString(),
            spanish: categoryList.toString()
        }
    }catch(ex){
        message.error('categoria invalida');
        err = true;
    }
    c1 = c1.split('\n').map(item=> item.split(','));
    c3 = c3.split('\n').map(item=> item.split(','));
    try{
        const quantityList = table.filter((f,i)=> f[3] && i).map(item=> {
            return {
                ingredient: c1.filter(f=> f[0] === item[3])[0][1] ,
                is_main: item[4]?true:false,
                quantity: item[5],
                unit: {
                    spanish: item[6],
                    english: c3.filter(f=> f[1] === item[6])[0][0] 
                }
            };
        });
        if(!quantityList.length){
            err = true;
            message.error("faltan ingredientes");
        }
        recipe["quantities"] = quantityList;
    }catch(ex){
        console.log(ex);
        err = true;
        message.error("error al cargar ingredientes");
    }
    

    recipe["steps"] = table.filter((f,i)=> f[7] && i>1).map((item,i)=> {
        return {
            description: {
                spanish: item[7],
                english: item[8]
            },
            step: i+1
        };
    });

    if(!recipe["steps"].length)
        err = true;

    recipe["utensils"] = table.filter((f,i)=> f[9] && i>1).map(item=> {
        return {
                spanish: item[9],
                english: item[10]
            };
    });
    console.log(err);

    setError(false || err || isInvalid(recipe));
    if(false || err || isInvalid(recipe))
        message.error("formato incorrecto");
    
}catch(ex){
    setError(true);
    message.error("archivo invalido");
}


    return recipe; //JSON
  }

  export default csvJSON;