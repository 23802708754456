import firebaseConfig from './firebaseIndex.js';
import firebase from 'firebase/app';
import {message, notification} from 'antd';
import notfoundimage from '../pics/notavailable.js';
import sw from '../utils/multilingual.json';
import { getImageURL } from './firestore-images.js';
import { fa } from './analytics.js';
import { getConfigJson } from './firebaseConfig.js';
// import { loadHomeTutorial } from '../utils/tutorials.js';

if(firebaseConfig)
    console.log("Running firebase firestore");

const db = firebase.firestore(); 
const firestorage = firebase.storage();
const language = localStorage.getItem('language') ?? ((window.navigator.userLanguage || window.navigator.language).split('-')[0] === 'es'? 'spanish':'english');

export const dbMethods = {
    addRecipe: async (recipe)=> {
        console.log("add recipe");
        if(!recipe.name.spanish || !recipe.name.english){
            message.warning('Agregue nombre (español/ingles) por favor');
            return;
        }
        if(!recipe.description.spanish || !recipe.description.spanish){
            message.warning('Agregue descripcion (español/ingles) por favor');
            return;
        }
        if(!recipe.categories.spanish.length || !recipe.categories.english.length){
            message.warning('Agregue categorias por favor');
            return;
        }
        if(!recipe.notes.spanish || !recipe.notes.english){
            message.warning('Agregue sus notas (español/ingles) por favor');
            return;
        }
        if(!recipe.quantities.length){
            message.warning('Agregue ingredientes por favor');
            return;
        }
        if(!recipe.steps.length){
            message.warning('Agregue pasos de preparación por favor');
            return;
        }
        // if(!recipe.utensils.length){
        //     message.warning('Agregue utensilios por favor');
        //     return;
        // }

        // for(let index in recipe.steps){
        //     if(!recipe.steps[index].spanish || !recipe.steps[index].english){
        //         message.warning('Por favor agregue texto en en paso '.concat((index + 1)));
        //         return;
        //     }
        // }

        // for(let index in recipe.utensils){
        //     if(!recipe.utensils[index].spanish || !recipe.utensils[index].english){
        //         message.warning('Por favor agregue texto en en utensilio'.concat((index + 1)));
        //         return;
        //     }
        // }

        // const categories = recipe.categories;
        // recipe.categories = {
        //     english: categories.map(item=> item.english).join(','),
        //     spanish: categories.map(item=> item.spanish).join(',')
        // }

        // recipe.quantities = recipe.quantities.map(qty=> {
        //     return {
        //         ingredient: qty.ingredient.key,
        //         is_main: qty.is_main,
        //         quantity: qty.quantity,
        //         unit: qty.unit
        //     };
        // });

        // recipe.steps = recipe.steps.map((step, index)=> {
        //     return {
        //         description: step,
        //         step: (index + 1)
        //     }
        // });

        console.log(recipe);

        return await db.collection('recipes')
        .add(recipe)
        .then(resp=> {
            dbMethods.uploadPic(resp.id.concat('.jpg'));
            message.info('Receta agregada');
            return true;
        }).catch(err=> {
            message.warning('Lo siento por alguna razon no se puede subir la receta');
            return false;
        });

    },
    getCategories: async ()=> {
        let categories = localStorage.getItem('categories');
        if(categories){
            return JSON.parse(categories);
        }

        categories = await db.collection('categories').get().then(function(querySnapshot){
            console.log("get categories");
            return querySnapshot.docs.map(item=> {
                return item.data();
            });
        });

        localStorage.setItem('categories',JSON.stringify(categories));

        return categories;
    },
    getUnits: async ()=> {
        let units = localStorage.getItem('units');
        if(units){
            return JSON.parse(units);
        }

        units = await db.collection('units').get().then(function(querySnapshot){
            console.log("get units")
            return querySnapshot.docs.map(item=> {
                return item.data().name;
            });
        });

        localStorage.setItem('units',JSON.stringify(units));

        return units;
    },
    getIngredients: async ()=> {
        return await db.collection('ingredients').get().then(function(querySnapshot){
            console.log("get ingredients")
            return querySnapshot.docs.map(item=> {
                let data = item.data();
                data['key'] = item.id;
                return data;
            });
        });
    },
    addIngredient: async (ingredient)=> {
        db.collection('ingredients').doc(ingredient.name.english.trim()).set(ingredient).then(resp=> {
            console.log("add ingredient")
            message.info(ingredient.name.spanish.concat(' agredado a ingredientes'));
        });
    },
    uploadPic: async (name)=> {
        firestorage.ref('recipes-pic').child(name).putString(notfoundimage,'base64',{contentType:'image/jpg'})
        .then(snapshot=> console.log(snapshot))
        .catch(err=> console.log(err));
    },
    uploadProfilePic: async (file,format)=> {
        firebase.auth().onAuthStateChanged(async user=> {
            firestorage.ref('users').child('profilePic').child(user.uid).putString(file,'base64',{contentType:format})
            .then(snapshot=> user.updateProfile({photoURL: getImageURL(user.uid, null,'users%2FprofilePic')}))
            .catch(err=> console.log(err));
        });
    },
    getRecipes: async (setRecipes, setLoading, isSnack = false) => {
        setLoading(true)
        let recipes = localStorage.getItem('recipes');
        firebase.auth().onAuthStateChanged(async user=> {
            if(user){
                // const allowed = await db.collection('user_preferences').doc(user.uid).get().then(function(querySnapshot){
                //     console.log("get user_preferences");
                //     const data = querySnapshot.data(); 
                //     return data;
                // });
                if(!recipes || (localStorage.getItem('lastUpdated') && 
                Math.ceil(Math.abs(new Date(parseInt(localStorage.getItem('lastUpdated'))) - new Date()) / (1000 * 60 * 60 * 24)) > 28) ){

                recipes = await db.collection('recipes').where('is_cooked_available','==', true).get().then(function(querySnapshot){
                    console.log("get recipes")
                    return querySnapshot.docs.map(item=> {
                        let data = item.data();
                        data['key'] = item.id; 
                        return data;
                    });
                });

                localStorage.setItem('recipes',JSON.stringify(recipes));
                localStorage.setItem('lastUpdated',new Date().getTime())
                }else{
                    try{
                        recipes = JSON.parse(recipes);
                        // console.log(recipes.map(r => r.name))
                        // if(['is_sugar_free', 'is_low_sodium', 'is_low_calories'].includes(allowed?.diet ?? 'all'))
                        //     recipes = recipes.filter(f => f[allowed.diet]);
                    }catch(ex) {
                        recipes = []
                    }
                }
                // if (allowed) {
                    // const a = new Set(allowed.ingredient_ids);
                    // console.log(a);

                    // recipes = recipes.filter(recipe => {
                    //     const ri = new Set(recipe.quantities.map(q=> q.ingredient));
                    //     let intersection = new Set(
                    //         [...a].filter(x => ri.has(x)));
                    //     recipe['visible'] = true;
                    //     return intersection.size === ri.size;
                    // });
                    console.log(recipes)
                    recipes = recipes.filter(f => isSnack ?  f.is_snack : !f.is_snack);

                    setRecipes(recipes.map(recipe => ({...recipe, visible: true})))
                    setLoading(false)
                // }
            }
        });
    },
    getRecipesFormated: async (setRecipes, setData, formatData,setUserId,setLoading)=> {
        let recipes = localStorage.getItem('recipes');
        firebase.auth().onAuthStateChanged(async user=> {
            if(user){
                if(setUserId)
                    setUserId(user.uid);
                const allowed = await db.collection('user_preferences').doc(user.uid).get().then(function(querySnapshot){
                    console.log("get user_preferences");
                    const data = querySnapshot.data(); 
                    return data;
                });
                if(!recipes || (localStorage.getItem('lastUpdated') && 
                Math.ceil(Math.abs(new Date(parseInt(localStorage.getItem('lastUpdated'))) - new Date()) / (1000 * 60 * 60 * 24)) > 28) ){

                recipes = await db.collection('recipes').where('is_cooked_available','==', true).get().then(function(querySnapshot){
                    console.log("get recipes")
                    return querySnapshot.docs.map(item=> {
                        let data = item.data();
                        data['key'] = item.id; 
                        data['visible'] = true;
                        return data;
                    });
                });

                localStorage.setItem('recipes',JSON.stringify(recipes));
                localStorage.setItem('lastUpdated',new Date().getTime())
                }else{
                    try{
                        recipes = JSON.parse(recipes);
                        // console.log(recipes.map(r => r.name))
                        if(['is_sugar_free', 'is_low_sodium', 'is_low_calories'].includes(allowed?.diet ?? 'all'))
                            recipes = recipes.filter(f => f[allowed.diet]);
                    }catch(ex) {
                        recipes = []
                    }
                }
                if (allowed) {
                    const a = new Set(allowed.ingredient_ids);
                    // console.log(a);

                    recipes = recipes.filter(recipe => {
                        const ri = new Set(recipe.quantities.map(q=> q.ingredient));
                        let intersection = new Set(
                            [...a].filter(x => ri.has(x)));
                        // if (intersection.size !== ri.size) {
                        //     console.warn(`Unsuported recipe because ingredients: ${recipe.key} : ${recipe.name.english}`);
                        //     console.table(recipe.quantities)
                        //     recipe.quantities.forEach(qty => {
                        //         if (!allowed.ingredient_ids.includes(qty.ingredient)){
                        //             console.log(qty.ingredient)
                        //         }
                        //     });
                        // }
                            
                        return intersection.size === ri.size;
                    })
                }

                let categories = localStorage.getItem('categories');
                if(!categories){
                    categories = await db.collection('categories').get().then(function(querySnapshot){
                        console.log("get categories")
                        return querySnapshot.docs.map(item=> {
                            return item.data();
                        });
                    });
                    localStorage.setItem('categories',JSON.stringify(categories));
                }
                else{
                    categories = JSON.parse(categories);
                }
                // console.log(recipes.map(r => r.name))
                setRecipes({
                        "categories": categories,
                        "recipes": recipes
                    });

                // Loads in 2 parts
                let d = formatData(categories, recipes);
                const half = d?.slice(0, d.length / 2);
                // loads first half
                setData(half);
                // loads second half
                setTimeout(() => setData(prev => [...prev, ...d?.slice(d.length / 2, d.length)]), 700);
                
                if(setLoading)
                    setLoading(false);
                
                // adding tutorial if needed
                if (!localStorage.getItem(`first_home_${user.uid}`)) {
                    console.log('first time')
                    // loadHomeTutorial();
                    fa('first_time');
                    localStorage.setItem(`first_home_${user.uid}`,'true');
                }
            }
        });
       
    },
    getStories: async ()=>{
        let stories = null;
        if(!stories){
            return await db.collection('stories').get().then(function(querySnapshot){
                console.log("get stories")
                return querySnapshot.docs.map(item=> item.data())
            });
        }
        return []
    },
    getPreferences: async (word,startAt,setStartAt,language,diet,setPreferences,setCurrentPrefs,pref,modified, selectDiet)=> {
        firebase.auth().onAuthStateChanged(async user=> {
            if(user){
                // let pref = []; 
                if(!diet){
                    pref = await db.collection('user_preferences').doc(user.uid).get()
                            .then(function(querySnapshot){
                                console.log("get user_preferences")
                                return querySnapshot.data();
                            })
                            .catch(err=> null);
                    if (!pref) {
                        selectDiet('all');
                        return;
                    }
                    setCurrentPrefs(prev=> ({...prev, 
                                            ingredient_ids:pref? pref.ingredient_ids:[],
                                            diet:pref && pref.diet}));
                }

                let query = null;
                if(word)
                    query = db.collection('ingredients')
                                .where('is_used','==',true)
                                .where('keywords.'.concat(language),'array-contains',word);
                else
                    query = db.collection('ingredients')
                                .where('is_used','==',true)
                                .orderBy('name.'.concat(language))
                                .startAt(startAt)
                                .limit(35);

                query.get().then(function(querySnapshot){
                    console.log("get ingredients");
                    const ingredients = querySnapshot.docs.map(item => {
                        let data = item.data();
                        data['key'] = item.id;
                        if(pref)
                            if(modified && (modified[item.id] === true || modified[item.id] === false) )
                                data['is_checked'] = modified[item.id];
                            else
                                data['is_checked'] = diet? (['all', 'is_sugar_free', 'is_low_sodium', 'is_low_calories'].includes(diet) ? true : data[diet]):pref.ingredient_ids.includes(item.id);

                        data['visible'] = true;
                        console.log(data);
                        return data;
                    });
                    console.log(ingredients)
                    if(word){
                        setStartAt('');
                        setPreferences(ingredients);
                    }else{
                        if(ingredients.length && startAt !== ingredients[ingredients.length]?.key)
                            setPreferences(prev=> [...prev.concat(ingredients)]);
                        if (ingredients.length)
                            setStartAt(ingredients[ingredients.length - 1]?.name[language]);
                    }
                });   
            }
        });
    },
    setPreferences: async (preferences,changes, setRecipes, setData, formatData,language,setLoading)=> {
        console.log(preferences)
        const update = preferences=> {
            preferences['uid'] = firebase.auth().currentUser.uid;
            console.log(preferences)
            db.collection('user_preferences').doc(preferences['uid']).update(preferences)
            .then(success=> {
                message.success(sw.preferencesSaved[language]);
                //Reload recipes
                if(setRecipes && setData && formatData && setLoading)
                    dbMethods.getRecipesFormated(setRecipes,setData,formatData,null,setLoading);
            })
            .catch(ex=> 
                db.collection('user_preferences').doc(preferences['uid']).set(preferences)
                .then(success=> {
                    message.success(sw.preferencesSaved[language]);
                    //Reload recipes
                    if(setRecipes && setData && formatData && setLoading)
                        dbMethods.getRecipesFormated(setRecipes,setData,formatData,null,setLoading);
                })
                .catch(ex=> message.warning(sw.somethingWentWrong[language]))
            );
        };
        if(Object.keys(changes.modified).length && changes.diet) {
            if(preferences && preferences['diet'] === changes.diet){// in case of modified and diet changed
                let newPrefs = {};
                for(let index in preferences.ingredient_ids)
                    newPrefs[preferences.ingredient_ids[index]] = true;

                for(const [key,value] of Object.entries(changes.modified))
                    newPrefs[key] = value;
                
                preferences.ingredient_ids = Object.keys(newPrefs).filter(key=> newPrefs[key]);
                preferences['diet'] = changes.diet;
                update(preferences);
            }
            else{
                if(['all', 'is_sugar_free', 'is_low_sodium', 'is_low_calories'].includes(changes.diet))
                    db.collection('ingredients').where('is_used','==',true).get()
                        .then(result=> {
                            let newPrefs = {};
                            for(let index in result.docs)
                                newPrefs[result.docs[index].id] = true;
                            
                            for(const [key,value] of Object.entries(changes.modified))
                                newPrefs[key] = value;
                            
                            preferences.ingredient_ids = Object.keys(newPrefs).filter(key=> newPrefs[key]);
                            preferences['diet'] = changes.diet;
                            update(preferences);

                        })
                        .catch(ex=> message.error(sw.somethingWentWrong[language]));
                else{
                    db.collection('ingredients')
                    .where('is_used','==',true)
                    .where(changes.diet,'==',true)
                    .get()
                    .then(result=> {
                        let newPrefs = {};
                        for(let index in result.docs)
                            newPrefs[result.docs[index].id] = true;
                        
                        for(const [key,value] of Object.entries(changes.modified))
                            newPrefs[key] = value;
                        
                        preferences.ingredient_ids = Object.keys(newPrefs).filter(key=> newPrefs[key]);
                        preferences['diet'] = changes.diet;
                        update(preferences);

                    })
                    .catch(ex=> message.error(sw.somethingWentWrong[language]));
                }
            }
        }
        else if(changes.diet){
            if(['all', 'is_sugar_free', 'is_low_sodium', 'is_low_calories'].includes(changes.diet))
                db.collection('ingredients').where('is_used','==',true).get()
                    .then(result=> {
                        preferences.ingredient_ids = result.docs.map(item=> item.id);
                        preferences['diet'] = changes.diet;
                        update(preferences);

                    })
                    .catch(ex=> message.error(sw.somethingWentWrong[language]));
                else{
                    db.collection('ingredients')
                    .where('is_used','==',true)
                    .where(changes.diet,'==',true)
                    .get()
                    .then(result=> {
                        preferences.ingredient_ids = preferences.ingredient_ids = result.docs.map(item=> item.id);
                        preferences['diet'] = changes.diet;
                        update(preferences);

                    })
                    .catch(ex=> message.error(sw.somethingWentWrong[language]));
                }

            
        }
        else{
            let newPrefs = {};
            for(let index in preferences.ingredient_ids)
                newPrefs[preferences.ingredient_ids[index]] = true;

            for(const [key,value] of Object.entries(changes.modified))
                newPrefs[key] = value;
            
            preferences.ingredient_ids = Object.keys(newPrefs).filter(key=> newPrefs[key]);
            update(preferences);
        }
    },
    getRecipe: async (recipeId,setRecipe,language,setUser)=> {
        if(setUser)
            firebase.auth().onAuthStateChanged(user=> {if(user) setUser(user.uid)});
        
        let recipes = JSON.parse(localStorage.getItem('recipes'));
        let recipe = null;
        if(recipes && (recipes = recipes.filter(f=> f.key === recipeId)).length){
            recipe = recipes[0];
            const ingredients = recipe.quantities.map(item=> item.ingredient);
            for(let index in ingredients) {
                recipe.quantities[index]['ingredient'] = await db.collection('ingredients').doc(recipe.quantities[index]['ingredient']).get()
                .then(value=> value.data());
            }
            setRecipe(recipe);
            document.getElementById('nav-bar-title').innerHTML = recipe.name[language];
        }else{
            db.collection('recipes').doc(recipeId).get().then(async function(querySnapshot){
                console.log("get recipes");
                if(querySnapshot.exists){
                    recipe = querySnapshot.data();
                    recipe['key'] = querySnapshot.id;
                    const ingredients = recipe.quantities.map(item=> item.ingredient);
                    
                    for(let index in ingredients) {
                        recipe.quantities[index]['ingredient'] = await db.collection('ingredients').doc(recipe.quantities[index]['ingredient']).get()
                        .then(value=> value.data());
                    }
                    setRecipe(recipe);
                    document.getElementById('nav-bar-title').innerHTML = recipe.name[language];
                    console.log(recipe)
                }
                else{
                    console.warn('recipe id not found');
                    setRecipe('NotFound');
                }
            })
            .catch(err=> console.warn(err));
        }
    },
    getInstagramPosts: (recipeId, setInstagramPosts) => {
        db.collection('recipe_posts').doc(recipeId).get()
            .then(resp => {
                if (resp.data()?.posts)
                    setInstagramPosts(resp.data().posts);
            })
            .catch(err => console.error(err.message));
    },
    isLiked: async (recipeId, setLiked)=> {
        firebase.auth().onAuthStateChanged(async user=> {
            if(user)
                db.collection('likes').where('recipe_id','==',recipeId).where('uid','==',user.uid).get()
                    .then(resp=> setLiked(!resp.empty));
        });
    },
    getUserLikes: async (setLikes)=> {
        firebase.auth().onAuthStateChanged(async user=> {
            if(user)
                db.collection('likes').where('uid','==',user.uid).get()
                    .then(resp=> setLikes(resp.docs.map(item=> item.data().recipe_id)))
                    .catch(err=> console.error(err));
        });
    },
    likeRecipe: async (recipeId, liked, setLiked, setRecipe, likeBtn)=> {
        firebase.auth().onAuthStateChanged(async user=> {
            const increment = firebase.firestore.FieldValue.increment(liked? -1:1);
            db.collection('recipes').doc(recipeId).update({ likes: increment })
                .then(resp=> {
                    let recipes = localStorage.getItem('recipes');
                    if(recipes){
                        recipes = JSON.parse(recipes);
                        const index = recipes.findIndex(f=> f.key === recipeId);
                        recipes[index]['likes'] = recipes[index]['likes'] + (liked? -1:1);
                        localStorage.setItem('recipes',JSON.stringify(recipes));
                    }
                    if(liked)
                        db.collection('likes').where('recipe_id','==',recipeId).where('uid','==',user.uid).get()
                            .then(resp=> db.collection('likes').doc(resp.docs[0].id).delete());
                    else
                        db.collection('likes').add({recipe_id:recipeId,uid:user.uid});
                    setLiked(!liked);
                    setRecipe(prev=> ({...prev,likes:!liked?++prev.likes:--prev.likes}));
                    likeBtn.disabled = false;
                });
        });   
    },
    getZones: async (setZones, city='Tijuana')=> {
            // if(!isCities)
            //     db.collection('allowed_zones').get()
            //         .then(resp=> setCities(resp.docs.map(item=> item.id)));

            db.collection('allowed_zones').doc(city).collection('zones').get()
                .then(resp=> {
                    setZones(resp.docs.map((item,i)=> {
                        let zone = item.data();
                        zone['key'] = item.id;
                        if(i===0){
                            zone['zoom'] = 15;
                            // setDeliverAddress(zone);
                        }
                        return zone;
                        }
                        ));
                });
    },
    getAddresses: async (setAddresses, setSelectedAddress)=> {
        firebase.auth().onAuthStateChanged(async user=> {
            if(user)
                db.collection('user_addresses').doc(user.uid).get()
                    .then(resp=> {
                        let addresses = resp?.data()?.addresses ?? [];
                        addresses = addresses
                            .sort((a, b) => b.selectedDate - a.selectedDate);

                        setAddresses(
                            addresses                            
                                .map((item, i) => {
                                    if(!i && setSelectedAddress)
                                        setSelectedAddress(item);
                                    return item;
                                })
                        );
                    })
                    .catch(error => {
                        console.error(error.message)
                    });
        });   
    },
    selectAddress: async (address, addresses, setAddresses, setSelectedAddress)=> {
        const newDate = new Date().getTime();
        let localAddresses = addresses;
        firebase.auth().onAuthStateChanged(async user=> {
            const selectedItem = localAddresses.findIndex(f => f.selectedDate === address);
            localAddresses[selectedItem].selectedDate = newDate;
            if(user)    
                db.collection('user_addresses').doc(user.uid)
                    .update({ addresses: localAddresses})
                    .then(() => {
                        if (setAddresses) {
                            setAddresses(
                                localAddresses
                                    .sort((a, b) => b.selectedDate - a.selectedDate)
                                    .map((item, i) => {
                                        if(!i && setSelectedAddress)
                                            setSelectedAddress(item);
                                        return item;
                                    })
                            )
                        }
                    });
        });
    },
    saveNewAddress: async (newAddress,language)=> {
        firebase.auth().onAuthStateChanged(async user=> {
            if(user){    
                newAddress['uid'] = user.uid;
                db.collection('user_addresses').doc(user.uid).get()
                .then(resp => {
                    const addresses = resp?.data()?.addresses;
                    if (addresses) {
                        addresses.push(newAddress);
                        resp.ref.update({ addresses: addresses })
                    }
                    else {
                        resp.ref.set({ addresses: [newAddress] });
                    }
                    message.success(sw.addressAdded[language]);
                    setTimeout(()=> window.location.href = '/',1500);
                })
                .catch(err=> {
                    if (err.code === 'permission-denied') {
                        console.log('second try')
                        db.collection('user_addresses').doc(user.uid).set({ addresses: null })
                            .then(() => {
                                dbMethods.saveNewAddress(newAddress, language);
                            })
                            .catch(e => console.error(e.message));
                    }
                });
            }
        });
    },
    removeAddress: async (key, addresses, setAddresses,setSelectedAddress,language)=> {
        const localAddresses = addresses.filter(f => f.selectedDate !== key);
        firebase.auth().onAuthStateChanged(async user=> {
            db.collection('user_addresses').doc(user.uid).update({ addresses: localAddresses })
                .then(resp=> {
                    if (setAddresses) {
                        setAddresses(
                            localAddresses
                                .sort((a, b) => b.selectedDate - a.selectedDate)
                                .map((item, i) => {
                                    if(!i && setSelectedAddress)
                                        setSelectedAddress(item);
                                    return item;
                                })
                        )
                    }
                })
                .catch(err=> message.error(sw.orderFailed[language]))
        });       
    },
    getOrders: async (start,end,setOrders)=> {
        firebase.auth().onAuthStateChanged(async user=> {
            if(user)
                db.collection('user_orders')
                    .where('uid','==',user.uid)
                    .where('is_active', '==', true)
                    .where('dateTime','>=',start)
                    .where('dateTime','<=',end)
                    .orderBy('dateTime','desc')
                    .get()
                    .then(resp=> {
                        if (!resp.docs.length) {
                            db.collection('user_orders')
                                .where('is_active', '==', true)
                                .orderBy('dateTime','desc')
                                .limit(1)
                                .get()
                                .then(latest => {
                                    console.log(latest)
                                    if (!latest.empty) {
                                        let order = latest.docs[0].data();
                                        order['key'] = latest.id;
                                        order['isLatest'] = true;
                                        setOrders([order]);
                                    }
                                })
                                
                            return;
                        }
                        setOrders(resp.docs.map(item=> {
                            let order = item.data();
                            order['key'] = item.id;
                            return order;
                        }));
                    })
        });
    },
    callPhone: async (uid) => {
        db.collection('user_phones').doc(uid).get()
            .then(res => {
                if (res.exists) {
                    const data = res.data();
                    window.location.href = `tel:${data.phone}` 
                }
                else message.error('Numero de telefono no disponible')
            })
            .catch(e => console.log(e))
    },
    getWeekOrders: async (setOrders) => {
        const lastWeek = new Date();
        lastWeek.setDate((new Date()).getDate() - 7);
        db.collection('user_orders')
            .where('scheduled', '>=', lastWeek)
            .where('is_active', '==', true)
            .where('is_canceled', '==', false)
            .orderBy('scheduled', 'desc')
            .get()
            .then(resp => {
                if (resp.docs.length)
                    setOrders(resp.docs.map(item => {
                        let order = item.data();
                        order['key'] = item.id;
                        return order;
                    }));
            })
    },
    cancelOrder: async (start,end,setOrders,key,language)=> {
        db.collection('user_orders').doc(key)
            .update({is_canceled:true})
            .then(resp=> {
                dbMethods.getOrders(start,end,setOrders);
                notification.close(key);
                message.success(sw.orderCanceled[language]);
            })
            .catch(ex=> console.log(ex))

    },
    finishOrder: async (deactivatePaymentLink, key)=> {
        db.collection('user_orders').doc(key)
            .update({is_active: true})
            .then(resp=> {
                db.collection('user_orders').doc(key).get()
                .then(result => {
                    const data = result.data();
                    console.log(data);
                    if (data) {
                        deactivatePaymentLink(data?.paymentlink_id);
                        db.collection('shoppingcart').doc(data.uid).get()
                        .then(sc => {
                            const data = sc.data();
                            if (data) {
                                const items = data?.items ?? [];
                                result.ref.update({recipes: items})
                                .then(f => sc.ref.delete());
                            }
                        })
                    }
                }); 
            })
            .catch(ex=>  {
                console.log(ex);
                document.location = `${document.location.host}/orders`;
            })

    },
    checkoutOrder: async (key) => {
        db.collection('user_orders').doc(key)
            .get()
            .then(doc => {
                const data = doc?.data();
                if (data) {
                    if (data?.delivered === null && (data?.days ?? 1) === 1) {
                        doc.ref.update({delivered: new Date()})
                            .then(r => {
                                message.success(sw.orderDelivered[language]);
                                fa('order_checked_out');
                            })
                            .catch(e => {
                                message.error(sw.errorOnDelivery[language]);
                                fa('error_order_checked_out');
                            });
                    }
                    else {
                        // updates for more than one day
                        doc.ref.update({
                            delivered: null, 
                            deliveredTimes: (data?.deliveredTimes ?? 0) + 1 , 
                            scheduled: new Date(new Date().setDate(new Date().getDate() + 1))
                        })
                            .then(r => {
                                message.success(sw.orderDelivered[language]);
                                fa('order_checked_out');
                            })
                            .catch(e => {
                                message.error(sw.errorOnDelivery[language]);
                                fa('error_order_checked_out');
                            });
                    }
                }
                else {
                    message.error(sw.orderNotFound[language]);
                    fa('incorrect_order_id-on_checkout');
                }
            })
            .catch(e => {
                message.error(sw.errorOnDelivery[language]);
                fa('error_order_checked_out');
            });
    },
    addOrderPaymentLink: async (paymentLinkId, key, url) => {
        db.collection('user_orders').doc(key)
            .update({paymentlink_id: paymentLinkId})
            .then(resp=> {
                console.info('paymentlink added');
                document.location = url;
            })
            .catch(ex=> console.log(ex))
    },
    createOrder: async (order, language)=> {
        firebase.auth().onAuthStateChanged(async user=> {
            if(user)
                order['uid'] = user.uid;
                order['userName'] = user.displayName;
                db.collection('user_orders')
                    .add(order)
                    .then(resp=> {
                        // localStorage.setItem('cart-items-'.concat(user.uid),'[]');
                        console.log(resp.id);
                        // createPaymentLink(order, resp.id);
                        // document.location = url;
                        // message.success(sw.orderSuccess[language], 3, () => document.location = url);
                    })
                    .catch(err=> {
                        message.error(sw.somethingWentWrong[language]);
                        fa('error-oncreateorder');
                    });    
        });
    },
    isEmployee: async () => {
        firebase.auth().onAuthStateChanged(async user => {
            const isEmployee = (await db.collection('roles').doc(user.uid).get()).exists
            sessionStorage.setItem('isEmployee', isEmployee);
        })
    },
    getUserData: async (setProfile,setAddresses,setSelectedAddress,setHealth)=>{
        firebase.auth().onAuthStateChanged(async user=> {
            console.log(user);
            user.getIdTokenResult().then(res => console.log(res.claims.role))
            let profile= {
                uid:user.uid,
                name:user.displayName,
                pic:user.photoURL,
                email:user.email
            }
            db.collection('user_phones').doc(user.uid).get()
                .then(resp=> {
                    profile['phoneNumber'] = resp.data().phone;
                    setProfile(profile);
                });
            if(setAddresses && setSelectedAddress)
                dbMethods.getAddresses(setAddresses,setSelectedAddress);
            if(setHealth)
                dbMethods.getHealth(user.uid, setHealth);
        });
    },
    getUserPhoneNumber: async (setPhoneNumber) => {
        firebase.auth().onAuthStateChanged(async user => {
            db.collection('user_phones').doc(user.uid).get()
                .then(resp=> {
                    setPhoneNumber(resp.data()?.phone);
                });
        });
    },
    savePhoneNumber: async (phoneNumber) => {
        firebase.auth().onAuthStateChanged(async user => {
            db.collection('user_phones').doc(user.uid).update({ phone: phoneNumber})
                    .then(resp=> console.log(resp))
                    .catch(err=> console.log(err));
        });
    },
    updateOrder: async (key, order) => {
        firebase.auth().onAuthStateChanged(async user=> {
            db.collection('user_orders').doc(key)
            .update(order)
            .then(resp => {
                message.success('entrega terminada');
                setTimeout(() => document.location.reload() , 5000)
            });
        });
    },
    updateProfile: async (profile,isPhoneChanged,isPasswordChanged,language,setChanges)=> {
        firebase.auth().onAuthStateChanged(async user=> {
            if(isPasswordChanged){
                user.updatePassword(profile.password)
                    .then(resp=> console.log('password updated'));
            }

            if(user.displayName !== profile.name){
                user.updateProfile({displayName:profile.name})
                .then(resp=> console.log('display name updated'));
            }

            // if(user.photoURL !== profile.pic){
            //     user.updateProfile({photoURL:profile.pic})
            //         .then(resp=> console.log('picture updated'));
            // }
            
            if(isPhoneChanged){
                db.collection('user_phones').doc(user.uid).update({ phone: profile.phoneNumber})
                    .then(resp=> console.log(resp))
                    .catch(err=> console.log(err));
            }

            setChanges({
                password:false,
                phoneNumber:false
            });
            message.success(sw.profileUpdated[language]);
        });
    },
    loadColors: ()=> {
        let root = document.documentElement;
        db.collection('colors').doc('web').get()
            .then(resp=> {
                const colors = resp.data();
                const colorKeys = Object.keys(colors);
                colorKeys.forEach(key=> root.style.setProperty('--'.concat(key),colors[key]));
            })
    },
    uploadSuggestion: async (suggestion)=> {
        db.collection('suggestions')
            .add(suggestion)
            .then(resp=> {
                message.success(sw.suggestionSuccess[language]);
                setTimeout(()=> window.location = '/',2000); 
            })
            .catch(resp=> sw.somethingWentWrong);
    },
    getHealth: async (uid, setHealth)=> {
        db.collection("user_preferences").doc(uid).get()
            .then(resp=> {
                const result = resp.data();
                console.log(result);
                if(result)
                    setHealth({
                        diet: result.diet? result.diet: 'all',
                        size: result.size? result.size: '0.0',
                        weight: result.weight? result.weight:'0'
                    });
            })
            .catch(resp=> console.log("Error loading preferences"))
    },
    setHealth: async (uid, health)=> {
        health['uid'] = uid;
        const update = preferences => {
        db.collection('user_preferences').doc(health['uid']).update(preferences)
            .then(success=> {
                message.success(sw.preferencesSaved[language]);
            })
            .catch(ex=> {
                db.collection('user_preferences').doc(health['uid']).set(preferences)
                    .then(success=> {
                        message.success(sw.preferencesSaved[language]);
                    })
                    .catch(ex=> message.warning(sw.somethingWentWrong[language]));
            });
        }
        update(health);
    },
    getShoppingCart: async (setShoppingcart, setLoading) => {
        console.log('calling shopping cart')
        if (setLoading) setLoading(true);
        firebase.auth().onAuthStateChanged(async user=> {
            if (user?.uid)
                db.collection('shoppingcart').doc(user.uid).get()
                .then(shoppingCart => {
                    const data = shoppingCart.data()
                    if (data) {
                        setShoppingcart(data);
                        if (localStorage.getItem('dd') === null && data?.pack?.days) 
                            localStorage.setItem('dd', JSON.stringify({days: Object.keys(data?.pack?.days).map(d => Number(d))}))    
                    }
                    else 
                        dbMethods.createShoppingCart(user.uid, []);

                    if (setLoading) setLoading(false);
                })
                .catch(err => {
                    console.log(err)
                    console.log('no items found')
                    dbMethods.createShoppingCart(user.uid, []);
                    if (setLoading) setLoading(false);
                });
        });
    },
    updateShoppingCart: async (uid, items, isMealkit, pack) => {
        await firebase.auth().onAuthStateChanged(async user => {
            if (user?.uid) {
                if (items && pack) {
                    db.collection('shoppingcart').doc(user.uid).update({items, isMealkit, pack}).catch(err => console.error(err));
                    return;
                }
                else if (items) {
                    db.collection('shoppingcart').doc(user.uid).update({items: items}).catch(err => console.error(err));
                    return;
                }
                
                db.collection('shoppingcart').doc(user.uid).update({isMealkit, pack}).catch(err => console.error(err));
            }
        });
    },
    createShoppingCart: (uid, items) => {
        db.collection('shoppingcart').doc(uid).set({items: items}).catch(err => console.error(err));
    },
    setPrices: (setPrices) => {
        db.collection('pricing').get()
        .then(prices => {
            const pricing = prices.docs.map(price => price.data()); 
            if (setPrices) {
                setPrices(pricing);
            }
        });
    },
    getDeliveryDays: () => {
        getConfigJson('packs', (days) => console.log(days))
        // db.collection('config').doc('delivery_days').get()
        //     .then(resp => {
        //         const data = resp.data()
        //         if (data)
        //         localStorage.setItem('dd', JSON.stringify(data))
        //     })
        //     .catch(error => console.error(error))
    }
}