import firebaseConfig from './firebaseIndex.js';
import firebase from 'firebase/app';
import {message} from 'antd';
import sw from '../utils/multilingual.json';
import { fa } from './analytics.js';
import { dbMethods } from './dbmethods.js';


if(firebaseConfig)
    console.log("empowered by firebase");

const language = localStorage.getItem('language') ?? ((window.navigator.userLanguage || window.navigator.language).split('-')[0] === 'es'? 'spanish':'english'); 

export const authMethods = {
    signup: (email, password, phoneNumber, userName, setErrors, setToken)=> {
        console.log(email);
        const auth = firebase.auth();
        auth.createUserWithEmailAndPassword(email, password)
            .then(async res=> {
                const token = await Object.entries(res.user)[5][1].b
                //set token to localStorage 
                await localStorage.setItem('token', token);
                setToken(token);
                //grab token from local storage and set to state. 
                const cu = firebase.auth().currentUser;

                firebase.firestore().collection('user_phones').doc(cu.uid).set({
                    uid: cu.uid,
                    phone: phoneNumber
                })
                .then(res=> {
                    cu.updateProfile({displayName: userName})
                        .then(r=> {
                            firebase.auth().currentUser.sendEmailVerification()
                            .then(res=> {
                                message.success('email verification sent to '.concat(email));
                            })
                            .catch(err=> console.log('could not send email verification'));
                        })
                        .catch(err=> console.log(err));
                });
            })
            .catch(err=> {
                setErrors(prev=> ([...prev, err.message]))
            });
    },
    signin: (email, password, setErrors, setToken, reload)=> {
        const auth = firebase.auth();
        auth.signInWithEmailAndPassword(email,password)
            .then(async res=> {
                if(res.user.emailVerified){
                    const token = await Object.entries(res.user)[5][1].b;
                    //set token to localStorage 
                    await localStorage.setItem('token', token);
                    setToken(window.localStorage.token);
                    fa('login');
                    await dbMethods.isEmployee()
                    console.log('reloading', reload)
                    if (reload) {
                        setTimeout(()=> window.location.reload(), 100);
                    }
                }
                else{
                    message.warn('Please verify your account to login');
                }
                console.log(res)
            })
            .catch(err=> {
                console.log(err);
                setErrors(prev=> ([ ...prev, (sw.authError[err.code.split('/')[1]] && sw.authError[err.code.split('/')[1]][language]) ?? err.message ]));
                fa(err.code.replace('/','_'));
            })
    },
    signinGoogle: (setErrors, setToken, reload)=> {
        const auth = firebase.auth();
        var provider = new firebase.auth.GoogleAuthProvider();
        // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        auth.signInWithPopup(provider)
            .then(async res=> {
                var credential = res.credential;

                if(credential){
                    const token = credential.accessToken;
                    //set token to localStorage 
                    await localStorage.setItem('token', token);
                    setToken(window.localStorage.token);
                    await dbMethods.isEmployee()
                    fa('login');
                    if (reload) {
                        setTimeout(()=> window.location.reload(), 100);
                    }
                }
                // console.log(res)
            })
            .catch(err=> {
                console.log(err);
                const e = err.code && err.code.includes('/') ?
                (sw.authError[err.code.split('/')[1]] && sw.authError[err.code.split('/')[1]][language]) ?? err.message : '';
                setErrors(prev=> ([ ...prev, e ]));
                fa(err.code.replace('/','_'));
            })
    },
    signout: (setErrors, setToken, setInputs)=> {
          // signOut is a no argument function
        firebase.auth().signOut().then( res => {
            //remove the token
            localStorage.removeItem('token');
            localStorage.removeItem('isEmployee')
            //set the token back to original state
            setToken(localStorage.getItem('token'));
            setInputs({email: '', password: ''});
            setErrors([]);
            fa('logout');
        })
        .catch(err => {
            //there shouldn't every be an error from firebase but just in case
            setErrors(prev => ([...prev, err.message]))
            //whether firebase does the trick or not i want my user to do there thing.
            localStorage.removeItem('token')
                setToken(localStorage.getItem('token'))
                console.error(err.message)
        });
    },
    forgotPs: (email)=> {
        firebase.auth().sendPasswordResetEmail(email)
            .then(async res=> {
                console.log('email sent it');
                console.log(res);
                message.success('Email sent');
                fa('forgot_ps');
            })
            .catch(err=> {
                message.warning('invalid email');
            }); 
    }
};