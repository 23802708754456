import React from "react";
import sw from '../../../utils/multilingual.json';
import { List } from "antd";
import { isMobile } from "../../../utils/mobile";
import { useState } from "react";
import { dbMethods } from "../../../firebase/dbmethods";
import {  
    ExclamationCircleFilled
} from '@ant-design/icons';
import confirm from "antd/lib/modal/confirm";
import AddressInfoListItem from "../address-info-list-item/AddressInfoListItem";

const AddressInfoList = (props) => {
    const [pagination] = useState({
        onChange: page => {
          console.log(page);
        },
        pageSize: isMobile? 1:2,
        showTitle:true
      });

    const checkAddress = (val,address) => {
        if(val){
            dbMethods.selectAddress(address.selectedDate, props.addresses, props.setAddresses, props.setSelectedAddress);//.then(()=> {props.setSelectedAddress(address)});
        }
    }

    const removeAddress = key => {
        confirm({
            icon: <ExclamationCircleFilled />,
            content: <label>{sw.confirmDeleteItem[props.language]}</label>,
            onOk() {
              dbMethods.removeAddress(key, props.addresses, props.setAddresses, props.setSelectedAddress,props.language);
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    return (<List
        className='address-info-list'
        grid={{ gutter: 16, 
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1,
            xxl: 1
        }}
        pagination={pagination}
        dataSource={props.addresses}
        renderItem={(item,index) => (
            <AddressInfoListItem
                language={props.language}
                removeAddress={removeAddress}
                checkAddress={checkAddress}
                selectedAddress={props.selectedAddress}
                item={item}
                index={index}
            />)}
    />);
}

export default AddressInfoList;