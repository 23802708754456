import React, {useState, useEffect, useRef} from 'react';
import {dbMethods} from '../../firebase/dbmethods.js'
import { Button, List, Card, Avatar, Space, Tag, Progress, Badge, Tooltip, Skeleton } from 'antd';
import { CartRemoveIcon, BagsIcon, } from '../../components/tlac-icon/TlacIcon.js';
import { SwapOutlined, /*RetweetOutlined*/ } from '@ant-design/icons';
import StoryList from '../../components/recipe/story-list/StoryList.js';
import sw from '../../utils/multilingual.json';
import './home.css';
// import { fa } from '../../firebase/analytics.js';
import { listenOrder } from '../../firebase/livedb.js';
import { getImageURL } from '../../firebase/firestore-images.js';
import RecipeSelector from '../../components/recipe/selector/RecipeSelector.js';
import ConfirmOrder from '../../components/order/confirm-order/ConfirmOrder.js';
import OrderPack from '../../components/order/order-pack/OrderPack.js';

const HomePage = (props)=> {
    const [stories, setStories] = useState(null);
    const [selectorVisible, setSelectorVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [packsVisible, setPacksVisible] = useState(false);
    const [shoppingcart, setShoppingcart] = useState(null);
    const [weekData, setWeekData] = useState([]);
    const [isloading, setLoading] = useState(true);
    const [disableOrder, setDisableOrder] = useState(true);
    const [selectedDay, setSelectedDay] = useState(null);
    const [total, setTotal] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 574);
    const [percent, setPercent] = useState(0);
    const [isSnack, setIsSnack] = useState(false);
    const language = props.language;

    const openSelector = (day, dayIndex, itemIndex, isSnack) => {
        setSelectedDay({name: day, index: dayIndex, itemIndex});
        console.log(isSnack)
        setIsSnack(isSnack)
        setSelectorVisible(true)
    }

    const remove = (indexItem, dayIndex, isSnack) => {
        setShoppingcart(prev => {
            const newPack = {...prev.pack};
            newPack.days[dayIndex] = [...newPack.days[dayIndex]];
            newPack.days[dayIndex][indexItem] = {
                itemkey: null,
                day: dayIndex,
                metadata: null,
                qty: 0,
                type: isSnack ? 2 : 1
            };

            dbMethods.updateShoppingCart(null, null, newPack.isCooked, newPack)
            return {
              ...prev,
              pack: newPack
            };
          });
    }

    const order = () => {
        setConfirmVisible(true);
    }

    const calculateTotals = () => {
        // calculate total
        setTotal(shoppingcart?.pack?.price);
        // calculate percentage of completed package
        const filled = document.querySelectorAll('.week-planning-days-item-card-list-item')?.length ?? 0;
        const empty = document.querySelectorAll('.week-planning-days-item-card-list-empty-item')?.length ?? 0;
        setPercent((100 / (filled + empty)) * filled);
        setDisableOrder(empty > 0);
    }

    const currentRef = useRef();

    useEffect(()=> {
        currentRef.current = {
            language: props.language,
            u: props.u,
            calculateTotals: calculateTotals
        };
        document.getElementsByTagName('main')[0].style.padding = '0px';
    });

    useEffect(()=> {
        document.title = `TLAC | ${sw.home[currentRef.current.language]}`;
        dbMethods.getStories().then(resp=> setStories(resp));
        dbMethods.getShoppingCart(setShoppingcart, setLoading).then(() => {
            // calculate percentage of completed package
            const filled = document.querySelectorAll('.week-planning-days-item-card-list-item')?.length ?? 0;
            const empty = document.querySelectorAll('.week-planning-days-item-card-list-empty-item')?.length ?? 0;
            setPercent((100 / (filled + empty)) * filled);
            setDisableOrder(empty > 0);
        });
        listenOrder()
        window.addEventListener('resize', () => setIsMobile(window.innerWidth <= 574));
    },[]);

    useEffect(() => {
        const deliveryDays = (JSON.parse(localStorage.getItem('dd')) ?? {days: [0,1,3,5]}).days
        const dayOfWeek = (new Date()).getDay();
        const nextEnabledDay = deliveryDays.includes(dayOfWeek) ?
        deliveryDays[0] :
        deliveryDays.find(day => day > dayOfWeek) || deliveryDays[0];
        let daysUntilNextWeek = (7 - dayOfWeek + nextEnabledDay) % 7;
        if (daysUntilNextWeek < ((deliveryDays?.minGap ?? 3) + 1)) daysUntilNextWeek = deliveryDays?.minGap ?? 3;
        const week = (Array.from({length: 7}).reduce((days, _, index) => {
            const currentDay = new Date();
            currentDay.setDate(currentDay.getDate() + daysUntilNextWeek + index);
            const isDisabled = !deliveryDays.includes(currentDay.getDay());
            if (!isDisabled) 
                days.push({name: sw.weekdays[currentDay.getDay()][currentRef.current.language], isDisabled, currentDay, index: currentDay.getDay()});
            return days
        }, []))
        
        const newWeekData = week.map((day, i) => {
            return ({
            day: (<Space direction='horizontal' style={{display: 'flex', justifyContent: 'space-between'}}>
                    <strong>{day.name} {day?.currentDay.getDate()}</strong> 
                    {/* <Button shape='circle' onClick={() => console.log('shuffle!')}> <RetweetOutlined /> </Button> */}
                </Space>),
            data: shoppingcart?.pack?.days[day.index] ?? [],
            index: day.index,
            dayName: day.name
        })});

        setWeekData(newWeekData)
        currentRef.current.calculateTotals();
    }, [shoppingcart]);

    return (
        <div className='home-page' style={{height: 'calc(100vh - 100px)'}}>
            {/* Here start story list */}
            <StoryList stories={stories} language={language} />

            {/* Here start week planning */}
            <Skeleton loading={isloading} active={isloading}>
            {
                shoppingcart?.pack && 
                <Card 
                    type='inner' 
                    style={{margin: '0 2vw'}}
                    title={<Tooltip title={sw.selectPack[language]}><Button shape='circle' size='large' type='ghost' onClick={() => setPacksVisible(true)} style={{height: 'fit-content',}} icon={<BagsIcon size={24} />}></Button></Tooltip>}
                    className='week-planning'
                    onLoad={calculateTotals}
                    extra={(
                    <Button 
                        className='week-planning-confirm' 
                        type='primary' 
                        size='large' 
                        shape='round' 
                        style={{display: 'flex', flexDirection: 'column', height: 55, alignItems: 'center', paddingTop: 0}}
                        onClick={order}
                        disabled={disableOrder}
                        >
                        {sw.Ordenar[language]}
                        <Tag color="success" style={{minWidth: 100, borderRadius: 5, fontSize: 18, marginLeft: 5, display: 'flex', flexDirection: 'column', position: 'relative'}}>
                            <span>${total}<span style={{fontSize: 10}}>MXN</span></span>
                        </Tag>
                    </Button>)}
                >
                    <Badge.Ribbon text={shoppingcart?.pack?.name.concat(shoppingcart?.pack?.isCooked ? '' : ` (${sw.ingredients[language]})`)} color={shoppingcart?.pack?.isCooked ? '#8d3251': '#52c41a'} placement='start' style={{transform: 'translate(-5px, -25px)', width: 160}} >
                    <Progress 
                        className='shopping-cart-footer-info__total-progress-bar' 
                        showInfo={false} 
                        percent={percent} 
                        status='active' 
                        size='small'
                        style={{position: 'absolute', top: 0, left: 150, transform: 'translateY(-20px)', width: 'calc(100% - 150px)'}}
                        strokeColor={{'0%': '#92b851', '50%': '#7c9c46', '100%': '#8d3251'}} 
                    />
                    <List 
                        className='week-planning-days'
                        grid={{ gutter: 1, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
                        dataSource={weekData}
                        pagination={{
                            pageSize: isMobile ? 1 : 4,
                            simple: true,
                            position: 'top',
                            hideOnSinglePage: true
                        }}
                        renderItem={(item) => (
                            <List.Item className='week-planning-days-item'>
                            <Card title={item.day} className='week-planning-days-item-card'>
                                <List 
                                    className='week-planning-days-item-card-list'
                                    dataSource={shoppingcart?.pack?.days[item.index] ?? []}
                                    loading={isloading}
                                    renderItem={(dayItem, i) => dayItem?.itemkey ? 
                                        // If item
                                        (<List.Item key={`item-${i}`} className='week-planning-days-item-card-list-item' style={{padding: '2px 0'}}>
                                            <List.Item.Meta
                                                avatar={<Avatar size={50} src={getImageURL(dayItem.itemkey,'jpg','recipe-pic-small')} alt='dish-preview' />}
                                                title={<strong>{dayItem?.metadata?.name[language] ?? dayItem.itemkey}</strong>}
                                                description={(
                                                <div style={{display: 'flex', justifyContent: 'flex-end', gap: 10}}>
                                                    <Button danger ghost shape='circle' onClick={() => {remove(i, item.index, dayItem.type === 2)}}><CartRemoveIcon size={18} /></Button>
                                                    <Button shape='circle' onClick={() => openSelector(item.dayName, item.index, i, dayItem.type === 2)}><SwapOutlined size={18} /></Button>
                                                </div>
                                                )}
                                            />
                                        </List.Item>
                                        ) : 
                                        // If no item
                                        <List.Item key={`empty-${i}`}>
                                            <Card 
                                                hoverable 
                                                className={`week-planning-days-item-card-list-empty-item ${dayItem.type === 2 ? 'snack' : 'default'}`}
                                                onClick={() => openSelector(item.dayName, item.index, i, dayItem.type === 2)}
                                            >
                                                +    
                                            </Card>
                                        </List.Item>
                                    }
                                />
                            </Card>
                            </List.Item>
                        )}
                    />
                    </Badge.Ribbon>
                </Card>
            }
            </Skeleton>
            <RecipeSelector 
                visible={selectorVisible} 
                language={language} 
                day={selectedDay} 
                setVisible={setSelectorVisible} 
                setShoppingCart={setShoppingcart}
                isSnack={isSnack}
            />

            <ConfirmOrder 
                visible={confirmVisible} 
                language={language} 
                setVisible={setConfirmVisible}
                shoppingCart={shoppingcart}
            />
            <Skeleton loading={isloading} active={isloading}>
                <OrderPack 
                    language={language}
                    visible={packsVisible}
                    setVisible={setPacksVisible}
                    setShoppingCart={setShoppingcart} 
                    onlyContent={shoppingcart?.pack ? false : true}
                />
            </Skeleton>
        </div>
    )
};

export default HomePage;