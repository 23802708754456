import firebaseConfig from './firebaseIndex.js';
import firebase from 'firebase/app';


if(firebaseConfig)
    console.log("Running firebase livedb");

const db = firebase.database();


export const listenOrder = () => {
    firebase.auth().onAuthStateChanged(async user=> {
        if (user?.uid)
            db.ref('pendingOrder').child(user.uid).on('value', (snap) => {
                const value = snap.val();
                if (value) {
                    db.ref('pendingOrder').child(user.uid).remove()
                    .then(() => document.location = value);
                }
            }, err => console.log(err));
        else {
            localStorage.clear();
            window.location = '/';
        }
    });
} 

export const deletePaymentLink = () => {
    firebase.auth().onAuthStateChanged(async user=> {
        db.ref('pendingOrder').child(user.uid).remove();
    });
}