import React from 'react';
import moment from 'moment'
import { Button, Row, Col, Avatar, Calendar,  } from 'antd';
const LunchHours = (props)=> {
    
    const validRange = [
        moment().subtract(1,'day'),
        moment().endOf('week').add(1,'week')
    ];

    const listData = [
        { type: 'warning', hour:'07:00', content: 'Breakfast', img:'https://firebasestorage.googleapis.com/v0/b/box2020-d5d80.appspot.com/o/recipes-pic%2F4T6qQUYoXgwhIIrJwZQo.jpg?alt=media&token=1be0aece-bd71-4f33-889c-9ca1a0ba6f60' },
        { type: 'success', hour:'10:00', content: 'Snack', img:'https://firebasestorage.googleapis.com/v0/b/box2020-d5d80.appspot.com/o/recipes-pic%2FEz9eHOuXizLmzG1DarTr.jpg?alt=media&token=e9086864-e221-4f19-87ce-6b3d97ba0f07' },
        { type: 'error', hour:'14:00', content: 'Food', img:'https://firebasestorage.googleapis.com/v0/b/box2020-d5d80.appspot.com/o/recipes-pic%2FrREPr1SrU8KyvYxhlz1l.jpg?alt=media&token=e5a8a4d7-4b5d-48c5-954b-051fd7cf086d' },
        { type: 'error', hour:'16:00', content: 'Lunch', img:'https://firebasestorage.googleapis.com/v0/b/box2020-d5d80.appspot.com/o/recipes-pic%2FEz9eHOuXizLmzG1DarTr.jpg?alt=media&token=e9086864-e221-4f19-87ce-6b3d97ba0f07' },
        { type: 'error', hour:'18:00', content: 'Dinner', img:'https://firebasestorage.googleapis.com/v0/b/box2020-d5d80.appspot.com/o/recipes-pic%2FEz9eHOuXizLmzG1DarTr.jpg?alt=media&token=e9086864-e221-4f19-87ce-6b3d97ba0f07' }
    ];

    return (
        <div>

            <Calendar 
                        fullscreen={true}
                        defaultValue={moment()}
                        validRange={validRange}
                        // onSelect={m=> selectedDate.day = m}
                        headerRender={({ value, type, onChange, onTypeChange }) => {
                        return (<Row>
                            <Col span={10}></Col>
                            <Col span={9}><label className="label text-large">{value.format('YYYY-MM-DD')}</label></Col>
                            <Col span={3}></Col>
                            <Col span={2}>
                                {/* <Button type="primary" > + </Button> */}
                            </Col>
                            </Row>);
                        }}
                        dateCellRender={date=> {

                            if(date.isAfter(validRange[1]))
                                return <div style={{background:'lightgray'}}> not available in this moment</div>
                            if(date.isBefore(moment().subtract(1,'day')))
                                return <div style={{background:'lightgray'}}> has passed</div>
                            return <ul className="events" style={{paddingLeft:0}}>
                            {listData.map(item => (
                              <li key={item.content} style={{listStyle:"none"}}>
                                  <Button type="link" size="small"><Avatar src={item.img} size="small" /></Button>
                                {/* <Badge status={item.type} text={item.content} /> */}
                              </li>
                            ))}
                          </ul>
                        }}
                    />
        </div>
    )
};

export default LunchHours;