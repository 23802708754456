import React from 'react';
import '../../pages/legal-page/legal-page.css';
import { useEffect } from 'react';
import './legal-page.css';

const PrivacyPage = (props) => {

    const language = window.location.pathname.includes('/en') ? 'english' :
                window.location.pathname.includes('/es') ? 'spanish' :
                (window.navigator.userLanguage || window.navigator.language).split('-')[0] === 'es' ? 'spanish' :
                'english';

    useEffect(() => {
        document.title = `TLAC | Privacy Policy`;
    }, []);

    return (<div className='legal-page'>
        <a href='/'>
            <img alt='tlac logo' src={require('../../pics/LOGO-01.png').default} height={100} width={100} />
        </a>

        {language === "english" ? <div>
             <h2>Privacy Policy for Tlac</h2>

             <p>Effective Date: {new Date('Fri Jan 12 2024 22:14:41 GMT-0800 (hora estándar del Pacífico)').toDateString()}</p>

             <h2>1. Introduction</h2>

    <p>Welcome to Tlac! This Privacy Policy outlines how we collect, use, and safeguard the personal information you provide while using our application.</p>

    <h2>2. Information We Collect</h2>

    <p>We collect the following types of information:</p>
    <ul>
        <li>Personal Information: We collect the user's email, name, ID, and profile picture for the sole purpose of authenticating the user. No sensitive data is used for internal data collection.</li>
        <li>Usage Data: Internally, we collect platform events to enhance user experience and for continuous improvement. No sensitive user data is used for this purpose.</li>
    </ul>

    <h2>3. How We Use Your Information</h2>

    <p>We use the collected information for the following purposes:</p>
    <ul>
        <li>To authenticate users using their email, name, ID, and profile picture through Firebase's signInWithPopup function.</li>
        <li>Internal data collection: We collect platform events to analyze user interactions for continuous platform improvement. No sensitive user data is used for this purpose.</li>
    </ul>

    <h2>4. Sharing Your Information</h2>

    <p>We do not sell, trade, or otherwise transfer your personal information to third parties. Information is only shared with trusted third-party service providers to assist us in delivering our services.</p>

    <h2>5. Security</h2>

    <p>We prioritize the security of your personal information and employ industry-standard measures to protect it.</p>

    <h2>6. Your Choices</h2>

    <p>At Tlac, we respect your privacy and provide you with the following choices:</p>

    <ol>
        <li><strong>Data Access:</strong> You may request access to the personal information we hold about you. For inquiries regarding your data, please contact us at <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a>.</li>
        
        <li><strong>Data Deletion:</strong> If you wish to delete your account or certain personal information, you can do so through your account settings. Additionally, you have the option to contact us at <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a> to request the deletion of your account and associated data. Please note that certain data may be retained for legal or legitimate business purposes even after account deletion.</li>
    </ol>

    <p>External communications are limited to essential delivery alerts via email as part of our service. Users may receive notifications related to their orders, but we do not engage in promotional emails or advertisements outside the platform. For any concerns regarding external communication preferences, please contact us at <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a>.</p>


    <h2>7. Gmail Authentication</h2>

    <p>Please note that Tlac uses Gmail authentication for user login. When you choose to authenticate via email/password, you are utilizing Gmail services for the authentication process.</p>

    <h2>8. Changes to This Privacy Policy</h2>

    <p>This Privacy Policy may be updated periodically. Please review this page for any changes.</p>

    <h2>9. Contact Us</h2>

    <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a>.</p>
</div>:
            <div>
            <h2>Política de Privacidad para Tlac</h2>

<p>Fecha de efectividad: {new Date('Fri Jan 12 2024 22:14:41 GMT-0800 (hora estándar del Pacífico)').toLocaleDateString('es-ES')}</p>

<h2>1. Introducción</h2>

<p>¡Bienvenido a Tlac! Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos la información personal que proporciona al usar nuestra aplicación.</p>

<h2>2. Información que Recopilamos</h2>

<p>Recopilamos los siguientes tipos de información:</p>
<ul>
    <li>Información Personal: Recopilamos el correo electrónico, nombre, ID y foto de perfil del usuario con el único propósito de autenticar al usuario. No se utiliza ningún dato sensible para la recopilación interna de datos.</li>
    <li>Datos de Uso: Internamente, recopilamos eventos de la plataforma para mejorar la experiencia del usuario y para la mejora continua. No se utiliza ningún dato sensible del usuario para este propósito.</li>
</ul>

<h2>3. Cómo Usamos Su Información</h2>

<p>Utilizamos la información recopilada para los siguientes propósitos:</p>
<ul>
    <li>Autenticar a los usuarios mediante su correo electrónico, nombre, ID y foto de perfil a través de la función signInWithPopup de Firebase.</li>
    <li>Recopilación interna de datos: Recopilamos eventos de la plataforma para analizar las interacciones del usuario y mejorar continuamente la plataforma. No se utiliza ningún dato sensible del usuario para este propósito.</li>
</ul>

<h2>4. Compartir Su Información</h2>

<p>No vendemos, intercambiamos ni transferimos su información personal a terceros. La información solo se comparte con proveedores de servicios de confianza para ayudarnos a proporcionar nuestros servicios.</p>

<h2>5. Seguridad</h2>

<p>Damos prioridad a la seguridad de su información personal y empleamos medidas estándar de la industria para protegerla.</p>

<h2>6. Sus Opciones</h2>

<p>En Tlac, respetamos su privacidad y le ofrecemos las siguientes opciones:</p>

<ol>
    <li><strong>Acceso a Datos:</strong> Puede solicitar acceso a la información personal que tenemos sobre usted. Para consultas sobre sus datos, contáctenos en <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a>.</li>
    
    <li><strong>Eliminación de Datos:</strong> Si desea eliminar su cuenta o cierta información personal, puede hacerlo a través de la configuración de su cuenta. Además, tiene la opción de contactarnos en <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a> para solicitar la eliminación de su cuenta y los datos asociados. Tenga en cuenta que ciertos datos pueden retenerse por motivos legales o legítimos incluso después de la eliminación de la cuenta.</li>
</ol>

<p>Las comunicaciones externas se limitan a alertas esenciales de entrega por correo electrónico como parte de nuestro servicio. Los usuarios pueden recibir notificaciones relacionadas con sus pedidos, pero no participamos en correos electrónicos promocionales o publicidad fuera de la plataforma. Para cualquier inquietud sobre las preferencias de comunicación externa, contáctenos en <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a>.</p>

<h2>7. Autenticación mediante Gmail</h2>

<p>Tenga en cuenta que Tlac utiliza la autenticación de Gmail para el inicio de sesión de usuario. Al optar por autenticarse mediante correo electrónico/contraseña, está utilizando los servicios de Gmail para el proceso de autenticación.</p>

<h2>8. Cambios en Esta Política de Privacidad</h2>

<p>Esta Política de Privacidad puede actualizarse periódicamente. Por favor, revise esta página para cualquier cambio.</p>

<h2>9. Contáctenos</h2>

<p>Si tiene alguna pregunta o inquietud con respecto a esta Política de Privacidad, contáctenos en <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a>.</p>

        </div>
        }
    </div>)
};

export default PrivacyPage;