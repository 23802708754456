import React,{useState} from 'react';
import {authMethods} from '../firebase/authmethods.js'
import firebase from 'firebase/app';
export const firebaseAuth = React.createContext()

const AuthProvider = (props) => {
  const [inputs, setInputs] = useState({email: '', password: '', userName:'', phoneNumber:''});
  const [errors, setErrors] = useState([]);
  const [token, setToken] = useState(localStorage.getItem('token'));

  const handleSignup = ()=> {
    // middle man between firebase and signup 
    console.log('handleSignup')
    // calling signup from firebase server
    authMethods.signup(inputs.email, inputs.password, inputs.phoneNumber, inputs.userName, setErrors, setToken);
    console.log(errors, token); 
  };

  const handleSignin = (reload)=> {
    console.log('handleSignin');
    authMethods.signin(inputs.email, inputs.password, setErrors, setToken, reload);
    // console.log(errors, token);
  };

  const handleSigninGoogle = (reload)=> {
    console.log('handleSignin google');
    authMethods.signinGoogle(setErrors, setToken, reload);
    // console.log(errors, token);
  };

  const handleSignout = () => {
    authMethods.signout(setErrors, setToken, setInputs);
  }

  const handleForgotPs = ()=> {
    console.log('forgot password');
    authMethods.forgotPs(inputs.email)
  }

  const handleGetUserData = async setUserData=> {
    firebase.auth().onAuthStateChanged(async user=> {
      if(user)
        setUserData({
          displayName:user?.displayName || user.email,
          picture: user?.photoURL || require('../pics/logo-simple.png').default
        });
    });
  }


  return (
    <firebaseAuth.Provider
        value={{
            handleSignup,
            handleSignin,
            handleSigninGoogle,
            handleSignout,
            handleForgotPs,
            handleGetUserData,
            inputs,
            setInputs,
            errors,
            token
        }}>
        {props.children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;
