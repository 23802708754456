import React from 'react';

const Story = props => {
    
    return (
    <div className="story-frame" direction="vertical" key={props.title[props.language]} data-position={props.index}>
        <img src={props.img} className="story-frame-img" onClick={e => props.showStories(e.currentTarget, props.index)} alt={props.title[props.language]}/>
        {/* <span className="story-frame-caption">{props.title[props.language]}</span> */}
    </div>
    );
};

export default Story;