import React, { useState } from 'react';
import Story from '../story/Story';
import Stories from 'react-insta-stories';

const StoryList = ({stories, language}) => {
    const [index, setIndex] = useState(0);
    const [isFullscreen, setIsFullscreen]= useState(false);

    const showStories = (e, index) => {
        setIndex(index);
        setIsFullscreen(true);
    };

    const closeFullscreen = e => {
        const storiesContainer = e.parentElement;
        storiesContainer.classList.remove('fullscreen');
        setIsFullscreen(false);
    }

    const CloseFullscreen = () => {
        if (stories)
            return (<span onClick={e => closeFullscreen(e.currentTarget)} className='stories-list-close'>X</span>);
        return null;
    }

    return <div className={'stories-list '.concat(isFullscreen ? 'fullscreen': '')}>
        {
            stories?.map((story, index)=> 
                <Story
                    title={story.title}
                    img={story.img}
                    language={language}
                    key={story.title[language]}
                    showStories={showStories}
                    index={index}
                />
            ) ?? []
        }
        {stories && isFullscreen &&
            <Stories 
                stories={stories.map(s => s.img)}
                defaultInterval={3000}
                width={390}
                height={768}
                storyContainerStyles={{marginLeft: 'auto', marginRight: 'auto'}}
                currentIndex={index}
                onAllStoriesEnd={() => setIsFullscreen(false)}
            />
        }
        <CloseFullscreen />
    </div>
};

export default StoryList;