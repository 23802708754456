import request from 'request';
import { dbMethods } from '../../firebase/dbmethods';
import { deletePaymentLink } from '../../firebase/livedb';

export const pay = (newOrder, lang) => {
  // const form = {};
  // for (let i = 0; i < newOrder.recipes.length; i++) {
  //     form[`line_items[${i}][price]`] = newOrder.recipes[i].price_id;  
  //     form[`line_items[${i}][quantity]`] = newOrder.recipes[i].groupedQuantity;  
  // }

  // form['after_completion[type]'] = 'redirect';
  // form['after_completion[redirect][url]'] = 'https://tlaconline.web.app/finish-order-page/';

  // request({
  //   'method': 'POST',
  //   'url': 'https://api.stripe.com/v1/payment_links',
  //   'headers': {
  //     'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_API_KEY}`,
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   },
  //   form: form
  // }, function (error, response) {
  //   if (error) console.error(error.message);
  //   const body = JSON.parse(response.body);
  //   const url = body?.url ?? '/';
    
  //   newOrder['paymentlink'] = body?.id;
  //   dbMethods.createOrder(newOrder, setCartOpened, lang, url);
  // });    
  dbMethods.createOrder(newOrder, lang);
}

// const createPaymentLink = (newOrder, key) => {
//   const form = {};
//   for (let i = 0; i < newOrder.recipes.length; i++) {
//       form[`line_items[${i}][price]`] = newOrder.recipes[i].price_id;  
//       form[`line_items[${i}][quantity]`] = newOrder.recipes[i].groupedQuantity;  
//   }

//   form['after_completion[type]'] = 'redirect';
//   form['after_completion[redirect][url]'] = `https://tlaconline.web.app/finish-order-page/${key}`;

//   request({
//     'method': 'POST',
//     'url': 'https://api.stripe.com/v1/payment_links',
//     'headers': {
//       'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_API_KEY}`,
//       'Content-Type': 'application/x-www-form-urlencoded'
//     },
//     form: form
//   }, function (error, response) {
//     if (error) console.error(error.message);
//     const body = JSON.parse(response.body);
//     const url = body?.url ?? '/';
//     if (body?.id && key && url)
//       dbMethods.addOrderPaymentLink(body?.id, key, url);
//   });
// }

export const finishOrder = (key) => {
    dbMethods.finishOrder(deactivatePaymentLink, key);
    deletePaymentLink();
}


const deactivatePaymentLink = (paymentLink) => {
  console.log(paymentLink);
  const form = {
    "active": false
  };
  request({
    'method': 'POST',
    'url': `https://api.stripe.com/v1/payment_links/${paymentLink}`,
    'headers': {
      'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_API_KEY}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    form: form
  }, function (error, response) {
    if (error) console.error(error.message);
    document.location = `https://app.tlac.com.mx/orders`;
  });
}