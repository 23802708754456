import React, {useState, useEffect, useRef} from 'react';
import { Button, Image } from 'antd';
import supportedDiets from '../../../utils/supported-diets.json';
import { ClockIcon, DietIcon, DificultyIcon, LikeIcon, UserIcon } from '../../tlac-icon/TlacIcon.js';
import { getImageURL } from '../../../firebase/firestore-images.js';
import { dbMethods } from '../../../firebase/dbmethods';


const RecipeCard = (props)=> {

    const [dietItems,setDietItems] = useState([]);

    const fixed = useRef();

    useEffect(()=> {
        fixed.current = props.recipe
    });

    useEffect(()=> {
        setDietItems(supportedDiets.filter(f=> f.index in fixed.current && fixed.current[f.index])
        .map(item=> <DietIcon key={item.index} type={item.index} size={25} class="diet-icon" /> )
        );
    },[]);

    const like = e => {
        e.disabled = true;
        dbMethods.likeRecipe(props.recipe.key,props.isLiked, props.setLiked, props.setRecipe, e);
    }

    return (
        <div className="recipe-card">
            <Image
            className="recipe-card-img"
            src={getImageURL(props.recipe.key,'jpg','recipes-pic')}
            />
            <div className="recipe-card-header">
                    <div className='recipe-card-header__row1'>
                        <div className="recipe-card-header__row1__diets">
                            {dietItems}
                        </div>
                        <Button 
                            className={`recipe-card-header__row1__likes ${props.isLiked && 'liked'}`}
                            onClick={e => like(e.currentTarget)}
                        >
                            <label className="recipe-card-header__row1__likes-number"> {props.recipe.likes || 0}</label>
                            <LikeIcon dark={props.isLiked} />
                        </Button>
                    </div>
                    <div className='recipe-card-header__row2'>
                        <div className='recipe-card-header__row2__recipe-metadata'>
                            <ClockIcon />
                            <span>{props.recipe.time > 60 ? (props.recipe.time / 60).toString().concat('hrs') : props.recipe.time + 'min'}</span>
                        </div>
                        <div className='recipe-card-header__row2__recipe-metadata'>
                            <UserIcon />
                            <span>{props.recipe.porcion}</span>
                        </div>
                        <div className='recipe-card-header__row2__recipe-metadata'>
                            <DificultyIcon type={props.recipe?.dificulty?.english?.toLowerCase() ?? 'easy'} />
                            <span>{props.recipe.dificulty[props.language]}</span>
                        </div>
                    </div>
            </div>
            <div className='recipe-card-footer'>
                <div className='recipe-card-footer__actions'>
                    {/* <div className='recipe-card-footer__actions__price-selector'>
                        <Button 
                            className={'recipe-card-footer__actions__price-selector__btn '.concat(!props.readyToEat && 'selected')}
                            onClick={e=> {props.setReadyToEat(false);}}
                        >
                            <IngredientsIcon size={25} />
                            {' $'.concat(Number(props.recipe.price).toFixed(2))}
                        </Button>
                        <Button 
                            className={'recipe-card-footer__actions__price-selector__btn '.concat(props.readyToEat && 'selected')}
                            onClick={e=> {props.setReadyToEat(true)}}
                        >
                            <CookedIcon size={25} />
                            {' $'.concat(Number(props.recipe.price + 20).toFixed(2))}
                        </Button>
                    </div> */}
                </div>
            </div>
        </div>
        
    )
};

export default RecipeCard;