import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  InfoWindow,
  StreetViewPanorama
} from "react-google-maps";
import { Button  } from 'antd';
// import sw from '../utils/multilingual.json';
import MapPinIconPath from '../components/tlac-icon/svg/map-pin-small.svg';
import { useState } from "react";
import { useEffect } from "react";
import './map.css';
import { circleOptions, circleOptionsEmpty } from "./map.js";
import { useRef } from "react";
import { StreetViewIcon } from "../components/tlac-icon/TlacIcon";

const GMap = compose(
  withProps({
    googleMapURL:
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{height: '100%'}}>Loading ...</div>,
    containerElement: <div className="map-container" style={{height: '100%'}}>Loading ...</div>,
    mapElement: <div id="map" style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
    const [position, setPosition] = useState({lat:0,lng:0,zoom:13});
    const [streetVisible, setStreetVisible] = useState(false);
    // const [addressText, setAddressText] = useState(null);

    // const updateLocation = (element, e, isAddressAvailable) => {
    //     if(getDistance(element, e.latLng) <= element.radius) {
    //         isAddressAvailable = true;
    //         console.log(e.latLng.lat(),e.latLng.lng());
    //         if (props.setPosition)
    //             props.setPosition(prev => ({
    //                 ...prev,
    //                 lat: e.latLng.lat(),
    //                 lng: e.latLng.lng(), 
    //                 zone: element})
    //             );
    //         setPosition(prev => ({
    //             ...prev,
    //             lat: e.latLng.lat(),
    //             lng: e.latLng.lng()
    //             })
    //         );
    //         setStreetVisible(true);
    //         setStreetVisible(false);
    //         // setTimeout(() => {
    //         //     setAddressText(getAddressText());
    //         //     if (props.onUpdateAddress)
    //         //         props.onUpdateAddress(getAddressText());
    //         // }, 500);
    //     }
    //     return isAddressAvailable;
    // }

    // const getAddressText = () => document.querySelector('.gm-iv-address-description')?.textContent;
    const ref = useRef();

    useEffect(() => {
        ref.current = {
            language: props.language
        };
    });


    useEffect(() => {
        setPosition(props.deliverAddress);
        // setAddressText(sw.dragMeToYourAddress[ref.current.language]);
    },[props.deliverAddress]);

    return (
    <GoogleMap
        zoom={props.deliverAddress?.zoom ?? 13} 
        defaultCenter={{ lat: 0, lng: 0 }}
        center={ props.deliverAddress }
        >
    <Marker 
        draggable={props.isDeliverAddressDraggable}
        visible={Boolean(props.addressName)}
        // onDragEnd={e=> {
        //     if(props.isDeliverAddressDraggable){
        //         let isAddressAvailable = false;
        //         props.circles.forEach(element => {
        //             isAddressAvailable = updateLocation(element, e, isAddressAvailable);
        //         });
        //         if(!isAddressAvailable) {
        //             props.setPosition(prev=> ({
        //                 ...prev,
        //                 lat: props.deliverAddress.lat,
        //                 lng: props.deliverAddress.lng})
        //             );
        //             setPosition(prev=> ({
        //                 ...prev,
        //                 lat: props.deliverAddress.lat,
        //                 lng: props.deliverAddress.lng,
        //                 zone: props.deliverAddress})
        //             );
        //             message.warning(sw.AddressNotAvailable[props.language]);
        //         }
        //     } 
        //     }}
        
        position={position} 
        icon={MapPinIconPath}
    >
        {props.isDeliverAddressDraggable && <InfoWindow>
            <span className="info-message">
            {/* {addressText ?? sw.dragMeToYourAddress[props.language]} */}
            </span>
        </InfoWindow>}
    </Marker>
    {props.circles && props.circles.map(item=> 
        <Circle 
            key={item.key}
            onClick={e=> {
                if(props.onClick)
                    props.onClick(e)
            }} 
            options={item.key.includes('Tijuana') || item.key.includes('Rosarito') ? circleOptions : circleOptionsEmpty} 
            center={{lat:item.lat,lng:item.lng}} 
            radius={item.radius}
        />)
    }

    <StreetViewPanorama 
        position={{lat: position?.lat ?? 0, lng: position?.lng ?? 0}}
        visible={streetVisible}
        onCloseClick={() => setStreetVisible(false)}
    />
    <Button disabled={!props.addressName} shape='round' className="street-btn" onClick={() => setStreetVisible(true)}>
        <StreetViewIcon size={30} />
    </Button>
    </GoogleMap>
)});

export default GMap;