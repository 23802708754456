import { message } from "antd";
import sw from '../utils/multilingual.json'

const lang = localStorage.getItem('language') ?? ((window.navigator.userLanguage || window.navigator.language).split('-')[0] === 'es'? 'spanish':'english');

export const circleOptions = {
  fillColor:'#8d325160',
  // strokeColor:'#8d3251',
  strokeWeight:0
};

export const circleOptionsEmpty = {
  fillColor:'#00000000',
  // strokeColor:'#8d3251',
  strokeWeight:0
};

export const rad = function(x) {
    return x * Math.PI / 180;
  };
  
export const getDistance = function(p1, p2) {
var R = 6378137; // Earth’s mean radius in meter
var dLat = rad(p2.lat - p1.lat);
var dLong = rad(p2.lng - p1.lng);

let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
var d = R * c;
return d; // returns the distance in meter
};

export const searchAddress = (value, setAddressName, setDeliverAddress, setSearching, zones) => {
  if (setSearching) setSearching(true);
  if (!value) {
    if (setSearching) setSearching(false);
    return;
  }
  const geocoder = new window.google.maps.Geocoder();
  geocoder.geocode({address: value}, (result, status) => {
      if (status === 'OK') {
          const address = result[0]?.formatted_address;
          setAddressName(address);
          const latlng = {
            lat: result[0].geometry.location.lat(),
            lng: result[0].geometry.location.lng()
          };
          let cZone = null;
          zones.forEach(zone => {
            if(getDistance(zone, latlng) <= zone.radius) {
              cZone = zone; 
            }
          })
          // validate in case zones are not loading..
          const allTj = {
            "lat": 32.474986,
            "radius": 13000,
            "lng": -116.982724,
            "hours": "7-23",
            "key": "Todo Tijuana"
          };

          if (!cZone && getDistance(allTj, latlng) <= allTj.radius)
              cZone = allTj;
          
          if (!cZone) {
            setAddressName('');
            setDeliverAddress(null)
            message.error(sw.AddressNotAvailable[lang], 5);
            if (setSearching) setSearching(false);
            return;
          }
          setDeliverAddress({lat: latlng.lat, lng: latlng.lng, zone: cZone });
      } else if (status === 'ZERO_RESULTS') {
        setAddressName('');
        message.error(sw.AddressNotFound[lang], 5);
        if (setSearching) setSearching(false);
        return;
      }
      if (setSearching) setSearching(false);
  });
}

export const getAddressByLocation = (latLng) => {
  const address = new window.google.maps.LatLng(latLng.lat, latLng.lng);
  console.log(address);

}

export const loadCurrentLocation = (setAddressName, setDeliverAddress, setSearching, zones) => {
  navigator.geolocation.getCurrentPosition((position) => {
    console.log(position)
    searchAddress(`${position.coords.latitude}, ${position.coords.longitude}`, setAddressName, setDeliverAddress, setSearching, zones);
  });
}