import React from 'react';
import { Breadcrumb } from 'antd';
import sw from '../../utils/multilingual.json'; 
import { WalletIcon } from '../../components/tlac-icon/TlacIcon';
import './payment-page.css';
import { useRef } from 'react';
import { useEffect } from 'react';

const Payment = (props)=> {
    
    const ref = useRef();

    useEffect(() => {
        ref.current = {
            language: props.language
        };
    });

    useEffect(() => {
        document.title = `TLAC | ${sw.payment[ref.current.language]}`;
    }, []);

    return (
        <div className='payment-page'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item href='/'>TLAC</Breadcrumb.Item>
                <Breadcrumb.Item><WalletIcon size={20} /> {sw.payment[props.language]}</Breadcrumb.Item>
            </Breadcrumb>
        </div>
    )
};

export default Payment;