import React from 'react';
import sw from '../../utils/multilingual.json';


const NotFoundRecipe = (props)=> {
    const language = localStorage.getItem('language') ?? ((window.navigator.userLanguage || window.navigator.language).split('-')[0] === 'es'? 'spanish':'english');
    return (<div style={{height:500,padding:'24px'}} className="text-center">
        <svg id="Chef" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
            <g>
                <path className="cls-6" d="M9.96,19.36l-.37,.37,.33,.29,.63,.51c.5,.43,.93,.82,1.27,1.13,.2-.13,.41-.26,.61-.39l-2.48-1.9Z"/>
                <path className="cls-5" d="M13.84,6.4c.54-.27,1.27-.56,2.15-.76,1.15-.25,2.14-.25,2.85-.19v-.43c.06-.04,.9-.68,1.2-1.22,.06-.11,.16-.29,.16-.54,0-.14-.04-.25-.06-.32-.03-.07-.07-.14-.12-.21-.21-.3-.53-.41-.67-.45l-.79,.66,.44-.89-1.52-.63-.51,.76,.13-.89s-1.08-.19-2.09,.13-1.14,.63-1.14,.63l.51,.63s-.57-.19-.82-.44l-1.14,1.08,.7,.63s-.38,.06-.95-.32c-.07,.06-.38,.31-.44,.76-.01,.09-.05,.41,.13,.7,.19,.31,.52,.42,.76,.51,.13,.05,.2,.05,.63,.19,.16,.05,.27,.09,.38,.19,.11,.11,.16,.23,.19,.32l.03,.09Z"/>
                <path className="cls-5" d="M18.81,5.68c-.67-.03-1.54,0-2.53,.19-.98,.19-1.79,.49-2.4,.76,0,0,.06,.06,.13,.51s.06,.82,.06,.82c.38-.27,.93-.59,1.64-.82,.93-.31,1.75-.34,2.28-.32,.4,.02,.8,.04,1.2,.06-.04-.11-.08-.21-.13-.32l-.19-.57h0c-.02-.11-.04-.21-.06-.32Z"/>
                <path className="cls-5" d="M14.19,8.27c.73-.49,1.39-.71,1.83-.82,.7-.18,1.3-.18,1.9-.19,.54,0,1,.03,1.33,.06,.12,.2,.29,.52,.38,.95,.05,.24,.17,.88-.13,1.58-.26,.63-.71,.97-.95,1.14-.17,.12-.53,.38-1.08,.51-.14,.03-1.23,.26-2.21-.44-.18-.13-.56-.44-.82-.95-.28-.55-.3-1.09-.32-1.39,0-.13,0-.24,0-.32v-.09s.06-.04,.06-.04Z"/>
                <path className="cls-5" d="M14.32,14.51c-.1,.53-.19,1.1-.25,1.71-.11,1.01-.14,1.94-.13,2.78,0,0,.13,2.21,.06,2.72s-.38,.96-.38,.96v9.73c.1,.76,.75,1.33,1.52,1.33s1.42-.57,1.52-1.33v-11.39h.57v11.39c.14,.82,.87,1.39,1.64,1.33,.69-.06,1.28-.6,1.39-1.33v-9.93c-.07-.06-.17-.16-.25-.32-.04-.08-.1-.2-.13-.44-.04-.34,0-.61,0-.63,0-.06,.03-.63,.06-1.77,.02-.78-.02-1.37-.06-2.02-.05-.7-.12-1.26-.19-1.83-.05-.39-.09-.71-.13-.95,0,0,.32-.06,.32,0,0,.12,.04,.29,.06,.51,.14,1.07,.22,1.63,.25,1.9,.12,.92,.02,1.14,0,3.67,0,.31,0,.79,0,1.39,.2,.33,.57,.52,.95,.51,.59-.03,.87-.55,.89-.57,.08-.6,.16-1.45,.19-2.47,.02-.79,.02-2.27-.25-4.17-.32-2.2-.77-3.28-1.39-3.92-.12-.13-.71-.73-1.08-.57-.08,.03-.09,.07-.25,.25,0,0-.31,.34-.63,.57-.32,.23-.63,.32-.82,.38-.34,.1-1.11,.26-1.96-.06-.28-.11-.73-.28-1.08-.7-.2-.24-.22-.38-.38-.44-.48-.18-1.23,.77-1.33,.89-.39,.48-.77,1.17-1.08,3.04-.19,1.16-.23,2.07-.25,2.53-.04,1.08-.06,2.57,.06,4.36,.03,.5,.45,.89,.95,.89s.91-.39,.95-.89c-.02-.8-.04-1.6-.06-2.4,0-.22,0-.52,0-.89,0-.43,.02-1.17,.13-2.09,.02-.18,.1-.69,.25-1.71h.32Z"/>
                <path className="cls-3" d="M17.64,24.32c-.21-.33-.45-.5-.82-.76-.67-.47-1.26-.65-1.39-.7-.37-.12-.7-.17-.95-.19l-.77-.59c-.2,.13-.41,.26-.61,.39,.45,.4,.72,.67,.75,.71,.07,.09,.13,.19,.13,.19h0c.06,.1,.22,.4,.51,.95,.12,.17,.28,.39,.51,.63,.18,.19,.36,.38,.63,.57,.25,.17,.72,.49,1.33,.44,.17-.01,.53-.04,.76-.32,.15-.17,.18-.37,.19-.44,.06-.39-.13-.7-.25-.89Z"/>
                <path className="cls-1" d="M12.8,7.62c-.05,.05-.38,.34-.38,.82,0,.61,.55,.94,.57,.95"/>
                <path className="cls-1" d="M13.3,7.74s-.27,.24-.25,.57c.01,.27,.18,.52,.44,.63"/>
                <circle className="cls-7" cx="15.66" cy="13.64" r=".26"/>
                <circle className="cls-7" cx="18.19" cy="13.64" r=".26"/>
                <circle className="cls-7" cx="15.62" cy="16.21" r=".26"/>
                <circle className="cls-7" cx="18.16" cy="16.21" r=".26"/>
                <circle className="cls-7" cx="15.62" cy="18.74" r=".26"/>
                <circle className="cls-7" cx="18.16" cy="18.74" r=".26"/>
                <path className="cls-2" d="M15.88,10.53h2.17c0-.05,.01-.13,0-.22-.04-.26-.22-.42-.29-.49-.3-.27-.66-.29-.76-.29-.38-.02-.65,.14-.71,.18,0,0-.28,.17-.47,.53-.02,.03-.05,.09-.06,.18,0,.05,0,.09,0,.12h.12Z"/>
            </g>
            <text className="cls-4" transform="translate(19.8 10.28)"><tspan x="0" y="0">?</tspan><tspan x="3.5" y="0">!</tspan></text>
            </svg>
        <div><label className="highlighted-text text-large">{sw.notFoundRecipes[language]}</label></div>
    </div>);
};

export default NotFoundRecipe;