// add useContext
import React, {useContext, useEffect} from 'react';
import {firebaseAuth} from '../../provider/AuthProvider'
import { Layout, Button, Input } from 'antd';
import './signin-page.css';
import sw from '../../utils/multilingual.json';
import { SocialIcon } from '../../components/tlac-icon/TlacIcon';
const { Footer } = Layout;

const Signin = (props) => {
  const redirectCheckin = window.location.pathname.includes('/checkin-order') ? true : false;
  const {handleSignin, handleSigninGoogle,handleForgotPs, setInputs, errors} = useContext(firebaseAuth);

  const language = window.location.pathname.includes('/en') ? 'english' :
                window.location.pathname.includes('/es') ? 'spanish' :
                (window.navigator.userLanguage || window.navigator.language).split('-')[0] === 'es' ? 'spanish' :
                'english';
  
  const handleSubmit = (e, reload) => {
    e.preventDefault()
    handleSignin(reload || redirectCheckin);
  };

  const handleSubmitGoogle = (e) => {
    e.preventDefault()
    console.log('handleSubmit')
    handleSigninGoogle(redirectCheckin);
    
  };

  const handleForgot = e=> {
    e.preventDefault();
    handleForgotPs();
  };

  const handleChange = e => {
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
  }

  useEffect(() => {
    
  }, [])

  return (
    <Layout className="main-page">
      <div className='header'>
      <a href='https://www.tlac.com.mx' className='header-logo'>
        <img width={120} src={require('../../pics/logo-horizontal-dark.png').default} alt='logo'/>
      </a>
      </div>
      <div className='content'>
          <form onSubmit={(e) => handleSubmit(e, false)}>
            <img src={require('../../pics/LOGO-01.png').default} alt='logo' width={200} />
            <p>
              <label>Email:</label>
              <Input className="top10" onChange={handleChange} onPressEnter={(e) => handleSubmit(e, false)} type="email" size="large" name="email" placeholder="email" prefix={<label></label>} />
            </p>
            <p>
              <label>Password:</label> 
              <Input className="top10" onChange={handleChange} onPressEnter={(e) => handleSubmit(e, false)} type="password" name="password" placeholder='password' prefix={<label></label>} />
            </p>
            <Button onClick={(e) => handleSubmit(e, false)} type='primary' className='signin-btn'>{sw.signin[language]}</Button>
            <Button onClick={handleSubmitGoogle} className='signin-btn-google' type='default'><img src={require('../../pics/google-logo.png').default} alt="google logo" height={20} /></Button>
            {errors.length > 0 ? <p className="error-message">{errors[errors.length-1]}</p> : null}
            <blockquote onClick={handleForgot} className="forgot-password">
              <small>{sw.forgotPassword[language]}</small>
            </blockquote>
            <span>{sw.or[language]}</span>
            <Button href='/Signup' type='primary' className="signup-btn">{sw.signup[language]}</Button>
          </form>
      </div>
      <Footer>
      <div><a href='/legal'>Legal</a> | <a href='/privacy-terms'>{sw.privacyTerms[language]}</a></div>
      TLAC ©2024 {sw.createdBy[language]} TLAC
      <div className='social-footer'>
        <a title='facebook' href='https://www.facebook.com/tlac.oficial'><SocialIcon type='facebook' size={40} /></a>
        <a title='instagram' href='https://www.instagram.com/tlac_oficial/' target='_blank' rel='noreferrer'><SocialIcon type='instagram' size={40}/></a>
        <a title='whatsapp' href='https://wa.me/+526633248667' target='_blank' rel='noreferrer'><SocialIcon type='whatsapp' size={40}/></a>
      </div>
      </Footer>
  </Layout>
    
  );
};

export default Signin;
