import React,{useState,useEffect,useRef} from 'react';
import { message,  Breadcrumb, Input, Image, Card, Button } from 'antd';
import sw from '../../utils/multilingual.json';
import { dbMethods } from '../../firebase/dbmethods.js';
import '../../pages/add-address-page/add-address-page.css';
import { searchAddress, loadCurrentLocation } from '../../google-maps/map';
import { SaveIcon, MapPinIcon } from '../../components/tlac-icon/TlacIcon.js';
import { PhoneOutlined } from '@ant-design/icons';
const {Search} = Input; 
const { Meta } = Card;

const NewAddress = (props)=> {
    const [zones,setZones] = useState([]);
    const [deliverAddress,setDeliverAddress] = useState(null);
    const [addressName, setAddressName] = useState('');
    const [content, setContent] = useState(<></>)
    const [isSearching, setSearching] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');

    const ref = useRef();

    const loadLocation = () => {
        loadCurrentLocation(setAddressName, setDeliverAddress, setSearching, zones)
    }
    
    useEffect(()=> {
        ref.current = {
            language:props.language,
            saveAddress: saveAddress,
        };    
    });

    useEffect(()=> {
        document.title = `TLAC | ${sw.addNewAddresses[ref.current.language]}`;
        dbMethods.getZones(setZones);
        if (!window.google?.maps) {
            const googleMapsScript = document.createElement('script');
            googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP}`;
            googleMapsScript.async = true;
            document.body.appendChild(googleMapsScript);
        }
        dbMethods.getUserPhoneNumber(setPhoneNumber)
    },[]);

    useEffect(() => {
        setContent(addressName ?
                <Card
                    hoverable
                    className='add-address-page-content'
                    cover={<Image preview={false} src={`https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent((deliverAddress?.lat ?? 0) + ',' + (deliverAddress?.lng ?? 0))}&zoom=15&size=600x300&maptype=roadmap&markers=color:purple|${encodeURIComponent((deliverAddress?.lat?? 0) + ',' + (deliverAddress?.lng ?? 0))}&key=${process.env.REACT_APP_MAP}`} />}
                    actions={[
                        <a href={`https://www.google.com.mx/maps/place/${deliverAddress?.lat??0},${deliverAddress?.lng??0}/@${deliverAddress?.lat??0},${deliverAddress?.lng??0},17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xc32720350d3a2f87!8m2!3d${deliverAddress?.lat??0}!4d${deliverAddress?.lng??0}`} target='_blank' rel='noreferrer'>
                            <MapPinIcon />
                        </a>,
                        <span onClick={ref.current.saveAddress}><SaveIcon /></span>
                    ]}
                >
                    <Meta title={addressName} description={`${deliverAddress?.lat ?? 0},${deliverAddress?.lng ?? 0}`} />
                    <br></br>
                    <div className='add-address-page-content-phone'>
                        <Input addonBefore={<PhoneOutlined />}  placeholder={sw.phoneNumber[ref.current.language]} onChange={e => setPhoneNumber(e.currentTarget.value)} defaultValue={phoneNumber} />
                    </div>
                </Card>:
                <Image className='add-address-page-content' preview={false} src={require('../../pics/delivery-address.png').default} width={'350px'} height={'300px'} />
        )
    }, [addressName, deliverAddress, phoneNumber])

    const saveAddress = ()=> {
        if(!phoneNumber){
            console.error('no phone number')
            message.warning(sw.invalidPhoneNumber[props.language]);
            return;
        }
        if(!deliverAddress?.zone){
            console.error('no deliver address zone')
            message.warning(sw.pleaseSelectAddress[props.language]);
            return;
        }
        if(!addressName){
            message.warning(sw.pleaseWriteName[props.language]);
            return;
        }
        const newAddress = {
            address: addressName,
            endEndHour: parseInt(deliverAddress.zone.hours.split('-')[1]),
            startHour: parseInt(deliverAddress.zone.hours.split('-')[0]),
            reference: '',
            isActiveInt:1,
            isActive:true,
            id:0,
            lat: deliverAddress.lat,
            lng: deliverAddress.lng,
            selectedDate: new Date().getTime()
        };  
        dbMethods.saveNewAddress(newAddress,props.language);
        dbMethods.savePhoneNumber(phoneNumber)
    };

    return (<div className="add-address-page">
        <Breadcrumb className='breadcrumb'>
            <Breadcrumb.Item href='/'>TLAC</Breadcrumb.Item>
            <Breadcrumb.Item>{sw.addNewAddresses[props.language]}</Breadcrumb.Item>
        </Breadcrumb>
        <div className='add-address-page-current-address'>
            <div className='address-container'>
            <Search
                    placeholder={`${sw.search[props.language]} ${sw.address[props.language]}`}
                    allowClear
                    loading={isSearching}
                    onSearch={(value) => searchAddress(
                        value, 
                        setAddressName, 
                        setDeliverAddress,
                        setSearching,
                        zones)}
                    className="search-bar-container-input"
                    size="large"
                />
                <Button type='link' size='small' className='current-location-btn' onClick={loadLocation}><MapPinIcon size={16} /> {sw.useCurrentLocation[props.language]}</Button>
            </div>
            {content}
            {/* <Tooltip title={sw.addNewAddresses[props.language]}>
                <Button onClick={saveAddress} className="add-address-page__save" size='middle'>
                    {sw.save[props.language]}
                </Button>
            </Tooltip> */}
        </div>
        </div>)
};

export default NewAddress;