import { Card, Row, Col, Button, Select } from 'antd';
import React, { } from 'react';
import {
    SaveOutlined
} from '@ant-design/icons';
import sw from '../../../utils/multilingual.json';
import { dbMethods } from '../../../firebase/dbmethods';
import { ArrowIcon, NutritionIcon } from '../../tlac-icon/TlacIcon';

const Health = (props)=> {

    const saveHealth = ()=> {
        dbMethods.setHealth(props.uid, props.health);
    }

    const setSize = (value, isMeters)=> {
        if(!isNaN(value)) 
            props.setHealth(prev=> ({...prev,
                                size: (isMeters? 
                                        String(value).concat('.',prev.size.split('.')[1]):
                                        prev.size.split('.')[0].concat('.',value))
                            }))
    }

    const expanded = (<div className="health-info__body">
        <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={2}>
                <label className="text-data text-medium margin-left-10">{sw.height[props.language]}:</label>
            </Col>
            <Col xs={9} sm={5} md={4} lg={5} xl={4} xxl={3}>
                <Select
                    defaultValue={props.health.size.split('.')[0]}
                    value={props.health.size.split('.')[0]}
                    onSelect={v=> setSize(v,true)}
                >
                    <Select.Option value={'0'}>0</Select.Option>
                    <Select.Option value={'1'}>1</Select.Option>
                    <Select.Option value={'2'}>2</Select.Option>
                </Select>
                <label className="text-data text-medium margin-left-10">m</label>
            </Col>
            <Col xs={9} sm={5} md={6} lg={6} xl={7} xxl={3}>
                <Select
                    defaultValue={props.health.size.split('.')[1]}
                    value={props.health.size.split('.')[1]}
                    onSelect={v=> setSize(v,false)}
                >
                    {Array.from(Array(99).keys())
                        .map(value=> <Select.Option value={value}>{value}</Select.Option>)}
                </Select>
                <label className="text-data text-medium margin-left-10">cm</label>
            </Col>
            <Col xs={5} sm={3} md={2} lg={3} xl={2} xxl={2}>
                <label className="text-data text-medium margin-left-10">{sw.weight[props.language]}:</label>
            </Col>
            <Col xs={9} sm={6} md={7} lg={5} xl={5} xxl={3}>
                <Select
                    defaultValue={props.health.weight}
                    value={props.health.weight}
                    onSelect={v=> props.setHealth(prev=> ({...prev,weight:v}))}
                >
                    {Array.from(Array(299).keys())
                        .map(value=> <Select.Option value={value}>{value}</Select.Option>)}
                </Select>
                <label className="text-data text-medium margin-left-10">kg</label>
            </Col>
            <Col xs={0} sm={12} md={12} lg={12} xl={12} xxl={0}>
            </Col>
            <Col xs={10} sm={12} md={12} lg={12} xl={12} xxl={6}>
                <Button
                    type="primary"
                    size="large"
                    className="save-health-button"
                    onClick={saveHealth}
                >
                    <SaveOutlined className="text-large" />
                        <label className="text-medium"> {sw.save[props.language]}</label>
                </Button>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            </Col>
        </Row>
    </div>)

    const closed = (<div className="health-info__body closed" onClick={props.setOpened}>
        <span>{props.health.size} m</span>
        <span>{props.health.weight} kg</span>
    </div>);

    return (<Card 
        className="health-info" 
        hoverable 
        title={<div className="health-info__title" onClick={props.setOpened}>
            <label>
                <NutritionIcon size={20} /> {sw.health[props.language]}
            </label>
            <ArrowIcon orientation={props.expand.health? 'top-left': 'bottom-right'} size={25} />
        </div>}>
            {props.expand.health? expanded:
                ((props.expand.info || props.expand.address)? null:closed)}
        </Card>)
}

export default Health;