import React, {useState,useEffect} from 'react';
import {Button, Modal, Input, Select, Checkbox, Row, Col,
        List, Typography, message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {dbMethods} from '../../firebase/dbmethods.js'
import * as xlsx from 'xlsx';
import csvJSON from '../../utils/readExcel.js'
const { Option } = Select;
const {TextArea} = Input;
const { Dragger } = Upload;

const CheffRecipes = (props)=> {

    const [newRecipe, setNewRecipe] = useState({
        name:{
            english:'',
            spanish:''
        },
        description:{
            english:'',
            spanish:''
        },
        time:10,
        likes:0,
        categories:[],
        is_lactose_free:false,
        is_low_calories:false,
        is_low_sodium:false,
        is_gluten_free:false,
        is_cooked_available:false,
        is_soy_free:false,
        is_sugar_free:false,
        is_vegan:false,
        is_veggie:false,
        dificulty:{
            english:'Easy',
            spanish:'Facil'
        },
        currency:'MXN',
        notes:{
            english:'',
            spanish:''
        },
        porcion:1,
        price:0,
        quantities:[
            /**  {   ingredient:undefined, 
                 is_main:false,
                 quantity:undefined,
                 unit:{
                 english:undefined,
                 spanish:undefined
                 }
             }*/ 
        ],
        steps:[
            /*{
                description:{
                    english:undefined,
                    spanish:undefined
                },
                step:1
            } */
        ],
        utensils:[
            /**  {
                 english:undefined,
                 spanish:undefined
             }*/
        ]
    });
    const [error,setError] = useState(true);
    // const [categories/*, setCategories*/] = useState([]);
// const [categoryList/*, setCategoryList*/] = useState([]);
    const [modalAddIngredient,setAddIngredientVisible] = useState(false);
    const [modalAddIngredientRecipe,setAddIngredientRecipeVisible] = useState(false);
    const [dropdownIngredient/*, setDropdownIngredient*/] = useState([]);
    const [dropdownUnit/*, setDropdownUnit*/] = useState([]);
    const [newQty, setNewQty] = useState({   
                                            ingredient: {
                                                name: {
                                                    spanish: '',
                                                    english: ''
                                                }
                                            }, 
                                            is_main:false,
                                            quantity: '1',
                                            unit:{
                                            english:'',
                                            spanish:''
                                            }
                                        });
    const [newIngredient,setNewIngredient] = useState({
                                            name:{
                                                english:'',
                                                spanish:''
                                            },
                                            is_gluten_free:false,
                                            is_sugar_free:false,
                                            is_vegan:false,
                                            is_veggie:false
                                        })
    useEffect(()=> {
        //Load categories 
        // loadCategories();
    },[]);


    let fileName = "";
    const loadFile = info => {
        const { originFileObj } = info.file;
        if(fileName !== originFileObj.name)
          if(originFileObj.name.split('.')[1] === 'xlsx'){
                fileName = originFileObj.name;
                const reader = new FileReader();
                reader.readAsBinaryString(originFileObj)
                reader.onload= (evt)=> {
                const bstr = evt.target.result;
                const wb = xlsx.read(bstr, {type:'binary'});
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = csvJSON(
                    xlsx.utils.sheet_to_csv(ws, {header:1}),
                    xlsx.utils.sheet_to_csv(wb.Sheets["ingredientes"], {header:1}),
                    xlsx.utils.sheet_to_csv(wb.Sheets["categorias"], {header:1}),
                    xlsx.utils.sheet_to_csv(wb.Sheets["unidades"], {header:1}),
                    setError
                );
                // const data = xlsx.utils.sheet_to_csv(ws, {header:1});
                /* Update state */
                console.log(data);
                // console.log(csvJSON(data));
                message.success("receta cargada");
                setNewRecipe(prev=> ({...prev,...data}));
                
          };
          }
          else{
              message.error("extension de archivo debe ser .xlsx");
              setError(true);
          }
          
    }

    // functions
    // const handleChangeCategory = value=>{
    //     setNewRecipe(prev=> ({...prev,categories: value.map(c=> categories.filter(f=> f.name.spanish === c)[0].name)}));
    // }

    // const loadCategories = ()=>{
    //     dbMethods.getCategories().then(resp=> {
    //         setCategories(resp);
    //         setCategoryList(resp.map(c=> <Option key={c.name.spanish}>{c.name.spanish}</Option>));
    //     }); 
    // }

    // const handleChangeDificulty = value=>{
    //     const dificulty = {
    //         '1':{
    //             english:'Easy',
    //             spanish:'Facil'
    //         },
    //         '2':{
    //             english:'Normal',
    //             spanish:'Normal'
    //         },
    //         '3':{
    //             english:'Expert',
    //             spanish:'Experto'
    //         }
    //     }[value]

    //     setNewRecipe(prev=> ({...prev,
    //         dificulty: dificulty
    //     }));
    //     console.log(newRecipe)
    // }

    // const onChangeCheckbox = e=> {
    //     const {name, checked} = e.target;
    //     setNewRecipe(prev=> ({...prev,[name]:checked}));
    // }

    const onChangeCheckNewIngredient = e=> {
        const {name, checked} = e.target;
        setNewIngredient(prev=> ({...prev,[name]:checked}));
    }

    // const handleChangeText = e=> {
    //     const {name, value} = e.target;
    //     const [key,language] = name.split('.');
    //     let multilingual = newRecipe[key];
    //     multilingual[language] = value; 
    //     setNewRecipe(prev=> ({...prev,[key]:multilingual}));
    // }

    const handleChangeTextIngredient = e=> {
        const {name, value} = e.target;
        const [key,language] = name.split('.');
        let multilingual = newIngredient[key];
        multilingual[language] = value; 
        setNewIngredient(prev=> ({...prev,[key]:multilingual}));
    } 

    // const handleChangeNumber= e=> {
    //     const {name, value} = e.target;
    //     if(value>0)
    //         setNewRecipe(prev=> ({...prev,[name]:value}));
    // }

    const addStep= ()=> {
        setNewRecipe(prev=> ({...prev,steps:[...prev.steps,{spanish: '', english: ''}]}));
        message.info('Paso agregado');
    }

    const addUtensil= ()=> {
        setNewRecipe(prev=> ({...prev,utensils:[...prev.utensils,{spanish: '', english: ''}]}));
        message.info('Utencilio agregado');
    }

    // const removeStep= (index)=> {
    //     const steps = newRecipe.steps;
    //     steps.splice(index,1);
    //     setNewRecipe(prev=> ({...prev,steps:steps}));
    //     message.info('Paso removido');
    // }

    // const removeIngredient = (index)=> {
    //     const ingredients = newRecipe.quantities;
    //     ingredients.splice(index,1);
    //     setNewRecipe(prev=> ({...prev,quantities:ingredients}));
    //     message.info('Ingrediente removido');
    // }

    // const removeUtensil= (index)=> {
    //     const utensils = newRecipe.utensils;
    //     utensils.splice(index,1);
    //     setNewRecipe(prev=> ({...prev,utensils:utensils}));
    // }

    // const handleEditTextList = e=> {
    //     const {name, value} = e.target;
    //     const [key, index, language] = name.split('.');
    //     let v = newRecipe[key];
    //     v[index][language] = value;
    //     setNewRecipe(prev=> ({...prev,[key]:v}));
    // }

    const handleAddIngredient = ()=> {
        dbMethods.addIngredient(newIngredient);
    }

    const handleAddIngredientRecipe = ()=> {
        if(newQty.ingredient && newQty.quantity && newQty.unit.spanish && newQty.unit.english){
            setNewRecipe(prev=> ({...prev,quantities:[...prev.quantities,newQty]}))
            setAddIngredientRecipeVisible(false);
            return;
        }
        message.warning('Complete los campos por favor');
    }
      
      function onBlur() {
        console.log('blur');
      }
      
      function onFocus() {
        console.log('focus');
      }
      
      function onSearch(val) {
        console.log('search:', val);
      }

      const renderDropdownIngredients = ()=> {
        //   dbMethods.getIngredients().then(resp=> {
        //         const options = resp.map(item=> <Option key={JSON.stringify(item)} value={JSON.stringify(item)}>{item.name.spanish}</Option>);
        //         setDropdownIngredient(options)
        //   });
      }

      const renderDropdownUnits = ()=> {
        //     dbMethods.getUnits().then(resp=> {
        //         const options = resp.map(item=> <Option key={JSON.stringify(item)} value={JSON.stringify(item)}>{item.spanish}</Option>);
        //         setDropdownUnit(options)
        // });
      }

      const handleShowAddIngredient = ()=> {
            setNewQty({   
                ingredient:{
                    name: {
                        spanish: '',
                        english: ''
                    }
                }, 
                is_main:false,
                quantity: '1',
                unit:{
                english:'',
                spanish:''
                }
            });
            renderDropdownIngredients();
            renderDropdownUnits();
            setAddIngredientRecipeVisible(true);
      }

      const handleAddIngredientVisible = ()=> {
            setNewIngredient({
                name:{
                    english:'',
                    spanish:''
                },
                is_gluten_free:false,
                is_sugar_free:false,
                is_vegan:false,
                is_veggie:false
            })
            setAddIngredientVisible(true);
      }

      const handleChangeQuantity = e=>{
        const value = e.target.value;
        if(value>0)
            setNewQty(prev=> ({...prev,quantity:'' + value}));
      }

      const handleAddRecipe = ()=> {
          dbMethods.addRecipe(newRecipe).then(resp=> {
              if(resp){
                setNewRecipe({
                    name:{
                        english:'',
                        spanish:''
                    },
                    description:{
                        english:'',
                        spanish:''
                    },
                    time:10,
                    categories:[],
                    is_lactose_free:false,
                    is_low_calories:false,
                    is_low_sodium:false,
                    is_gluten_free:false,
                    is_cooked_available:false,
                    is_soy_free:false,
                    is_sugar_free:false,
                    is_vegan:false,
                    is_veggie:false,
                    likes:0,
                    dificulty:{
                        english:'Easy',
                        spanish:'Facil'
                    },
                    currency:'MXN',
                    notes:{
                        english:'',
                        spanish:''
                    },
                    porcion:1,
                    price:0,
                    quantities:[
                        // {   ingredient:undefined, 
                        //     is_main:false,
                        //     quantity:undefined,
                        //     unit:{
                        //     english:undefined,
                        //     spanish:undefined
                        //     }
                        // } 
                    ],
                    steps:[
                        /*{
                            description:{
                                english:undefined,
                                spanish:undefined
                            },
                            step:1
                        } */
                    ],
                    utensils:[
                        // {
                        //     english:undefined,
                        //     spanish:undefined
                        // }
                    ]
                });
                setTimeout(()=> window.location.reload(), 3000)
              }
            });
      }

      

    return (
        <div>
            <Dragger name="file" multiple={false} accept="xlsx" onChange={loadFile}>
                <p className="ant-upload-drag-icon">
                <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click para cargar nueva receta</p>
                <p className="ant-upload-hint">
                Soporta unicamente archivos excel con extension xlsx
                </p>
            </Dragger>
            <div>
                <label>Nombre de platillo:</label>
                <Input /* onChange={handleChangeText} */ contentEditable="false" name="name.spanish" className="top10" value={newRecipe.name.spanish} maxLength={70} addonBefore="Español" defaultValue="" placeholder="Nombre en español" />
                <Input /* onChange={handleChangeText} */ contentEditable="false" name="name.english" addonBefore="Ingles" value={newRecipe.name.english} maxLength={70} defaultValue="" placeholder="Nombre en ingles" />
            </div>
            <div className="top10">
                <label>Descripcion de platillo:</label>
                <Input /* onChange={handleChangeText} */ contentEditable="false" name="description.spanish" value={newRecipe.description.spanish} className="top10" maxLength={100} addonBefore="Español" defaultValue="" placeholder="Descripcion en español" />
                <Input /* onChange={handleChangeText} */ contentEditable="false" name="description.english" value={newRecipe.description.english} maxLength={100} addonBefore="Ingles" defaultValue="" placeholder="Descripcion en ingles" />
            </div>
            <div className="top10">
                <Row>
                    <Col span={12}>
                        <label>Tiempo en minutos</label>
                        <Input /* onChange={handleChangeNumber} */ contentEditable="false" name="time" value={newRecipe.time} className="top10" pattern="\d*"  addonAfter="Minutos" type="number" defaultValue="0" placeholder="" />
                    </Col>
                    <Col span={12}>
                    <label>Porciones</label>
                        <Input /* onChange={handleChangeNumber} */ contentEditable="false" name="porcion" className="top10" value={newRecipe.porcion} pattern="[0-9]+([\.,][0-9]+)?" step="0.25"  addonAfter="personas" type="number" defaultValue="0" placeholder="" />
                    </Col>    
                </Row>
                
            </div>
            <div className="top10">
                <label>Categorias:</label><br></br>
                <Input contentEditable="false" name="categories" className="top10" value={newRecipe.categories.spanish}/>
                {/* <Select 
                className="top10"
                mode="multiple"
                size={categories.length}
                placeholder="seleccione categorias"
                // value={newRecipe.categories.map(item=> item.name.spanish)}
                allowClear
                onChange={handleChangeCategory}
                style={{width:'100%'}}
                >
                    {categoryList}
                </Select> */}
            </div>
            <div className="top10">
            <label>Dificultad:</label>
            <Select className="top10" defaultValue={newRecipe.dificulty.spanish} style={{ width: 120 }} /* onChange={handleChangeDificulty} */>
                <Option value="1">Facil</Option>
                <Option value="2">Normal</Option>
                <Option value="3">Experto</Option>
            </Select>
            </div>
            <div className="top10">
                <label>Caracteristicas</label>
                <Row className="top10">
                    <Col span={12}>    
                        <Checkbox checked={newRecipe.is_vegan} /* onChange={onChangeCheckbox} */ name="is_vegan">Es vegano</Checkbox>
                    </Col>
                    <Col span={12}>
                        <Checkbox checked={newRecipe.is_veggie} /* onChange={onChangeCheckbox} */ name="is_veggie">Es vegetariano</Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Checkbox checked={newRecipe.is_gluten_free} /* onChange={onChangeCheckbox} */ name="is_gluten_free">Libre de gluten</Checkbox>
                    </Col>
                    <Col span={12}>
                    <Checkbox checked={newRecipe.is_lactose_free} /* onChange={onChangeCheckbox} */ name="is_lactose_free">Libre de lactosa</Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Checkbox checked={newRecipe.is_low_calories} /* onChange={onChangeCheckbox} */ name="is_low_calories">Bajas calorias</Checkbox>
                    </Col>
                    <Col span={12}>
                        <Checkbox checked={newRecipe.is_low_sodium} /* onChange={onChangeCheckbox} */ name="is_low_sodium">Bajo en sodio</Checkbox> 
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Checkbox checked={newRecipe.is_soy_free} /* onChange={onChangeCheckbox} */ name="is_soy_free">Libre de soya</Checkbox>
                    </Col>
                    <Col span={12}>
                        <Checkbox checked={newRecipe.is_cooked_available} /* onChange={onChangeCheckbox} */ name="is_cooked_available">Disponible ya preparado</Checkbox>
                    </Col>
                </Row>      
            </div>
            <div className="top10">
                <List
                header={<div>Ingredientes<Button disabled onClick={handleShowAddIngredient} type="primary" style={{right:80,top:5, position: 'absolute'}}>Agregar</Button><Button type="default" onClick={handleAddIngredientVisible} style={{right:5,top:5, position: 'absolute'}} disabled>Nuevo</Button></div>}
                footer={<div>{newRecipe.quantities.length} ingredientes agregados</div>}
                bordered
                dataSource={newRecipe.quantities}
                renderItem={(item,i) => (
                    <List.Item style={{background:item.is_main? '#d1ffc8':'#fff'}}>
                        <Col span={2}>
                            <label>{item.quantity} </label>
                        </Col>
                        <Col span={10}>
                            <label>{item.unit.spanish.concat('(s) de ')} </label>
                        </Col>
                        <Col span={10}>
                        <Typography.Text mark>{item.ingredient}</Typography.Text>
                        </Col>
                        <Col span={2}>
                        {/* <Button shape="circle" style={{marginLeft:5}} type="primary" danger onClick={()=> removeIngredient(i)}>X</Button> */}
                        </Col>
                    </List.Item>
                )}
                />
            </div>
            <div className="top10">
                <List
                header={<div>Pasos de preparado<Button disabled onClick={addStep} type="primary" style={{right:5,top:5, position: 'absolute'}}>Agregar</Button></div>}
                footer={<div>{newRecipe.steps.length} pasos agregados</div>}
                bordered
                dataSource={newRecipe.steps}
                renderItem={(item,i) => (
                    <List.Item>
                    {/* <Row> */}
                        <Col span={2}>
                            <Typography.Text mark>[{i + 1}] </Typography.Text>
                        </Col>
                        <Col span={20}>
                            <Input type="text" addonBefore="es" /* onChange={handleEditTextList} */ placeholder="español" name={'steps.'.concat(i,'.spanish')} value={item.description.spanish} /> 
                            <Input type="text" addonBefore="in" /* onChange={handleEditTextList} */ placeholder="ingles" name={'steps.'.concat(i,'.english')} value={item.description.english} />
                        </Col>
                        {/* <Col span={2}>
                            <Button shape="circle" style={{marginLeft:5}} type="primary" danger onClick={()=> removeStep(i)}>X</Button>
                        </Col> */}
                    
                    </List.Item>
                )}
                />
            </div>
            <div className="top10">
                <List
                header={<div>Utensilios<Button disabled onClick={addUtensil} type="primary" style={{right:5,top:5, position: 'absolute'}}>Agregar</Button></div>}
                footer={<div>{newRecipe.utensils.length} utensilios agregados</div>}
                bordered
                dataSource={newRecipe.utensils}
                renderItem={(item,i) => (
                    <List.Item>
                    {/* <Row> */}
                        <Col span={2}>
                            <Typography.Text mark>[{i + 1}] </Typography.Text>
                        </Col>
                        <Col span={20}>
                            <Input type="text" addonBefore="es" /* onChange={handleEditTextList} */ placeholder="español" name={'utensils.'.concat(i,'.spanish')} value={item.spanish} /> 
                            <Input type="text" addonBefore="in" /* onChange={handleEditTextList} */ placeholder="ingles" name={'utensils.'.concat(i,'.english')} value={item.english} />
                        </Col>
                        {/* <Col span={2}>
                            <Button shape="circle" style={{marginLeft:5}} type="primary" danger onClick={()=> removeUtensil(i)}>X</Button>
                        </Col> */}
                    
                    </List.Item>
                )}
                />
            </div>
            <div className="top10">
                <label>Notas y comentarios</label>
                <div className="top10" style={{background:'#f4f4f4'}}>
                 <label style={{margin:5}}>Español</label>
                <TextArea style={{padding:5}} value={newRecipe.notes.spanish} className="top10" name="notes.spanish" placeholder="Tus notas y comentarios de la receta" /* allowClear onChange={handleChangeText} */ />
                </div>
                <div className="top10" style={{background:'#f4f4f4'}}>
                <label style={{margin:5}}>Ingles</label>
                <TextArea style={{padding:5}} value={newRecipe.notes.english} className="top10" name="notes.english" placeholder="Tus notas y comentarios de la receta" /* allowClear onChange={handleChangeText} */ />
                </div>
            </div>
            <div className="top10">
            <Button disabled={error} type="primary" onClick={handleAddRecipe} block>Agregar nueva receta</Button>
            </div>

            <Modal
                title={'Agregar nuevo ingrediente'}
                visible={modalAddIngredient}
                onOk={handleAddIngredient}
                onCancel={()=> setAddIngredientVisible(!modalAddIngredient)}
                okText="Agregar"
                cancelText="Cancelar"
            >
                <Row>
                    <Col span={24}>
                    Nombre: 
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Input onChange={handleChangeTextIngredient} name="name.spanish" className="top10" value={newIngredient.name.spanish} maxLength={70} addonBefore="es" defaultValue="" placeholder="Nombre en español" />
                        <Input onChange={handleChangeTextIngredient} name="name.english" addonBefore="in" value={newIngredient.name.english} maxLength={70} defaultValue="" placeholder="Nombre en ingles" />
                    </Col>
                </Row>
                <Row className="top10">
                    <Col span={12}>
                        <Checkbox className="top10" onChange={onChangeCheckNewIngredient} name="is_sugar_free">Libre de azucar</Checkbox>
                    </Col>
                    <Col span={12}>
                        <Checkbox onChange={onChangeCheckNewIngredient} name="is_gluten_free">Libre de gluten</Checkbox>    
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Checkbox onChange={onChangeCheckNewIngredient} name="is_vegan">Vegano</Checkbox>
                    </Col>
                    <Col span={12}>
                        <Checkbox onChange={onChangeCheckNewIngredient} name="is_veggie">Vegetariano</Checkbox>
                    </Col>
                </Row>
            </Modal>
            <Modal
                title={'Agregar ingrediente a receta'}
                visible={modalAddIngredientRecipe}
                onOk={handleAddIngredientRecipe}
                onCancel={()=> setAddIngredientRecipeVisible(!modalAddIngredientRecipe)}
                okText="Agregar"
                cancelText="Cancelar"
            >
                <Row>
                    <Col span={8}>
                    Ingrediente: 
                    </Col>
                    <Col span={16}>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        value={newQty.ingredient.name.spanish}
                        placeholder="Seleccione ingrediente"
                        optionFilterProp="children"
                        onChange={val=> setNewQty(prev=> ({...prev,ingredient: JSON.parse(val)}))}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {dropdownIngredient}
                    </Select>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                    Unidad:
                    </Col>
                    <Col span={16}>
                    <Select
                        showSearch
                        value={newQty.unit.spanish}
                        style={{ width: 200 }}
                        placeholder="Seleccione unidad"
                        optionFilterProp="children"
                        onChange={val=> setNewQty(prev=> ({...prev,unit: JSON.parse(val)}))}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {dropdownUnit}
                    </Select> 
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                    Cantidad:
                    </Col>
                    <Col span={16}>
                    <Input onChange={handleChangeQuantity} name="quantity" value={newQty.quantity} className="top10" pattern="\d*"  type="number" defaultValue="1" placeholder="" />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Checkbox checked={newQty.is_main} onChange={e=> setNewQty(prev=> ({...prev,is_main: e.target.checked}))}>Es principal</Checkbox>
                    </Col>
                </Row>
            </Modal>

        </div>
    )
};

export default CheffRecipes;