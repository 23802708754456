import React,{useState,useEffect} from 'react';
import { message, Breadcrumb, Card, Button, List, Space, Avatar, Collapse } from 'antd';
import sw from '../../utils/multilingual.json'; 
import { MapPinIcon, } from '../../components/tlac-icon/TlacIcon.js';
import './delivery.css'
import { dbMethods } from '../../firebase/dbmethods';
import { PhoneOutlined, CopyOutlined, InfoCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { getImageURL } from '../../firebase/firestore-images.js';
import confirm from 'antd/lib/modal/confirm.js';

const { Panel } = Collapse;

const Delivery = (props)=> {
    const [orders, setOrders] = useState([])
    const deliveryDays = JSON.parse(localStorage.getItem('dd')) ?? {days: [4, 5, 6, 0]};

    useEffect(() => {
        dbMethods.getWeekOrders(setOrders)
    }, [])

    const setDelivered = (order, dayIndex, nextDate) => {
        confirm({
            icon: <InfoCircleFilled />,
            content: <div>¿Seguro que desea entregar?</div>,
            onOk() {
                if (!nextDate) order.delivered = order.scheduled;
                else order.scheduled = nextDate;
                order.deliveryDays = order.deliveryDays.map(day => {
                    if (day.index === dayIndex) day.isDelivered = true;
                    return day;
                });
                dbMethods.updateOrder(order.key, order);
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    const callPhone = (uid) => dbMethods.callPhone(uid)

    const copyKey = (key) => navigator.clipboard.writeText(key).then(() => {
        message.info('Identificador de orden copiado');
    })

    const getMapUrl = (lat, lng) => 
        `https://www.google.com.mx/maps/place/${lat},${lng}/@${lat},${lng},17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xc32720350d3a2f87!8m2!3d${lat}!4d${lng}`;

    const ListItem = ({item}) => {
        const scheduledDate = item.scheduled.toDate()
        const dayOfWeek = scheduledDate.getDay()
        let index = deliveryDays.days.findIndex(f => f === dayOfWeek)
        if (index === -1) return (<></>)
        
        return <List.Item key={item.key}>
        <List.Item.Meta 
            title={<Space direction='horizontal'>
                <CopyOutlined onClick={() => copyKey(item.key)} />
                {/* <Badge color='#5a139e' count={`${formatDate(item.scheduled.toDate())} ${item.selectedHour}`} />  */}
                <strong><small>{item.userName} ({item.uid})</small></strong>
                </Space>}
            description={<Space direction='vertical' style={{width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', gap: 10}}>
                        <Button 
                                shape='circle' 
                                type='default' 
                                onClick={() => callPhone(item.uid)} 
                                icon={<PhoneOutlined />}/>
                        <Button 
                            target='_blank' 
                            type='default' 
                            shape='circle' 
                            rel='noreferrer' 
                            href={getMapUrl(item?.address?.lat ?? 0, item?.address?.lng ?? 0)} 
                            icon={<MapPinIcon size={20} />}></Button>
                    </div>
                    {item.delivered && <CheckCircleFilled style={{color: 'green', fontSize: 30}} />}
                </div>
                <Collapse>
                    {item.deliveryDays.map((day, i) => {
                        return (
                        <Panel 
                            header={day.date.toDate().toLocaleDateString()} 
                            key={'order-item' + i}
                            extra={<Button 
                                shape='round' 
                                type='primary'
                                disabled={item.scheduled.toDate().toLocaleDateString() !== day.date.toDate().toLocaleDateString() || item.delivered}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDelivered(item, day.index, item.deliveryDays[i + 1]?.date?.toDate());
                                }}
                            >
                                Entregar
                            </Button>}
                        >
                            <List
                                bordered
                                dataSource={item.order.filter(f => f.day === day.index) ?? []}
                                renderItem={(item, index) => (<List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={getImageURL(item.key, 'jpg', 'recipe-pic-small')} size={50} />}
                                        title={<small>{(item.metadata?.name?.spanish ?? item?.key)}</small>}
                                        description={<div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                            <small>cantidad: {item.qty}</small> 
                                        </div>}
                                    />
                                </List.Item>)}
                                />
                        </Panel>) 
                    })}
                </Collapse>
            </Space>}
            /> 
        </List.Item>
    } 

    return (
        <div className='delivery-page'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item href='/'>TLAC</Breadcrumb.Item>
                <Breadcrumb.Item>{sw.delivery[props.language]}</Breadcrumb.Item>
            </Breadcrumb>
            <Card title="Pedidos de la semana">
            <List
                bordered
                dataSource={orders??[]}
                renderItem={(item) => (
                    <ListItem item={item} />
                )}
                />
            </Card>

        </div>)
}

export default Delivery;