// add useContext
import React, {useContext} from 'react';
import {firebaseAuth} from '../../provider/AuthProvider';
import {withRouter} from 'react-router-dom';
import { Layout, Input } from 'antd';
import './signup.css';
import { SocialIcon } from '../../components/tlac-icon/TlacIcon';

const { Footer } = Layout;


const Signup = (props) => {
    const {handleSignup,inputs,setInputs, errors} = useContext(firebaseAuth)
    
    const handleSubmit = async (e)=> {
        e.preventDefault();
        console.log('handle submit');
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(inputs.email.length && inputs.password.length && inputs.userName.length && inputs.phoneNumber.length===10 && re.test(inputs.email) ) {
            await handleSignup();
            console.log('correct');
            //push home
            props.history.push('/')
        }   
        else
            console.log('incorrect');
    
    }

    const handleChange = e=> {
        const {name, value} = e.target;
        console.log(inputs);

        if(name === 'phoneNumber'){
            if(!isNaN(value))
                setInputs(prev => ({...prev, [name]:value})); 
        } 
        else if(name !== 'password'){
            const regex = /[!$%^&*#()+|~=`{}[\]:";'<>?,/]/;
            if(!regex.test(String(value).toLowerCase()))
                setInputs(prev => ({...prev, [name]:value}));
        }
        else{
            setInputs(prev => ({...prev, [name]:value}));
        }
    }

    return (
        <Layout className='signup-page' style={{height:window.screen.height}}>
    <div className="signup-page-content">
    <a href='/'>
      <img width={120} src={require('../../pics/logo-horizontal-dark.png').default} alt='logo'/>
    </a>
      <form onSubmit={handleSubmit} className='signup-form'>
            <h1>Sign up</h1>
            <p className="top10">
              <label>User Name:</label>
              <Input className="top10" onChange={handleChange} type="text" maxLength={60} size="large" name="userName" placeholder="User Name" value={inputs.userName} prefix={<text></text>} />
            </p>
            <p>
              <label>Email:</label>
              <Input className="top10" onChange={handleChange} type="email" size="large" name="email" placeholder="email" value={inputs.email} prefix={<text></text>} />
            </p>
            <p>
              <label>Password:</label> 
              <Input className="top10" onChange={handleChange} type="password" name="password" placeholder='password' value={inputs.password} prefix={<text></text>} />
            </p>
            <p>
              <label>Phone Number:</label> 
              <Input className="top10" onChange={handleChange} type="tel" pattern="\d*" maxLength={10} name="phoneNumber" placeholder='Phone Number' value={inputs.phoneNumber} prefix={<text></text>} />
            </p>

            <button>Signup</button>
            {errors.length > 0 ? <p className="text-center danger-text">{errors[errors.length-1]}</p> : null}
        </form>
    </div>
    <Footer>
      <a href='/legal'>Legal</a>
      TLAC ©2023 Created by TLAC
      <div className='social-footer'>
        <a title='facebook' href='https://www.facebook.com/tlac.oficial'><SocialIcon type='facebook' /></a>
        <a title='instagram' href='https://www.instagram.com/tlac.oficial/' target='_blank' rel='noreferrer'><SocialIcon type='instagram' /></a>
        <a title='whatsapp' href='https://wa.me/+351934043683' target='_blank' rel='noreferrer'><SocialIcon type='whatsapp' /></a>
      </div>
      </Footer>
  </Layout>
    );
};

export default withRouter(Signup);
