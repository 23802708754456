import React, {useState, useEffect, createRef} from 'react';
import { Card, Input, List, Modal, Tag, Popover } from 'antd';
import {dbMethods} from '../../../firebase/dbmethods.js'
import sw from '../../../utils/multilingual.json'
import diets from '../../../utils/supported-diets.json'; 
import { fa } from '../../../firebase/analytics.js';
import Meta from 'antd/lib/card/Meta.js';
import { getImageURL } from '../../../firebase/firestore-images.js';
import { DietIcon } from '../../tlac-icon/TlacIcon.js';
import { InfoCircleFilled } from '@ant-design/icons';
const {Search} = Input; 
const { CheckableTag } = Tag;

const RecipeSelector = ({ language, day, visible, setVisible, setShoppingCart, isSnack })=> {
    const [recipes, setRecipes] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const searchRef = createRef();

    const setItem = (item) => {
        setShoppingCart(prev => {
            console.log(day)
            const newPack = {...prev.pack};
            newPack.days[day.index] = [...newPack.days[day.index]];
            newPack.days[day.index][day.itemIndex] = {
                itemkey: item.key,
                day: day?.index ?? 0,
                metadata: {
                    name: item.name
                },
                qty: 1,
                type: isSnack ? 2 : 1
            };
            dbMethods.updateShoppingCart(null, null, newPack.isCooked, newPack)
          
            return {
              ...prev,
              pack: newPack
            };
          });
          close();
    }

    const close = () => {
        if (searchRef.current.input.value)
            searchRef.current.input.parentElement.querySelector('.ant-input-clear-icon')?.click()
        setRecipes([])
        setVisible(false);
    }

    const setFilter = (tag, checked) => {
        const nextSelectedTags = checked
          ? [...selectedTags, tag]
          : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
        onSearch(searchValue, nextSelectedTags);
    };    

    const onSearch = (value, tags) => {
        fa('search_recipe');
        setSearchValue(value);
        setRecipes(recipes.map(recipe => {
            if (tags === undefined) tags = selectedTags;
            const allTagsMatch = tags.length ? tags.every(tag => recipe[tag.index] === true) : true;
            recipe.visible = (recipe.name[language].toLowerCase().includes(value.toLowerCase())
            || recipe.description[language].toLowerCase().includes(value.toLowerCase())
            || recipe.categories[language].toLowerCase().includes(value.toLowerCase()))
            && (allTagsMatch);
            return recipe
        })
        );
    }

    useEffect(()=> {
        if (visible) {
            dbMethods.getRecipes(setRecipes, setLoading, isSnack);
        }
    },[visible, day?.index, isSnack]);

return (
    <Modal
        className="recipe-selector"
        title={<label className="recipe-selector-title">{sw.selectDish[language]} ({day?.name})</label>}
        open={visible}
        onCancel={close}
        footer={null}
    >
        <List 
            className='recipe-selector-list'
            grid={{ gutter: 5, column: 2 }}
            dataSource={recipes.filter(f => f.visible)}
            loading={loading}
            header={<div className='recipe-selector-list-search-bar' style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                <Search
                    ref={searchRef}
                    placeholder={sw.search[language]}
                    allowClear
                    onSearch={(value) => onSearch(value)}
                    className="recipe-selector-list-search-bar-input"
                    size='middle'
                    style={{flex: 12}}
                />
                <div style={{width: '100%', overflowX: 'scroll'}}>
                <div className='recipe-selector-list-search-bar-filters' style={{display: 'flex', justifyContent: 'space-between'}}>
                {diets.map((tag) => (
                    <Popover key={tag.index} content={(<span>{sw[tag.name][language]}</span>)}>
                        <CheckableTag
                        className='recipe-selector-list-search-bar-filters-diet'
                        key={tag.index}
                        checked={selectedTags.indexOf(tag) > -1}
                        style={{borderRadius: 10}}
                        onChange={(checked) => setFilter(tag, checked)}
                        >
                            <DietIcon type={tag.index} size={35} class='diet-icon' />          
                        </CheckableTag>
                    </Popover>
                ))}
                </div>
                </div>
            </div>}
            renderItem={(item) => (
                <List.Item className='recipe-selector-list-item'>
                    <Card 
                        className='recipe-selector-list-item-card'
                        hoverable
                        onClick={() => setItem(item)}
                        cover={<img src={getImageURL(item.key,'jpg','recipe-pic-small')} alt='recipe' className='recipe-selector-list-item-card-cover' />}
                        style={{borderRadius: 14}}
                        actions={[
                            <a 
                                href={document.location.href.concat('recipe/', item.key)} 
                                target='_blank'
                                rel='noreferrer'
                                onClick={e => e.stopPropagation()}
                            >
                                <InfoCircleFilled style={{marginTop: 6}} />
                            </a>,
                        ]}
                    >
                        <Meta
                            title={item.name[language]}
                            // description={item.description[language]}
                        />
                    </Card>
                </List.Item>
            )}
        />
    </Modal>
);
};

export default RecipeSelector;