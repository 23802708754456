import React from "react";

export const CartAddIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'add-to-cart2': 'add-to-cart1'}.svg`).default} 
        alt="add to cart" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const BagsIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'bags1': 'bags2'}.svg`).default} 
        alt="bags" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const BasquetIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'basquet2': 'basquet1'}.svg`).default} 
        alt="basquet" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const CalendarIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'calendar3': 'calendar1'}.svg`).default} 
        alt="calendar" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const ChartIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'chart1': 'chart2'}.svg`).default} 
        alt="chart" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const ClockIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'clock2': 'clock1'}.svg`).default} 
        alt="clock" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const ConfigIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'config2': 'config1'}.svg`).default} 
        alt="config" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const CookBookIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'cook-book2': 'cook-book1'}.svg`).default} 
        alt="cook-book" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const CookIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'cook1': 'cook2'}.svg`).default} 
        alt="cook" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const CookedIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'mealkit-dark': 'mealkit-white'}.svg`).default} 
        alt="cooked" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const DotsIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'dots1': 'dots2'}.svg`).default} 
        alt="dots" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const GlutenFreeIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'gluten-free1': 'gluten-free2'}.svg`).default} 
        alt="glutten-free" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const HighDifIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/high-dif1.svg`).default} 
        alt="high-diff" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const HomeIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'home1': 'home2'}.svg`).default} 
        alt="home" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const IngredientsIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'ingredients2': 'ingredients1'}.svg`).default} 
        alt="ingredients" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const KetoIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'keto1': 'keto2'}.svg`).default} 
        alt="keto" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const LactoseFreeIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'lactose-free1': 'lactose-free2'}.svg`).default} 
        alt="lactose-free" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const LikeIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'liked': 'like-white'}.svg`).default} 
        alt="like" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const LogoutIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'logout1': 'logout2'}.svg`).default} 
        alt="add to cart" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const LowCalIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'low-cal1': 'low-cal2'}.svg`).default} 
        alt="low-cal" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const LowDifIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/low-dif1.svg`).default} 
        alt="low-diff" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const LowSaltIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'low-salt2': 'low-salt1'}.svg`).default} 
        alt="sow-salt" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const MapPinIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'map-pin1': 'map-pin2'}.svg`).default} 
        alt="map-pin" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const MidDifIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/mid-dif1.svg`).default} 
        alt="mid-diff" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const NutritionAppleIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'nutrition-apple1': 'nutrition-apple2'}.svg`).default} 
        alt="nutrition-apple" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const NutritionIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'nutrition1': 'nutrition2'}.svg`).default} 
        alt="nutrition" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const PlayIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'play2': 'play1'}.svg`).default} 
        alt="play" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const CartRemoveIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'remove-cart2': 'remove-cart1'}.svg`).default} 
        alt="cart-remove" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const SaveIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'save1': 'save2'}.svg`).default} 
        alt="save" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const ShoppingCartIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'shopping-cart': 'shopping-cart2'}.svg`).default} 
        alt="shopping-cart" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const SoyFreeIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'soy-free1': 'soy-free2'}.svg`).default} 
        alt="soy-free" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const SpatulasIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/spatulas1.svg`).default} 
        alt="spatulas" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const SpoonFolkIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'spoon-folk2': 'spoon-folk1'}.svg`).default} 
        alt="spoon-folk" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const SugarFreeIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'sugar-free1': 'sugar-free2'}.svg`).default} 
        alt="sugar-free" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const TrashIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'trash1': 'trash2'}.svg`).default} 
        alt="trash" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const UploadIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'upload1': 'upload2'}.svg`).default} 
        alt="upload" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const UserIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'user2': 'user1'}.svg`).default} 
        alt="user" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const UtensilIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'utensil2': 'utensil3'}.svg`).default} 
        alt="utensil" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const VeganIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'vegan1': 'vegan2'}.svg`).default} 
        alt="vegan" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const VeggieIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'veggie2': 'veggie1'}.svg`).default} 
        alt="veggie" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const VideoIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'video2': 'video1'}.svg`).default} 
        alt="video" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const WalletIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'wallet2': 'wallet1'}.svg`).default} 
        alt="wallet" 
        width={size ?? 30}
        className={className} 
        style={{transform: 'translateY(-5px)'}}
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const AddRecipeIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./svg/${dark? 'suggest-recipe-dark': 'suggest-recipe'}.svg`).default} 
        alt="sr" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;

export const ArrowIcon = ({size, class_name, orientation, dark, onClick}) => 
    <img 
        src={require(`./svg/arrow.svg`).default} 
        alt="arrow" 
        width={size ?? 30}
        onClick={onClick}
        className={`arrow-icon ${class_name} ${orientation} ${dark ? 'dark' : ''}`}
    />;

export const DificultyIcon = ({ size, dark, type, className, onClick }) => {
    switch(type) {
        case 'easy':
            return <LowDifIcon size={size ?? 30} dark={dark ?? false} class={className} onClick={(e) => {
                if (onClick)
                    onClick(e);
            }} />;
        case 'normal':
            return <MidDifIcon size={size ?? 30} dark={dark ?? false} class={className} onClick={(e) => {
                if (onClick)
                    onClick(e);
            }} />;
        case 'hard':
            return <HighDifIcon size={size ?? 30} dark={dark ?? false} class={className} onClick={(e) => {
                if (onClick)
                    onClick(e);
            }} />;
        default:
            return <LowDifIcon size={size ?? 30} dark={dark ?? false} class={className} onClick={(e) => {
                if (onClick)
                    onClick(e);
            }} />;
    }
}

export const DietIcon = ({ size, dark, className, type, onClick }) => {
    switch(type) {
        case 'is_gluten_free':
            return <GlutenFreeIcon size={size ?? 30} dark={dark ?? false} class={`${type} ${className}`} />;
        case 'is_lactose_free':
            return <LactoseFreeIcon size={size ?? 30} dark={dark ?? false} class={`${type} ${className}`} />;
        case 'is_low_calories':
            return <LowCalIcon size={size ?? 30} dark={dark ?? false} class={`${type} ${className}`} />;
        case 'is_low_sodium':
            return <LowSaltIcon size={size ?? 30} dark={dark ?? false} class={`${type} ${className}`} />;
        case 'is_soy_free':
            return <SoyFreeIcon size={size ?? 30} dark={dark ?? false} class={`${type} ${className}`} />;
        case 'is_sugar_free':
            return <SugarFreeIcon size={size ?? 30} dark={dark ?? false} class={`${type} ${className}`} />;
        case 'is_vegan':
            return <VeganIcon size={size ?? 30} dark={dark ?? false} class={`${type} ${className}`} />;
        case 'is_veggie':
            return <VeggieIcon size={size ?? 30} dark={dark ?? false} class={`${type} ${className}`} />;
        default:
            return <SpoonFolkIcon size={size ?? 30} dark={dark ?? false} class={`all ${className}`} />;
    }
}

export const RecipeFeatureIcon = ({ size, dark, type, number, className, onClick }) => {
    switch(type) {
        case 'ingredient': 
            return <IngredientsIcon size={size ?? 30} dark={dark ?? false} class={`${type} ${className}`} />;
        case 'step':
            return <span className={`${type} ${className}`}>{number}.</span>;
        case 'utensil':
            return <UtensilIcon size={size ?? 30} dark={dark ?? false} class={`${type} ${className}`} />;
        case 'nutritional':
            return <NutritionAppleIcon size={size ?? 30} dark={dark ?? false} class={`${type} ${className}`} />;
        default:
            return <div className={`${type} ${className}`}></div>;
    }
}

export const StreetViewIcon = ({ size, dark, className, onClick }) => 
    <img 
        src={require(`./png/streetview.png`).default} 
        alt="st view" 
        width={size ?? 30}
        className={className} 
    />;

export const SocialIcon = ({ size, dark, type, className, onClick }) => {
    switch(type) {
        case 'facebook': 
            return <img 
            src={require(`./svg/facebook-icon.svg`).default} 
            alt="st view" 
            width={size ?? 30}
            className={className}
            onClick={(e) => {
                if (onClick)
                    onClick(e);
            }} 
        />;
        case 'instagram': 
            return <img 
            src={require(`./svg/instagram-icon.svg`).default} 
            alt="st view" 
            width={size ?? 30}
            className={className} 
            onClick={(e) => {
                if (onClick)
                    onClick(e);
            }}
        />;
        case 'whatsapp': 
            return <img 
            src={require(`./svg/whatsapp-icon.svg`).default} 
            alt="st view" 
            width={size ?? 30}
            className={className} 
            onClick={(e) => {
                if (onClick)
                    onClick(e);
            }}
        />;
        default:
            return <div className={`${type} ${className}`}></div>;
    }
}

export const DiscountIcon = ({size, className, onClick}) => 
    <img 
        src={require(`./svg/discount.svg`).default} 
        alt="discount" 
        width={size ?? 30}
        className={className} 
        onClick={(e) => {
            if (onClick)
                onClick(e);
        }}
    />;