import React from 'react';
import GMap from '../../../google-maps/GMap.js';
// import MapContainer from '../../../google-maps/GoogleApiWrapper.js';
// import { isMobile } from '../../../utils/mobile';

const OrderAddress = (props)=> {

    return (
        <div>
            {/* <label id="map-data" style={{display:'none'}}></label> */}
            <div className='address-modal-map'>
                <GMap 
                    language={props.language}
                    isDeliverAddressDraggable={false} 
                    deliverAddress={props.deliverAddress} 
                    markers={[]}
                    addressName={props.addressName}
                />
            </div>
        </div>
    );
};

export default OrderAddress;