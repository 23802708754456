import { List } from "antd";
import { renderToString } from 'react-dom/server';
import React, { useRef, useState, useEffect } from "react";
import { dbMethods } from "../../../firebase/dbmethods.js";
import { isMobileScreen } from "../../../utils/mobile.js";
import NotFoundOrder from '../../error/NotFoundOrder.js';
import OrderListItem from "../order-list-item/OrderListItem.js";
import OrderFilter from "../order-filter/OrderFilter.js";

const OrderList = (props) => {
    const [orders, setOrders] = useState([]);
    const [pagination, setPag] = useState({
        onChange: page => {
          console.log(page);
        },
        pageSize: isMobileScreen()? 3 : 6,
        showTitle: true
      });

    const [period, setPeriod] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear()
    });

    const loadOrders = ()=> {
        dbMethods.getOrders(
            new Date(period.year,period.month , 1, 0, 0, 0),
            new Date(period.year,period.month + 1, 0, 23, 59, 59),
            setOrders
        );
    }

    const ref = useRef();

    useEffect(()=> {
        ref.current = {
            language: props.language,
            loadOrders: loadOrders
        };
        const isEmpty = document.querySelector('.order-list .ant-empty-image');
        if (isEmpty) {
            isEmpty.innerHTML = renderToString(<NotFoundOrder/>);
        }
    });

    useEffect(()=> {
        ref.current.loadOrders();
    },[period]);
    
    return (<List
        className="order-list"
        grid={{ gutter: 16, 
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1,
            xxl: 1
        }}
        pagination={pagination}
        dataSource={orders}
        header={<OrderFilter setPeriod={setPeriod} period={period} />}
        renderItem={(item,index) => (
        <OrderListItem
            language={props.language}
            period={period}
            setOrders={setOrders}
            setPag={setPag}
            item={item}
        />
        )}
    />);
}

export default OrderList;