import React, {useState, useEffect} from 'react';
import { Drawer, Card, Badge, } from 'antd';
import {dbMethods} from '../../../firebase/dbmethods.js'
import sw from '../../../utils/multilingual.json'
import { isMobileScreen } from '../../../utils/mobile.js';
import Meta from 'antd/lib/card/Meta.js';
import { getConfigJson } from '../../../firebase/firebaseConfig.js';

const OrderPack = ({ language, visible, setVisible, setShoppingCart, onlyContent })=> {
    const [packs, setPacks] = useState([]);

    const handleSelectPack = (value) => {
        dbMethods.updateShoppingCart(
            null, 
            [], 
            value.isCooked, 
            value)
            .then(() => {
            setShoppingCart(prev => ({...prev, items: [], pack: value }));
            close()
        });
    }

    const close = () => {
        setVisible(false);
    }

    const Packs = () => <>
        <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, justifyContent: 'space-around'}}>
                {packs?.filter(f => f.isCooked).map((pack) => <Badge.Ribbon key={pack.name} text={sw.readyToEat[language]} color='#8d3251'><Card 
            className='packs-item'
            key={pack.name + 'card'}
            style={{maxWidth: 300, display: 'flex', alignItems: 'center', height: 170}}
            cover={<img alt="pack" src={`https://firebasestorage.googleapis.com/v0/b/box2020-d5d80.appspot.com/o/landing%2Fpacks%2F${pack.pic}?alt=media`} style={{maxHeight: 200}} />}
            onClick={() => handleSelectPack(pack)}
            hoverable >
                <Meta title={
                    <span style={{fontSize: isMobileScreen() ? 18 : 25, fontWeight: 'bold', color: '#8d3251'}}>
                    {`$${pack.price}`} <small>MXN</small>
                    </span>} 
                    className='packs-item-price'
                />
                <Meta title={<span>{pack.name}</span>} description={<span>{pack.description}</span>} />
        </Card></Badge.Ribbon>) ?? []}
            </div>
            <br></br>
        <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, justifyContent: 'space-around'}}>
                {packs?.filter(f => !f.isCooked).map((pack) => <Badge.Ribbon text={sw.ingredients[language]} color='green'><Card 
            className='packs-item'
            style={{maxWidth: 300, display: 'flex', alignItems: 'center', height: 170}}
            cover={<img alt="pack" src={`https://firebasestorage.googleapis.com/v0/b/box2020-d5d80.appspot.com/o/landing%2Fpacks%2F${pack.pic}?alt=media`} style={{maxHeight: 200}} />}
            onClick={() => handleSelectPack(pack)}
            hoverable >
                <Meta title={
                    <span style={{fontSize: isMobileScreen() ? 18 : 25, fontWeight: 'bold', color: '#8d3251'}}>
                    {`$${pack.price}`} <small>MXN</small>
                    </span>} 
                    className='packs-item-price'
                />
                <Meta title={<span>{pack.name}</span>} description={<span>{pack.description}</span>} />
        </Card></Badge.Ribbon>) ?? []}
        </div>
    </>

    useEffect(() => {
        getConfigJson('packs', setPacks);
    }, [visible]);

    return (onlyContent ? 
    <div>
        <h1 style={{marginLeft: '3vw', fontWeight: 'bold'}}>{sw.pack[language]}</h1>
        <Packs />
    </div> : 
    <Drawer 
        className='order-pack'
        title={<strong>{sw.selectPack[language]}</strong>}
        placement='bottom'
        closable={true}
        onClose={close}
        open={visible}
        key={'confirm_order'}
    >
        <Packs />
    </Drawer>)
}

export default OrderPack;