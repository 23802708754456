import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Input, Select, List, Button, Breadcrumb } from 'antd';
import sw from '../../utils/multilingual.json';
import { isMobile } from '../../utils/mobile';
import { dbMethods } from '../../firebase/dbmethods';
import TextArea from 'antd/lib/input/TextArea';
import './suggest-recipe-page.css';
import { AddRecipeIcon, TrashIcon } from '../../components/tlac-icon/TlacIcon';
import { useRef } from 'react';
const { Option } = Select;

const SuggestRecipePage = (props)=> {
    const [suggestion,setSuggestion] = useState({
        name:'',
        description:'',
        time:10,
        portions:1,
        dificulty:'Easy',
        categories:[],
        ingredients:[{qty:'',unit:'',name:''}],
        steps:''
    });

    const [pagination, setPagination] = useState({
        onChange: page => {
          console.log(page);
        },
        pageSize: isMobile? 4:6,
        showTitle:true
      });

    const [categories, setCategories] = useState([]);

    const margins = {
        time: {
            max:180,
            min:10
        },
        portions: {
            max:10,
            min:1
        }  
    };

    useEffect(()=> {
        dbMethods.getCategories().then(c=> setCategories(c));
    },[]);

    const changeQuantity = (index, isPlus)=> {
        if(isPlus && suggestion[index] < margins[index].max)
            setSuggestion(prev=> ({...prev,[index]:(prev[index] + (index === 'time'? 5:1))}));
        else if(!isPlus && suggestion[index] > margins[index].min)
            setSuggestion(prev=> ({...prev,[index]:(prev[index] - (index === 'time'? 5:1))}));
    }

    const handleChangeDificulty = value=> {
        setSuggestion(prev=> ({...prev,dificulty:value}))
    }

    const handleChangeText = (name,value)=> {
        setSuggestion(prev=> ({...prev,[name]:value}))
    }

    const selectCategory = item=> {
        const index = categories.findIndex(f=> f.name[props.language] === item.name[props.language]);
        let newArr = [].concat(categories); 
        newArr[index]['selected'] = newArr[index].selected? false:true; 
        setCategories(newArr);
        setPagination(prev=> prev);
        setSuggestion(prev=> ({...prev,categories: categories.filter(f=> f.selected).map(item=> item.name.english)}));
    }

    const addIngredient = ()=> {
        setSuggestion(prev=> ({...prev,ingredients:[...prev.ingredients,{qty:'',unit:'',name:''}]}))
    }

    const removeItem = (listName)=> {
        let items = [].concat(suggestion[listName]);
        items.pop();
        setSuggestion(prev=> ({...prev,[listName]:items})); 
    }

    const changeIngredient = (listName, index, field, value)=> {
        let items = [].concat(suggestion[listName]);
        items[index][field] = value;
        setSuggestion(prev=> ({...prev,[listName]:items}));
        console.log(suggestion);
    }

    const changeSteps = value=> {
        suggestion.steps = value;
    }

    const uploadSuggestion = ()=> {
        dbMethods.uploadSuggestion(suggestion);
    }

    const ref = useRef();

    useEffect(() => {
        ref.current = {
            language: props.language
        };
    });

    useEffect(() => {
        document.title = `TLAC | ${sw.sendRecipe[ref.current.language]}`;
    }, []);

    return (
        <div className='suggest-recipe-page'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item href='/'>TLAC</Breadcrumb.Item>
                <Breadcrumb.Item><AddRecipeIcon size={20} /> {sw.sendRecipe[props.language]}</Breadcrumb.Item>
            </Breadcrumb>
            <div className='suggest-recipe-page__form'>
                <div id="basic">
                    <div className="form-input">
                        <label>{sw.name[props.language]}:</label>
                        <Input onKeyUp={elem=> handleChangeText(elem.currentTarget.name,elem.currentTarget.value)} name="name" placeholder={sw.recipeName[props.language]} />
                    </div>

                    <div className="form-input">
                        <label>{sw.description[props.language]}:</label>
                        <Input onKeyUp={elem=> handleChangeText(elem.currentTarget.name,elem.currentTarget.value)} name="description" placeholder={sw.description[props.language]}/>
                    </div>
                    <div className="form-input">
                        <label>{sw.time[props.language]} (min):</label>
                        <Input
                            disabled
                            value={suggestion.time && suggestion.time}
                            className="select-quantity"
                            addonBefore={<Button className="left-btn" onClick={()=> changeQuantity('time',false)} size="small" type="text"> - </Button>}
                            addonAfter={<Button className="right-btn" onClick={()=> changeQuantity('time',true)} size="small" type="text"> + </Button>}
                        />
                    </div>
                    
                    <div className="form-input">
                        <label>{sw.portions[props.language]}:</label>
                        <Input
                            disabled
                            value={suggestion.portions && suggestion.portions}
                            className="select-quantity"
                            addonBefore={<Button className="text-center" onClick={()=> changeQuantity('portions',false)} size="small" type="text"> - </Button>}
                            addonAfter={<Button className="text-center" onClick={()=> changeQuantity('portions',true)} size="small" type="text"> + </Button>}
                        />
                        {/* <div className="counter counter-color text-center"><button onClick={()=> changeQuantity('portions',false)}>-</button> <label>{suggestion.portions && suggestion.portions}</label> <button onClick={()=> changeQuantity('portions',true)}>+</button></div> */}
                    </div>
                    <div className="form-input">
                        <label>{sw.dificulty[props.language]}:</label>
                        <Select defaultValue={sw.easy[props.language]} onChange={handleChangeDificulty}>
                            <Option value={sw.easy[props.language]}>{sw.easy[props.language]}</Option>
                            <Option value={sw.medium[props.language]}>{sw.medium[props.language]}</Option>
                            <Option value={sw.hard[props.language]}>{sw.hard[props.language]}</Option>
                        </Select>
                    </div>
                </div>
                <List
                    id="categories"
                    style={{padding:'10px'}}
                    grid={{ gutter: 16, 
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 2,
                        xl: 3,
                        xxl: 3
                    }}
                    pagination={pagination}
                    dataSource={categories}
                    header={<label className="highlighted-text text-bold title">{sw.categories[props.language]}</label>}
                    renderItem={(item,index) => (
                    <List.Item onClick={()=> selectCategory(item)} className={'diet-item '.concat(item.selected && 'selected')}>
                            {item.name[props.language]}
                    </List.Item>
                    )}
                />
                <List
                    id='ingredients'
                    size="small"
                    header={<div className='ingredient-header'>
                        <label className="ingredient-header__title">{sw.ingredients[props.language]}</label>
                        <div className='ingredient-header__actions'>
                            <Button id="remove-button" onClick={()=> removeItem('ingredients')}><TrashIcon size={18} /> {sw.remove[props.language]}</Button> 
                            <Button id="add-button" onClick={addIngredient} type="primary">+ {sw.add[props.language]}</Button>
                        </div>
                    </div>}
                    bordered
                    dataSource={suggestion.ingredients}
                    renderItem={(item, index) => <List.Item className='ingredient-item'>
                            <div className='form-input'>
                                <label>{sw.quantity['spanish']}:</label>
                                <Input type="text" onKeyUp={elem=> changeIngredient('ingredients',index,'qty',elem.currentTarget.value)} />
                            </div>
                            <div className='form-input'>
                                <label>{sw.unit['spanish']}:</label>
                                <Input type="text" onKeyUp={elem=> changeIngredient('ingredients',index,'unit',elem.currentTarget.value)}/>
                            </div>
                            <div className='form-input'>
                                <label>{sw.name['spanish']}:</label>
                                <Input type="text" onKeyUp={elem=> changeIngredient('ingredients',index,'name',elem.currentTarget.value)}/>
                            </div>
                    </List.Item>}
                />
                
                <Card id="steps" hoverable>
                    <Row>
                        <Col span={24}>
                            <label className="highlighted-text title text-bold margin-left-10">{sw.steps[props.language]}</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <TextArea 
                                placeholder={'1. '.concat(sw.steps[props.language],' 1\n2. ',sw.steps[props.language],' 2\n. . . . . .')} 
                                rows={3}
                                onKeyUp={elem=> changeSteps(elem.currentTarget.value)}
                                ></TextArea>
                        </Col>
                    </Row>
                </Card>
                <Card id="actions" className="text-center">
                    <Button 
                        size="large" 
                        type="primary" 
                        className="suggest-button"
                        onClick={uploadSuggestion}
                    >
                        {sw.sendRecipe[props.language]}
                    </Button>
                </Card>
            </div>
            
        </div>
    )
};

export default SuggestRecipePage;