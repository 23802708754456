import React from 'react';
import { Space, Row, Col, List, Avatar } from 'antd';
import sw from '../../../utils/multilingual.json'
import { getImageURL } from '../../../firebase/firestore-images';


const OrderDetails = ({language, scheduled, created, deliverHour, recipes, subtotal, delivered, deliveryDays})=> {

    return (
        <div className='order-details'>
            <div className="order-details__date">
                <span>{sw.created[language]}:</span>
                <span>{created}</span>
            </div>
            {!delivered && <div className="order-details__date">
                <span>{sw.scheduled[language]}:</span>
                <span>{scheduled.toDate().toLocaleDateString()}</span>
            </div>}
            <div className='order-details__date'>
                <span>{sw.deliveryTime[language]} aprox. </span>
                <span style={{width: 'max-content'}}>{deliverHour}</span>
            </div>
            <List
                className='order-details-list'
                dataSource={recipes}
                pagination={{
                    pageSize: 2,
                    responsive: true,
                    showLessItems: true
                }}
                renderItem={(item, i)=> (
                    <List.Item key={`${item.recipe_id}-${i}`} className='order-details-list-item'>
                        <List.Item.Meta
                            avatar={<Avatar alt='prepared recipe' src={getImageURL(item.itemkey, 'jpg', 'recipe-pic-small')} size={30} shape='circle' />}
                            title={<div>
                                <Row>
                                    <Col span={18}>
                                        <Space direction="vertical">
                                            <label className="order-details-list-item__name" onClick={()=> window.location.href = '/recipe/'.concat(item.itemkey)}>{item?.metadata?.name[language]}</label>
                                        </Space>
                                    </Col>
                                </Row>
                            </div>}
                            description={<span>{deliveryDays?.some(f => f.index === item.day && f.isDelivered) ? sw.delivered[language] : sw.scheduled[language]}: {deliveryDays?.find(f => f.index === item.day)?.date?.toDate().toLocaleDateString()}</span>}
                        />
                    </List.Item>
                )}
            footer={<div className='order-details-footer'>
                <label className="order-details-footer__total">Total ${Number(subtotal ?? 0).toFixed(2)}</label>
            </div>}
            />
        </div>
    );
};

export default OrderDetails;