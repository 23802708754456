import React from 'react';
import '../../pages/orders-page/orders-page.css';
import OrderList from '../../components/order/order-list/OrderList.js';
import { Breadcrumb } from 'antd';
import sw from '../../utils/multilingual.json'; 
import { BagsIcon } from '../../components/tlac-icon/TlacIcon';
import { useEffect } from 'react';
import { useRef } from 'react';

const OrdersPage = (props)=> {

    const ref = useRef();

    useEffect(() => {
        ref.current = {
            language: props.language
        };
    });

    useEffect(() => {
        document.title = `TLAC | ${sw.myOrders[ref.current.language]}`;
    }, []);

    return (<div className='orders-page'>
        <Breadcrumb className='breadcrumb'>
            <Breadcrumb.Item href='/'>TLAC</Breadcrumb.Item>
            <Breadcrumb.Item><BagsIcon size={20} /> {sw.myOrders[props.language]}</Breadcrumb.Item>
        </Breadcrumb>
        <OrderList language={props.language} />
    </div>)
};

export default OrdersPage;