// import React from 'react';
// import { /*Calendar,*/ message/*, TimePicker*/ } from "antd";
// import confirm from "antd/lib/modal/confirm";
// import moment from "moment";
// import sw from '../../utils/multilingual.json';
import { fa } from '../../firebase/analytics';
// import { pay } from '../../pages/payment-page/payment-methods';
import { dbMethods } from '../../firebase/dbmethods';

// let setShoppingCart = null;
// let setPage = null;
export let userId = null;
// let lang = 'english';

// export const initShoppingCart = (setData, setPag, uId, language) => {
//     setShoppingCart = setData;
//     // setPage = setPag;
//     userId = uId;
//     lang = language;
//     if (!localStorage.getItem('dd'))
//         dbMethods.getDeliveryDays()
// }

// export const getShoppingCart = (cartItems = []) => {
//     const recipes = JSON.parse(localStorage.getItem('recipes'));

//     const cartData = (cartItems ?? [])
//     .map(item=> {
//         let d= item;
//         if(recipes && recipes.length) {
//             const metadata = recipes.find(f=> f.key === item.key);
//             if(metadata)
//                 d['metadata'] = {
//                     name: metadata.name
//                 };
//             return d;
//         }
        
//         d['metadata'] = {
//             name: {
//                 english:'unkwown',
//                 spanish:'desconocido'
//             }
//         };

//         return d;
//     })
//     return cartData; 
// }

export const changeQuantity = 
    async (key, day, value, shoppingCart) => {
        console.log(shoppingCart)
        console.log(day)
        console.log(key)
        console.log(value)
    if (value > 0 && value < 25) {
        console.log(key, day)
        const index = shoppingCart.findIndex(f=> f.key === key && f.day === day);
        if (index > -1) {
            shoppingCart[index].qty = parseInt(value);
            await dbMethods.updateShoppingCart(userId, shoppingCart);
            return shoppingCart;
        }
    }
    return null;
};

export const removeItem = async (key, dayIndex, shoppingCart) => {
    const index = shoppingCart.findIndex(f=> f.key === key && f.day === dayIndex);
    
    shoppingCart.splice(index, 1);
    await dbMethods.updateShoppingCart(userId, shoppingCart);
    return shoppingCart;
};

export const createOrder = async (
    selectedAddress, 
    shoppingCart, 
    selectedHour,
    setLoading,
    lang,
    close) => {
    setLoading(true);
    // calculating days
    const sc = {...shoppingCart};
    sc.items = Object.values(shoppingCart.pack.days).reduce((a, b) => a.concat(b), []);
    sc.items = sc.items.map((item) => {
        const newItem = { ...item, key: item.itemkey };
        delete newItem.itemkey;
        return newItem;
    });
    const {mealpreps, snacks} = sc.pack;
    sc.pack = null;
    const deliveryDays = (JSON.parse(localStorage.getItem('dd')) ?? {days: [4, 5, 6, 0]}).days
    const dayOfWeek = (new Date()).getDay();
    const nextEnabledDay = deliveryDays.includes(dayOfWeek) ?
    deliveryDays[0] :
    deliveryDays.find(day => day > dayOfWeek) || deliveryDays[0];
    let daysUntilNextWeek = (7 - dayOfWeek + nextEnabledDay) % 7;
    if (daysUntilNextWeek < ((deliveryDays?.minGap ?? 3) + 1)) daysUntilNextWeek = deliveryDays?.minGap ?? 3;
    const week = (Array.from({length: 7}).reduce((days, _, index) => {
        const currentDay = new Date();
        currentDay.setDate(currentDay.getDate() + daysUntilNextWeek + index);
        const isDisabled = !deliveryDays.includes(currentDay.getDay());
        if (!isDisabled && (sc?.items ?? []).some(s => s.day === currentDay.getDay())) 
            days.push({date: currentDay, index: currentDay.getDay(), isDelivered: false});
        return days
    }, []));

    // fill new order
    let newOrder = {
        address: selectedAddress,
        dateTime: new Date(),
        delivered: null,
        is_canceled: false,
        order_code: null,
        isMealkit: sc.isMealkit,
        totalQty: getShoppingcartQty(sc?.items ?? []),
        order: sc?.items ?? [],
        deliveryDays: week,
        selectedHour: selectedHour,
        mealpreps,
        snacks,
        scheduled: week[0].date
    };
    console.log(newOrder);
    await dbMethods.createOrder(newOrder, lang)
    .then(() => {
        sc.items.forEach(item => {
            fa(`shop-${item?.metadata?.name?.english}`);
        });
    })
    .catch(() => {
        setLoading(false);
        close()
    });
}

// export const completeOrder = (
//     selectedAddress, 
//     isMealkit,
//     data,
//     setLoading
//     ) => {
//     // check if there is an address
//     if (!selectedAddress) {
//         message.warning(sw.pleaseSelectAddress[lang]);
//         return;
//     }
//     // check if there are items inside the shopping cart
//     // const data = getShoppingCart(userId, filterCheck.readyToEat, filterCheck.toPrepare);
//     let qty = data.length > 1 ? getShoppingcartQty(data): data[0].qty;
//     if (qty < 3) {
//         return;
//     }

//     setZIndex();

//     const deliveryDays = JSON.parse(localStorage.getItem('dd')) ?? {days: [4, 5, 6, 0]};

//     let selectedDate = null;
//     let selectedHour = deliveryDays?.hours?.length > 0 ? `${deliveryDays.hours[0]?.init}:00 - ${deliveryDays.hours[0]?.end}:00`: '';
//     let daysQty = 1;
    
//     const getEnabledDaysOfWeek = enabledDays => {
//         let today = new Date();
//         const currentDay = today.getDay();
    
//         const nextEnabledDay = enabledDays.includes(currentDay) ?
//             enabledDays[0] :
//             enabledDays.find(day => day > currentDay) || enabledDays[0];
    
//         let daysUntilNextWeek = (7 - currentDay + nextEnabledDay) % 7;
//         if (daysUntilNextWeek < ((deliveryDays?.minGap ?? 3) + 1)) daysUntilNextWeek = deliveryDays?.minGap ?? 3;

//         return Array.from({ length: 14 }, (_, index) => {
//             const currentDay = new Date(today);
//             currentDay.setDate(today.getDate() + daysUntilNextWeek + index);
//             const isDisabled = !enabledDays.includes(currentDay.getDay())
//             const selectItem = (item) => {
//                 selectedDate = currentDay;
//                 const currentContainer = document.querySelector('.shopping-cart-calendar-week');
//                 currentContainer.classList.remove('show-optional');
//                 const okBtn = document.querySelector('.ant-modal-confirm-btns .ant-btn.ant-btn-primary');
//                 okBtn?.classList.remove('disabled');
//                 const items = document.querySelectorAll('.shopping-cart-calendar-week-item:not(.disabled)')
//                 if (items) {
//                     let selectedCount = 0;
//                     items.forEach((citem, cindex) => {
//                         if (Number(item.classList[1]) <= Number(citem.classList[1]) && selectedCount < daysQty) {
//                             citem.classList.add('selected');
//                             selectedCount++;
//                             if (citem.classList.contains('optional'))
//                                 citem.parentElement.classList.add('show-optional')
//                         }
//                         else {
//                             citem.classList.remove('selected');
//                         }
//                     });
//                 }
//             }

//             return <button
//                 key={index}
//                 onClick={(e) => selectItem(e.currentTarget)} 
//                 className={`shopping-cart-calendar-week-item ${index} ${(isDisabled ? 'disabled' : '')} ${(index > 6 ? 'optional' : '')}`} 
//                 disabled={isDisabled || index > 6}
//                 >
//                 <span className='shopping-cart-calendar-week-item__day'>{sw.weekdays[currentDay.getDay()][lang]}</span><br></br>
//                 <span className='shopping-cart-calendar-week-item__date'>
//                 {currentDay.toLocaleDateString(lang === 'english' ? 'en-US' : 'es-ES', { month: 'long' })} {currentDay.getDate()}
//                 </span>
//             </button>
//         });
//     };
    

//     const enabledDays = getEnabledDaysOfWeek(deliveryDays.days)

//     const onChangeDays = (value) => {
//         selectedDate = null;
//         const okBtn = document.querySelector('.ant-modal-confirm-btns .ant-btn.ant-btn-primary');
//         okBtn?.classList.add('disabled');
//         const items = document.querySelectorAll('.shopping-cart-calendar-week-item.selected');
//         items.forEach(item => item.classList.remove('selected'));
//         daysQty = Number(value);
//         const currentContainer = document.querySelector('.shopping-cart-calendar-week-container');
//         currentContainer.classList.remove('show-optional');
//     }

//     const onChangeHour = (e) => {
//         e.parentElement.childNodes.forEach(e => e.classList.remove('selected'))
//         e.classList.add('selected');
//         selectedHour = e.textContent;
//     }

//     confirm({
//         className:'shopping-cart-calendar',
//         title:<label className='shopping-cart-calendar-title'>{sw.deliveryTime[lang]}</label>,
//         content:
//             <div className='shopping-cart-calendar-week'>
//                 <span>{sw.selectDeliveryDate[lang]}</span>
//                 <br></br>
//                 <div>
//                     <span>{sw.dayToSplitOrder[lang]}</span>
//                     <select onChange={(e) => onChangeDays(e.currentTarget.value)}>
//                         {deliveryDays.days.map((day, index) => <option key={index} value={index + 1}>{index + 1} {sw.day[lang]}{(index === 0 ? '' : 's')}</option>)}
//                     </select>
//                 </div>
//                 <div className='shopping-cart-calendar-week-container'>
//                 {enabledDays}
//                 </div>
//                 <span>{sw.deliveryTime[lang]}:</span>
//                     <br></br>
//                 <div className='shopping-cart-calendar-week-hours'>
//                     {deliveryDays?.hours?.map((hour, index) => 
//                         <button 
//                             index={index}
//                             onClick={(e) => onChangeHour(e.currentTarget)}
//                             className={`shopping-cart-calendar-week-hours__hour ${(index === 0 ? 'selected' : '')}`}>
//                                 {hour?.init}:00 - {hour?.end}:00
//                         </button>)}
//                 </div>
//             </div>, 
//         onOk(close) {
//             const okBtn = document.querySelector('.ant-modal-confirm-btns .ant-btn.ant-btn-primary');
//             if (!okBtn?.classList.contains('disabled')) {
//                 let newOrder = {
//                     address: selectedAddress,
//                     dateTime: new Date(),
//                     delivered: null,
//                     is_canceled: false,
//                     order_code: null,
//                     isMealkit: isMealkit,
//                     totalQty: qty,
//                     scheduled: selectedDate,
//                     days: daysQty,
//                     selectedHour: selectedHour
//                 };
//                 console.log('pressed ok')
//                 dbMethods.createOrder(newOrder, lang);
//                 data.forEach(item => {
//                     fa(`shop-${item?.metadata?.name?.english}`);
//                 });
    
//                 setLoading(true);
//                 close()
//             }
//             else {
//                 message.warning(sw.selectDeliveryDate[lang]);
//             }
//         },
//         okButtonProps: { className: 'disabled' },
//         onCancel() {
//         console.log('Cancel');
//         },
//         okText: sw.ok[lang],
//         cancelText: sw.cancel[lang]
//     });
// }

// export const setupDeliveryDays = () => {
//     const deliveryDays = JSON.parse(localStorage.getItem('dd')) ?? {days: [4, 5, 6, 0]};
//     let selectedHour = deliveryDays?.hours?.length > 0 ? `${deliveryDays.hours[0]?.init}:00 - ${deliveryDays.hours[0]?.end}:00`: '';
//     const onChangeHour = (e) => {
//         e.parentElement.childNodes.forEach(e => e.classList.remove('selected'))
//         e.classList.add('selected');
//         selectedHour = e.textContent;
//     }

//     const onSelectDay = (e) => {

//     }

//     confirm({
//         className:'delivery-days',
//         title:<label className='delivery-days-title'>{sw.deliveryTime[lang]}</label>,
//         content:
//         <>
//         <div className='delivery-days-week'>
//             {Array.from({length: 7}).map((item, index) => {
//             const currentDay = new Date();
//             currentDay.setDate(currentDay.getDate() - currentDay.getDay() + index);
//             const isDisabled = !deliveryDays.days.includes(index);
//             return <button
//                 key={index}
//                 onClick={(e) => onSelectDay(e.currentTarget)} 
//                 className={`delivery-days-week-item ${index} ${(isDisabled ? 'disabled' : '')}`} 
//                 disabled={isDisabled}
//                 >
//                 <span className='delivery-days-week-item__day'>{sw.weekdays[currentDay.getDay()][lang]}</span><br></br>
//             </button>
//             })}
//         </div>
//         <div className='delivery-days-week-hours'>
//             {deliveryDays?.hours?.map((hour, index) => 
//             <button 
//                 index={index}
//                 onClick={(e) => onChangeHour(e.currentTarget)}
//                 className={`delivery-days-week-hours__hour ${(index === 0 ? 'selected' : '')}`}>
//                     {hour?.init}:00 - {hour?.end}:00
//             </button>)}
//         </div>
//     </>,
//     onOk: (close) => {

//     },
//     onCancel: () => {
        
//     }
//     })
// }

export const getDeliveryDays = () => {

}

// const setZIndex = e=> {
//     document.getElementsByClassName('react-modal-sheet-container')[0].parentElement.style.zIndex="1000";
// }

export const getShoppingcartQty = data => data?.length ? data.reduce((a,b)=> (isNaN(a)? a.qty:a) + b.qty) : 0;