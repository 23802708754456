import { Image, Button, Breadcrumb} from 'antd';
import React, { useState, useEffect,useRef} from 'react';
import { dbMethods } from '../../firebase/dbmethods.js';
import Information from '../../components/user/user-info/Information.js';
import Address from '../../components/user/user-address/Address.js';
import Health from '../../components/user/user-health/Health.js';
import { UploadIcon } from '../../components/tlac-icon/TlacIcon.js';
import sw from '../../utils/multilingual.json';
import './user-profile-page.css';
import { getImageURL } from '../../firebase/firestore-images.js';

const Profile = (props)=> {

    const [profile,setProfile] = useState({
        uid:undefined,
        name:'',
        pic: require('../../pics/logo-simple.png').default,
        email:'',
        phoneNumber:'',
        password:''
    });

    const [isOpened,setOpened] = useState({
        info: false,
        address: false,
        health: false
    });

    const [health,setHealth] = useState({
        diet: 'all',
        size: '0.0',
        weight: '0'                            
    });

    const [addresses,setAddresses] = useState([]);

    const [selectedAddress,setSelectedAddress] = useState(null);

    const ref = useRef();

    useEffect(()=> {
        ref.current = props.language;
    });

    useEffect(()=> {
        dbMethods.getUserData(setProfile,setAddresses,setSelectedAddress, setHealth);
    },[])

    const openFilePicker = ()=> {
        document.getElementById('imgupload').click();
    }

    const changePic = e=> {
        const docs = document.getElementById('imgupload').files;
        const type = docs[0].type;
        const reader = new FileReader();
        reader.readAsDataURL(docs[0]);
        reader.onload = () =>  dbMethods.uploadProfilePic(reader.result.split(',')[1],type).then(r=> setProfile(prev=> ({...prev,pic:reader.result})));
        reader.onerror = error => console.log(error);
    }

    const LoadProfileContent = ()=> {
        if(profile.uid)
            return (<div className="profile-content">
            <Information
                profile={profile}
                language={ref.current}
                setProfile={setProfile}
                expand={isOpened}
                setOpened={()=> setOpened(prev=> ({...prev,
                                                    info:!prev.info,
                                                    address:false,
                                                    health:false
                                                }))}
            />
            <Address
                language={ref.current}
                setProfile={setProfile}
                expand={isOpened}
                addresses={addresses}
                selectedAddress={selectedAddress}
                setOpened={()=> setOpened(prev=> ({...prev,
                                                    address:!prev.address,
                                                    info: false,
                                                    health:false
                                                }))}
                setSelectedAddress={setSelectedAddress}
                setAddresses={setAddresses}
            />
            <Health 
                expand={isOpened}
                language={ref.current}
                setOpened={()=> setOpened(prev=> ({...prev,
                    address:false,
                    info: false,
                    health:!prev.health
                }))}
                uid={profile.uid}
                setHealth={setHealth}
                health={health}
            />
            </div>)

        return (<div></div>);
    }


    return (<div className="user-profile-page">
        <Breadcrumb className='breadcrumb'>
            <Breadcrumb.Item href='/'>TLAC</Breadcrumb.Item>
            <Breadcrumb.Item>{sw.profileTitle[props.language]}</Breadcrumb.Item>
        </Breadcrumb>
        <div
        className="user-profile-card"
        style={{background: `url(${getImageURL('purplepaint','jpg','app')})`}}
        >
            <div className="user-profile-card__cover">
                <div className='profile-pic-container'>
                    <Image className="user-profile-card__cover__profile-pic" alt="profile-pic" src={profile?.pic ?? require('../../pics/logo-simple.png').default} />
                    <span className='user-profile-card__cover__name'>{profile.name}</span>
                    <LoadProfileContent />
                </div>
                <Button onClick={openFilePicker} type='text' className="user-profile-card__cover__upload">
                    <UploadIcon />
                </Button>
            </div>
            <input type="file" onChange={changePic} id="imgupload" style={{display:'none'}}/> 
        </div>
    </div>);
};

export default Profile;