import React, {useState, useEffect, useRef} from 'react';
import { ArrowIcon, RecipeFeatureIcon } from '../../tlac-icon/TlacIcon';
import { isMobileScreen } from '../../../utils/mobile.js';

const AccordionList = (props) => {
    const [selectedItem, setSelectedItem] = useState(isMobileScreen()? -1 : 0);
    const fixed = useRef();

    const clickOnArrow = (index) => {
        setSelectedItem(isMobileScreen() && selectedItem === index ? -1 : index);
    }
    
    useEffect(() => {
        fixed.current = {
            accordionCount: props.accordionItems?.length
        };
    });
    
    useEffect(()=> {
        let root = document.documentElement;
        root.style.setProperty('--accordion-count', fixed.current.accordionCount - 1);
    }, []);

    return (<div className='accordion-list'>
        {props.accordionItems.map((item, index) => 
            <div key={index} className={`accordion-list-item ${selectedItem === index ? 'selected':''}`}>
                <span className='accordion-list-item-header' onClick={() => clickOnArrow(index)} >
                    {item.title} 
                    <ArrowIcon 
                        class_name={selectedItem === index ? 'hidden' : ''} 
                        orientation={'bottom-right'} 
                    />
                </span>
                <div 
                    className={`accordion-list-item-body ${selectedItem !== index || selectedItem === -1 ? 'hidden' : ''}`}
                >
                    {item.items.map((listItem, listIndex) => 
                    <div key={listIndex} className='accordion-list-item-body__item'>
                        <RecipeFeatureIcon type={item.icon} number={listIndex + 1} />
                        <span className='accordion-list-item-body__item-text'>{listItem}</span>
                    </div>)}
                </div>
            </div>
        )}
    </div>);
}

export default AccordionList;