import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import NotFoundRecipe from '../../components/error/NotFoundRecipe.js';
import { dbMethods } from '../../firebase/dbmethods.js';
import sw from '../../utils/multilingual.json'; 
import RecipeCard from '../../components/recipe/card/RecipeCard.js';
import './recipe-page.css';
import AccordionList from '../../components/recipe/accordion-list/AccordionList.js';
import InstagramPost from '../../components/instagram/InstagramPost.js';
import {Breadcrumb} from 'antd'

const RecipePage = (props) => {
    const [instagramPosts, setInstagramPosts] = useState([]);
    const [recipe, setRecipe] = useState('loading');
    const [isLiked, setLiked] = useState(false);
    const {id} = useParams();
    const fixed = useRef();

    const getNutritionalTable = ()=> ({
        title: sw.nutritionalTable[props.language],
        icon: 'nutritional',
        items: Object.keys(recipe?.nutrition ?? {}).map(key => 
            `${sw[key] && sw[key][props.language]} : ${recipe.nutrition[key].qty}${recipe.nutrition[key].unit}`) ?? []
    })
    
    const getIngredientList = ()=> ({
        title: sw.ingredients[props.language],
        icon: 'ingredient',
        items: recipe?.quantities?.map(item => 
            `${item?.quantity} ${item?.unit[props.language]} ${sw.of[props.language]} ${item?.ingredient?.name[props.language]}`) ?? []
    });

    const getStepsList = ()=> ({
        title: sw.steps[props.language],
        icon: 'step',
        items: recipe?.steps?.map(item => 
            item?.description[props.language] ?? '') ?? []
    });

    const accordionItems = [
        getIngredientList(),
        getStepsList(),
        getNutritionalTable()
    ];

    useEffect(()=> {
        fixed.current = {
            id:id,
            language:props.language
        };
    });

    useEffect(()=> {
        document.title = `TLAC | ${fixed.current.id}`;
        dbMethods.getRecipe(fixed.current.id, 
                            setRecipe, 
                            fixed.current.language);
        dbMethods.isLiked(fixed.current.id,setLiked);
        dbMethods.getInstagramPosts(fixed.current.id, setInstagramPosts);
        setInstagramPosts(prev=>  [...[]]);
    },[]);

    if (recipe === 'loading')
        return <div className='recipe-page' style={{height: 'calc(100vh - 100px)'}} />;
    else if (recipe !== 'NotFound') {
        return (<div className='recipe-page' style={{height: 'calc(100vh - 100px)'}}>
            {/* Start banner */}
            {/* <Banner link="#" src="" /> */}
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item href='/'>TLAC</Breadcrumb.Item>
                <Breadcrumb.Item>{recipe.name[fixed.current.language]}</Breadcrumb.Item>
            </Breadcrumb>
            {/* Start recipe content */}
            <div className='recipe-info content'>
                <div className='recipe-info-basic'>
                    <RecipeCard 
                        recipe={recipe} 
                        isLiked={isLiked} 
                        setLiked={setLiked} 
                        // readyToEat={readyToEat} 
                        // setReadyToEat={setReadyToEat} 
                        language={fixed.current.language}
                        setRecipe={setRecipe}
                        // setDisableLike={setDisableLike}
                    />
                    <span className='recipe-info-basic__description'>
                        {recipe.description[fixed.current.language]}
                    </span>
                </div>
                <div className='recipe-info-advanced'>
                    <AccordionList 
                    accordionItems={accordionItems} />
                </div>
            </div>

            {/* Start instagram posts */}
            <div className='instagram-wall content'>
                {instagramPosts.map(post => <InstagramPost postId={post} />)}
            </div>
    </div>);
    }
    else
        return (<NotFoundRecipe />);
}

export default RecipePage;