import { Button, List, Popover, Switch } from "antd";
import React from "react";
import { DotsIcon, MapPinIcon } from "../../tlac-icon/TlacIcon";
import sw from '../../../utils/multilingual.json';

const AddressInfoListItem = (props) => {
    return (<List.Item key={props.index} className='address-info-list-item'>
    <MapPinIcon />
    <div className="address-info-list-item__data">
        <span className="address-info-list-item__data-address">{props.item.address}</span>
        <span className="address-info-list-item__data-reference">{props.item.reference}</span>
    </div>
    <div className='address-info-list-item__actions'>
        <Switch 
            onChange={val=> props.checkAddress(val,props.item)} 
            disabled={props.item.selectedDate===props.selectedAddress.selectedDate} 
            checked={props.item.selectedDate===props.selectedAddress.selectedDate}
        />
        <Popover 
            content={
                <Button 
                    type="text" 
                    onClick={()=> props.removeAddress(props.item.selectedDate)}
                >
                    {sw.remove[props.language]}
                </Button>} 
            trigger="click"
            placement="bottom"
        >
            <Button 
                type='text' 
                shape={`circle`}
                className={`options-btn ${props.item.selectedDate===props.selectedAddress.selectedDate? 'hidden':''}`}
            >
                <DotsIcon dark={true} size={20} />
            </Button>
            </Popover>
    </div>
</List.Item>);
}

export default AddressInfoListItem;