import React from 'react';
import sw from '../../utils/multilingual.json';


const NotFoundRecipe = (props)=> {
    const language = localStorage.getItem('language') ?? ((window.navigator.userLanguage || window.navigator.language).split('-')[0] === 'es'? 'spanish':'english');
    return (<div className="orders-not-found">
        <svg id="not-found-orders-chef" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
            <path className="cls-3" d="M14.37,6.57c.54-.27,1.26-.56,2.14-.76,1.14-.25,2.13-.25,2.84-.19v-.43c.06-.04,.9-.68,1.2-1.21,.06-.11,.16-.29,.16-.54,0-.14-.04-.25-.06-.32-.03-.07-.07-.14-.12-.21-.21-.3-.52-.41-.67-.45l-.79,.66,.44-.88-1.51-.63-.5,.76,.13-.88s-1.07-.19-2.08,.13-1.13,.63-1.13,.63l.5,.63s-.57-.19-.82-.44l-1.13,1.07,.69,.63s-.38,.06-.95-.32c-.07,.06-.38,.31-.44,.76-.01,.09-.05,.4,.13,.69,.19,.31,.52,.42,.76,.5,.13,.04,.2,.05,.63,.19,.16,.05,.27,.09,.38,.19,.11,.11,.16,.23,.19,.32l.03,.09Z"/>
            <path className="cls-3" d="M19.31,5.84c-.67-.03-1.53,0-2.52,.19-.98,.19-1.79,.48-2.39,.76,0,0,.06,.06,.13,.5s.06,.82,.06,.82c.38-.26,.93-.58,1.64-.82,.93-.31,1.74-.34,2.27-.32,.4,.02,.8,.04,1.2,.06-.04-.11-.08-.21-.13-.32l-.19-.57h0c-.02-.11-.04-.21-.06-.32Z"/>
            <path className="cls-3" d="M14.71,8.43c.73-.49,1.39-.71,1.83-.82,.7-.18,1.29-.18,1.89-.19,.54,0,1,.03,1.32,.06,.12,.2,.29,.52,.38,.95,.05,.24,.17,.87-.13,1.58-.26,.63-.71,.96-.95,1.13-.17,.12-.53,.38-1.07,.5-.14,.03-1.23,.26-2.21-.44-.18-.13-.56-.43-.82-.95-.28-.55-.3-1.09-.32-1.39,0-.13,0-.24,0-.32v-.09s.06-.04,.06-.04Z"/>
            <path className="cls-1" d="M16.48,10.7c-.06-.02-.07-.13-.06-.19,0-.12,.09-.21,.19-.32,.05-.05,.15-.15,.32-.25,.08-.05,.21-.13,.38-.19,.33-.11,.62-.08,.76-.06,.2,.03,.46,.06,.69,.25,.02,.02,.18,.16,.25,.38,.02,.06,.04,.14,0,.19-.04,.04-.13,.03-.19,0-.07-.04-.07-.1-.13-.19-.02-.03-.06-.08-.25-.19-.18-.11-.27-.16-.38-.19-.34-.1-.76,.03-1.01,.25-.04,.04-.12,.11-.25,.25-.19,.2-.26,.27-.32,.25Z"/>
            <path className="cls-3" d="M20.41,19.44v1.08s.21,.89,.28,1.45c.05,.42,.03,1.13,.03,1.13v9.06c-.07,.77-.73,1.37-1.5,1.38-.78,0-1.45-.6-1.52-1.38v-10.78h-.5v10.71c-.06,.83-.76,1.47-1.58,1.45-.79-.02-1.46-.65-1.51-1.45v-9.69c.02-.22,.05-.55,.13-.95,.08-.41,.14-.56,.19-.88,.04-.26,.09-.65,.06-1.13"/>
            <path className="cls-3" d="M14.52,17.11c0-.12,0-.25,0-.38,.01-.84,.11-1.62,.25-2.33l-.25-.06c-.06,.23-.1,.43-.13,.57-.07,.35-.17,1-.19,1.83,0,.16,0,.29,0,.38,.03,.03,.07,.08,.13,.13,.12,.09,.23,.12,.25,.13h.95s.06,0,.19,.06c.09,.04,.24,.11,.38,.25,.08,.08,.21,.22,.25,.44,.05,.28-.08,.5-.13,.57-.1,.17-.24,.26-.32,.32-.32,.21-.66,.23-1.13,.25-.19,0-.34,0-.44,0-.18-.11-.37-.23-.57-.38-.32-.23-.59-.47-.82-.69-.11-.11-.25-.28-.38-.5-.01-.02-.12-.21-.19-.44-.1-.31-.13-.68-.06-1.45,.05-.54,.12-1,.19-1.39,.08-.43,.14-.73,.25-1.13,.15-.5,.32-1.09,.76-1.7,.29-.41,.55-.62,.82-.76,.13-.06,.24-.1,.32-.13h.13s.08,.03,.13,.06c.05,.03,.07,.07,.13,.13,.03,.03,.07,.08,.13,.13,.1,.1,.25,.24,.44,.38,.23,.16,.85,.6,1.76,.63,.91,.03,1.56-.35,1.7-.44,.51-.32,.8-.72,.95-.95,.19,.06,.46,.17,.76,.38,.95,.67,1.22,1.77,1.51,3.02,.16,.69,.33,1.71,.32,2.96,0,0-.38,.76-.63,1.01s-.63,.63-1.07,.95-.63,.38-.63,.38c-.09,0-.22,0-.38,0-.21-.01-.44-.03-.69-.13-.22-.09-.52-.21-.63-.5-.11-.29,.03-.57,.06-.63,.2-.4,.63-.49,.69-.5,0,0,.18-.05,.38-.06,.25-.01,.74,0,.74,0,0,0,.05-.01,.05-.01,0,0,.03-.14,.22-.3l-.13-1.32-.19-1.51h-.38c.1,.42,.19,.91,.25,1.45,.06,.58,.08,1.11,.06,1.58"/>
            <path className="cls-3" d="M12.19,17.94c-.08,.2-.17,.5-.19,.88-.02,.41,.06,.74,.13,.95"/>
            <path className="cls-3" d="M12.73,18.54c0,.11-.01,.24,0,.38,.02,.23,.07,.42,.13,.57"/>
            <path className="cls-3" d="M22.24,18.48c.05,.08,.12,.24,.13,.44,.01,.32-.13,.55-.19,.63"/>
            <path className="cls-3" d="M22.81,18.04c.08,.15,.18,.42,.19,.76,.01,.54-.22,.92-.32,1.07"/>
            <path className="cls-3" d="M20.7,6.93l.79-.68-.05,.72h.73l-.42,.45,.49,.47s-.45,.25-.92,.22"/>
            <circle className="cls-2" cx="18.62" cy="13.61" r=".28"/>
            <circle className="cls-2" cx="16.29" cy="13.61" r=".28"/>
            <circle className="cls-2" cx="18.62" cy="16.26" r=".28"/>
            <circle className="cls-2" cx="16.29" cy="16.26" r=".28"/>
        </svg>
        <span className="error-message">{sw.noOrders[language]}</span>
    </div>);
};

export default NotFoundRecipe;