import React, {useState, useEffect} from 'react';
import { Input, Drawer, Button, Select, } from 'antd';
import {dbMethods} from '../../../firebase/dbmethods.js'
import sw from '../../../utils/multilingual.json'
// import { fa } from '../../../firebase/analytics.js';
import { MapPinIcon } from '../../tlac-icon/TlacIcon.js';
import { createOrder } from '../../shopping-cart/shopping-cart.js';
import { getConfigJson } from '../../../firebase/firebaseConfig.js';
const { Option } = Select;

const ConfirmOrder = ({ language, visible, setVisible, shoppingCart })=> {
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [deliveryHours, setDeliveryHours] = useState([]);
    const [selectedHour, setSelectedHour] = useState(0);
    const [loading, setLoading] = useState(false);
    const confirm = () => {
        const hour = deliveryHours[selectedHour];
        createOrder(selectedAddress, shoppingCart, `${hour?.init}:00 - ${hour?.end}:00`, setLoading, language, close);
    }

    const handleSelectAddress = (value) => {
        if (value === 'add') {
            document.location.href = '/new_address'; 
            return;
        }

        let address = addresses.find(f=> f.address === value);
        if(address){
            dbMethods.selectAddress(address.selectedDate, addresses, setAddresses, setSelectedAddress);
        }
    }

    const onChangeHour = (index, hour) => {
        setSelectedHour(index);
    }

    const getMapUrl = (lat, lng) => 
        `https://www.google.com.mx/maps/place/${lat},${lng}/@${lat},${lng},17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xc32720350d3a2f87!8m2!3d${lat}!4d${lng}`;

    const close = () => {
        setVisible(false);
    }

    useEffect(()=> {
        if (visible) {
            dbMethods.getAddresses(setAddresses, setSelectedAddress);
            getConfigJson('deliveryHours', setDeliveryHours)
        }
    },[visible]);

return (
    <Drawer
        className='confirm-order'
        title={sw.ConfirmOrder[language]}
        placement='bottom'
        closable={true}
        onClose={close}
        open={visible}
        key={'confirm_order'}
      >
        <Input.Group compact className='confirm-order-address'>
            <Select
                className='confirm-order-address-select'
                value={selectedAddress.address} 
                onChange={e => handleSelectAddress(e)}
            >
                {!addresses?.length ? <Option value=''></Option> : <></>}
                {/* add addresses */}
                {addresses.map((item, index)=> 
                    <Option value={item.address} key={`address${index}`}>
                        {item.address}
                    </Option>
                )}
                <Option value={'add'}>
                    {sw.addNewAddresses[language]}
                </Option>
            </Select>
            <Button 
                shape='circle' 
                className='see-google-maps'
                href={getMapUrl(selectedAddress?.lat ?? 0, selectedAddress?.lng ?? 0)}
                target='_blank'
            >
                <MapPinIcon dark={true} size={20} />
            </Button>
        </Input.Group>
        <div className='confirm-order-hour'>
        <strong>{sw.lunchHours[language]}</strong>
            <div className='confirm-order-hour-container'>
                {deliveryHours?.map((hour, index) => 
                <Button 
                    index={index}
                    type={index === selectedHour ? 'primary' : 'ghost'}
                    shape='round'
                    onClick={(e) => onChangeHour(index, `${hour?.init}:00 - ${hour?.end}:00`)}
                    className={`confirm-order-hour-item`}>
                        {hour?.init}:00 - {hour?.end}:00
                </Button>)}
            </div>
        </div>
        <div className='confirm-order-continue'>
            <Button 
                type='primary' 
                size='large' 
                shape='round' 
                style={{width: '100%', maxWidth: 400}}
                onClick={confirm}
                className='confirm-order-continue-btn'
                loading={loading}
                disabled={!selectedAddress || loading}
            >
                {sw.proceedToPayment[language]}
            </Button>
        </div>
    </Drawer>
);
};

export default ConfirmOrder;