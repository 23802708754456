import React from 'react';
import sw from '../../utils/multilingual.json'; 
import { useRef } from 'react';
import { useEffect } from 'react';
import { finishOrder } from './payment-methods';
import { useParams } from 'react-router-dom';

const FinishOrderPage = (props)=> {
    
    const ref = useRef();
    const {key} = useParams();

    useEffect(() => {
        ref.current = {
            language: props.language,
            key: key
        };
    });

    useEffect(() => {
        document.title = `TLAC | ${sw.payment[ref.current.language]}`;
        finishOrder(ref.current.key);
    }, []);

    return (
        <div className='finish-order-page'>wait...</div>
    )
};

export default FinishOrderPage;